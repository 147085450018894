import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';

// Components
import { SubscriptionsComponent } from '../../../shared/super/subscriptions-component';

// Models
import { DragNDropModalState } from '../../models/drag-n-drop-modal-state.model';
import { ManageProject } from '../../../shared/models/manage-project.model';
import { ProjectTasks } from '../../../shared/models/project-tasks.model';
import { GroupProject } from '../../../shared/models/group-project.model';
import { UserProfile } from '../../../shared/models/user-profile.model';
import { Profile } from '../../../shared/models/profile.model';
import { Project } from '../../../shared/models/project.model';
import { Group } from '../../../shared/models/group.model';

// Services
import { DRAG_N_DROP_SERVICE } from '../../services/injection-tokens/drag-n-drop-token';
import { ProjectService } from '../../services/project/project.service';
import { GroupService } from '../../services/group/group.service';
import { UserService } from '../../../shared/services/user/user.service';
import { TaskService } from '../../services/task/task.service';


@Component({
  selector: 'ffcrm-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
  providers: [
    {
      provide: DRAG_N_DROP_SERVICE,
      useExisting: ProjectService,
    },
  ],
})
export class ProjectsListComponent extends SubscriptionsComponent implements OnInit, OnDestroy {

  constructor(
    private groupService: GroupService,
    private projectService: ProjectService,
    private userService: UserService,
    private taskService: TaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
    super();

    this.subscriptions.push(
      this.activatedRoute?.params.subscribe(async (params: Params) => {
        this.groupSlug = params?.groupSlug;
        if (this.projectsBlock) {
          this.projectsBlock.nativeElement.scrollTop = 0;
        }

        // if (this.groupSlug && this.profile?.isStaff) { // this.profile?.isStaff always undefined
        //   console.log('from projects-list.component admin')
        //   await this.groupService.getGroup(this.groupSlug);
        // }

        // if (!this.groupSlug && !this.profile?.isStaff) {
        //   console.log('from projects-list.component')
        //   await this.projectService.getUsersProjects();
        // }
      })
    );

    this.subscriptions.push(
      this.activatedRoute.data.subscribe(
        (data: {
          profile: Profile,
          currentGroup: Group,
        }) => {
          this.currentGroup = data?.currentGroup;
          this.group = data?.currentGroup;
          this.projects = this.currentGroup?.projects;
          this.filteredProjects = this.currentGroup?.projects;
          this.filterProjects();
          this.profile = data?.profile; // there is no property profile in data
        }
      )
    );

    this.subscriptions.push(
      this.activatedRoute.data.subscribe((routeData: Data) => {
        this.isNoGroups = routeData?.noGroups;
      })
    );

    this.$currentGroup = this.groupService.$currentGroup as Observable<Group>;
    this.$currentProject = this.projectService.$currentProject;
    this.$currentManageProject = this.projectService.$currentManageProject as Observable<ManageProject>;
    this.$tasks = this.taskService.$tasks;
  }

  public selectOptions = [
    { id: 'all', label: 'All projects' },
    { id: 'billable', label: 'Billable' },
    { id: 'nonbillable', label: 'Non-billable' },
  ];

  @ViewChild('search') search;
  @ViewChild('projectsBlock') projectsBlock;

  private groupSlug: string;
  public group: Group;
  public user: UserProfile;
  public profile: Profile;
  public currentGroup: Group;
  public projects: Array<GroupProject>;
  public filteredProjects: Array<GroupProject>;
  public $currentGroup: Observable<Group>;
  public $currentProject: Observable<Project>;
  public $currentManageProject: Observable<ManageProject>;
  public $tasks: Observable<ProjectTasks>;
  public billableFilter = 'all';
  public selectedProjectSlug: string;
  public isNoGroups = false;
  private dragNDropModalState: DragNDropModalState = {
    refresh: false,
    slug: '',
    open: false,
    mode: 'project',
  };

  get userModalState(): DragNDropModalState {
    return this.dragNDropModalState;
  }

  set userModalState(value: DragNDropModalState) {
    this.dragNDropModalState = value;

    if (value.refresh) {
      this.groupService.refreshGroup();
    }
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.$currentGroup.subscribe((group: Group) => {
        if (group) {
          this.titleService.setTitle(`Timerilo - ${group.name}`);
        } else {
          this.titleService.setTitle(`Timerilo`);
        }
        this.currentGroup = group;
        this.projects = group?.projects;
        this.filterProjects();
      })
    );
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public selectFilter(id: any): void {
    this.billableFilter = id;
    this.filterProjects();
  }

  public async starProject(project: GroupProject): Promise<void> {
    await this.projectService.adminStarProject(project.slug);
    await this.groupService.getGroup(this.group?.slug);
  }

  public async unStarProject(project: GroupProject): Promise<void> {
    await this.projectService.adminUnStarProject(project.slug);
    await this.groupService.getGroup(this.group?.slug);
  }

  public async newAddUsersModal(slug: string): Promise<void> {
    this.userModalState = {
      refresh: false,
      mode: 'project',
      open: true,
      slug,
    };
  }

  public filterProjects(): void {
    let keyword = '';
    if (this.search) {
      keyword = this.search.nativeElement.value.toLowerCase();
    }
    this.filteredProjects = this.projects?.filter(project => project.name.toLowerCase().includes(keyword));
    if (this.billableFilter !== 'all') {
      this.filteredProjects = this.filteredProjects
        .filter(project => this.billableFilter === 'billable' ? project.isBillable : !project.isBillable);
    }
  }

  public groupEdited(group): void {
    this.group = group;
  }

  public async openProject(slug: string): Promise<void> {
    await this.router.navigate([`home/groups/${this.groupSlug}/projects/${slug}`], {
      queryParamsHandling: 'preserve',
    });
  }
}
