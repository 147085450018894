import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';

// Components
import { SubscriptionsComponent } from '../../../shared/super/subscriptions-component';

// Models
import { UserProfile } from '../../../shared/models/user-profile.model';
import { UserTasksList } from '../../interfaces/user-tasks-list.model';
import { SummaryAdmin } from '../../interfaces/summary-admin.model';
import { Profile } from '../../../shared/models/profile.model';
import { Group } from '../../../shared/models/group.model';

// Services
import { UsersProjectService } from '../../services/all-users/users-projects/users-project.service';
import { SummaryService } from '../../services/summary/summary.service';
import { UserService } from '../../../shared/services/user/user.service';


@Component({
  selector: 'ffcrm-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent extends SubscriptionsComponent implements OnInit {
  constructor(
    private summaryService: SummaryService,
    private userService: UserService,
    private titleService: Title,
    private userProjectService: UsersProjectService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    super();

    this.$summary = this.summaryService.$summary as Observable<SummaryAdmin>;
    this.$users = this.userService.$users;
    this.$groups = this.userService.$userGroups;
  }

  @ViewChild('search') search: ElementRef;
  @ViewChild('usersBlock') usersBlock;

  public filterHeader = 'All users';
  public selectOptions = [
    { id: 'all', label: 'All users' },
    { id: 'active', label: 'Active users' },
    { id: 'blocked', label: 'Blocked users' },
  ];
  public selectFilterValue = 'all';
  public $summary: Observable<SummaryAdmin>;
  public summary: SummaryAdmin;
  public $users: Observable<Array<UserProfile>>;
  private users: Array<UserProfile>;
  public filteredUsers: Array<UserProfile>;
  public user: Profile;
  public tasks: UserTasksList;
  public $groups: Observable<Array<Group>>;
  public groups: Array<Group>;
  private projectSlug: string;

  public userPage: boolean;

  public ngOnInit(): void {
    this.userProjectService.userId = null;
    this.titleService.setTitle(`Timerilo - All Users`);
    this.updateFilter(this.activatedRoute.snapshot.queryParams['user-filter'] || 'all');

    this.subscriptions.push(
      this.$summary.subscribe((summary: SummaryAdmin) => {
        this.summary = summary;
      }),

      this.$users.subscribe((users: Array<UserProfile>) => {
        this.users = users;
        this.filterUsers();
      }),

      this.activatedRoute.queryParams.subscribe(async (queries: Params) => {
        if ((queries['user-filter'] && queries['user-filter'] !== this.selectFilterValue) ||
          this.projectSlug !== queries?.project) {
          if (this.usersBlock) {
            this.usersBlock.nativeElement.scrollTop = 0;
          }
          this.projectSlug = queries?.project;
          await this.userService.getAllUsersAsync().toPromise();
        }

        this.updateFilter(queries['user-filter'] || 'all');
      }),
    );
  }

  public async selectedFilter(option): Promise<void> {
    await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams: {
        'user-filter': option,
      },
    });

    this.updateFilter(option);
  }

  private updateFilter(option: string): void {
    this.filterHeader = this.selectOptions.filter((value) => {
      return value.id === option;
    })[0]?.label;
    this.selectFilterValue = option;
  }

  public filterUsers(): void {
    let keyword = '';

    if (this.summaryService.summary && this.search) {
      keyword = this.search?.nativeElement?.value.toLowerCase();
    }

    if (this.users?.length) {
      this.filteredUsers = this.users?.filter((user: UserProfile) => (
        user?.firstName + ' ' + user?.lastName).toLowerCase()?.includes(keyword)
        || user?.jobTitle?.includes(keyword)
        || user?.email?.includes(keyword)
      );
    } else {
      this.filteredUsers = [];
    }
  }

}
