const isImageUploadingIncorrect = (file: File, resolvedFileTypes: Array<string>): string | false => {
  if (file.size > 2000000) {
    return 'File can’t be more than 2 MB';
  } else if (!resolvedFileTypes.includes(file.type)) {
    return 'Rejected file type';
  }

  return false;
};

const imageResolving = async (
    file: File,
    correctCallback: () => void,
    incorrectCallback: (message: string | false) => void,
    resolvedFileTypes?: Array<string>
  ): Promise<void> => {
  resolvedFileTypes = resolvedFileTypes ?? [
    'image/gif',
    'image/jpeg',
    'image/png',
  ];

  if (isImageUploadingIncorrect(file, resolvedFileTypes)) {
    incorrectCallback(isImageUploadingIncorrect(file, resolvedFileTypes));
  } else {
    correctCallback();
  }
};

export default imageResolving;
