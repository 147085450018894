<div class="wrapper">
  <div class="head">
    <p>Working time</p>
    <div class="selected">
      <div class="cell cell-active" (click)="state = true;">{{ selectedHours }} h</div>
      <span class="colon">:</span>
      <div class="cell cell-active" (click)="state = false;">{{ selectedMinutes }} m</div>
    </div>
  </div>
  <div class="navigation">
    <span>Choice
      <span *ngIf="!state">minutes</span>
      <span *ngIf="state">hours</span>
    </span>
    <div class="arrows">
      <button class="icon icon-left" [disabled]="state" (click)="toggleState()"></button>
      <button class="icon icon-right" [disabled]="!state" (click)="toggleState()"></button>
    </div>
  </div>
  <div class="panel">

    <div class="panel-table" [@changeHours]="state">
      <ng-container>
        <div class="cell" *ngFor="let time of [].constructor(24); let i = index" [ngClass]="i == selectedHours ? 'cell-active': ''" (click)="selectHours(i)">
          {{ i + 'h' }}
        </div>
      </ng-container>
    </div>

    <div class="panel-table" [@changeMinutes]="state">
      <ng-container>
        <div class="cell"  *ngFor="let time of minutes" [ngClass]="time == selectedMinutes ? 'cell-active': ''" (click)="selectTime(time)">
          {{ time + 'm' }}
        </div>
      </ng-container>
    </div>

  </div>
</div>
