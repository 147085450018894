<ffcrm-dialog
  [visible]="!isDelete"
  [title]="overtimeId ? 'Edit overtime' : 'Add overtime'"
  [closable]="true"
  (visibleChange)="onStateChange()"
>
  <div class="dialog-body">
    <form [formGroup]="form">
      <div class="input-block project-wrapper">
        <label for="project">Project</label>
        <ffcrm-autocomplete id="project"
                            (valueChange)="projectsAutocomplete($event)"
                            [canWrite]="true"
                            [options]="projects"
                            (focusEvent)="projects = coldProjects"
                            labelKey="name"
                            placeholder="Enter project name"
                            formControlName="project"
                            ngDefaultControl></ffcrm-autocomplete>
      </div>
      <div class="input-block">
        <label for="task">Task</label>
        <ffcrm-autocomplete *ngIf="isProjectSelected; else inputTask" id="task"
                            (valueChange)="tasksAutocomplete($event)"
                            [canWrite]="true"
                            [options]="tasks"
                            (focusEvent)="tasks = coldTasks"
                            labelKey="title"
                            placeholder="Enter title"
                            formControlName="task"
                            ngDefaultControl></ffcrm-autocomplete>
        <ng-template #inputTask>
          <input type="text" id="task" class="input-field" placeholder="Enter title" formControlName="task" />
        </ng-template>
        <div class="error-msg" [class.visible]="form.get('task').invalid && (form.get('task')?.touched || form.get('task')?.dirty)">
          <div *ngFor="let error of getErrors(form.get('task'))">{{ error }}</div>
        </div>
      </div>
      <div class="input-block">
        <label for="activity">Activity</label>
        <ffcrm-select id="activity"
                      classList="select form-select"
                      labelKey="activity"
                      formControlName="activity"
                      placeholder="Development"
                      [options]="activities"
                      ngDefaultControl
        ></ffcrm-select>
        <div class="error-msg" [class.visible]="form.get('activity').invalid && (form.get('activity')?.touched || form.get('activity')?.dirty)">
          <div *ngFor="let error of getErrors(form.get('activity'))">{{ error }}</div>
        </div>
      </div>
      <div class="input-block">
        <div class="pickers">
          <div>
            <label>Date</label>
            <ffcrm-datepicker formControlName="date" ngDefaultControl></ffcrm-datepicker>
          </div>
          <div>
            <label>Time</label>
            <ffcrm-timepicker formControlName="minutes" ngDefaultControl></ffcrm-timepicker>
          </div>
        </div>
      </div>
      <div class="error-fields">
        <div class="error-msg" [class.visible]="form.get('date')?.invalid && (form.get('date')?.touched || form.get('date')?.dirty)">
          <div *ngFor="let error of getErrors(form.get('date'))">{{ error }}</div>
        </div>
        <div class="error-msg" [class.visible]="form.get('minutes')?.invalid && (form.get('minutes')?.touched || form.get('minutes')?.dirty)">
          <div *ngFor="let error of getErrors(form.get('minutes'))">{{ error }}</div>
        </div>
      </div>
      <div class="actions">
        <button *ngIf="overtimeId" type="submit" class="btn btn-delete btn-block" (click)="isDelete = true">Delete</button>
        <button *ngIf="overtimeId" type="submit" class="btn btn-block" [disabled]="form.invalid" (click)="editOvertime()">Save</button>
        <button *ngIf="!overtimeId" type="submit" class="btn btn-light btn-block" [disabled]="form.invalid" (click)="postOvertime(false)">Create & New</button>
        <button *ngIf="!overtimeId" type="submit" class="btn btn-block" [disabled]="form.invalid" (click)="postOvertime(true)">Create & Close</button>
      </div>
    </form>
  </div>
</ffcrm-dialog>

<ffcrm-accept
  [isVisible]="isDelete"
  description="Do you really want to delete this data?"
  type="delete"
  acceptBtnText="Delete"
  (accept)="deleteOvertime()"
  (cancel)="isDelete = false"
></ffcrm-accept>
