const deleteProperty = (propertyName: string): object => {
  return (obj: object): object => {
    if (obj[propertyName]) {
      delete obj[propertyName];
    }

    return obj;
  };
};

export default deleteProperty;
