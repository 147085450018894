<ffcrm-dialog
  [visible]="!isDelete"
  (visibleChange)="onStateChange()"
  [title]="timeEntryId ? 'Edit hours' : 'Add time'"
  [userTitle]="timeEntryId ? username : ''"
  [closable]="true">

  <div class="dialog-body">
    <form [formGroup]="form">

      <div class="input-block">
        <label>Activity</label>
        <ffcrm-select classList="select form-select" [labelKey]="'activity'" [options]="timeEntryActivities" formControlName="activity" ngDefaultControl></ffcrm-select>
      </div>

      <div class="error-msg" [class.visible]="form.get('activity').invalid && (form.get('activity')?.touched || form.get('activity')?.dirty)">
        <div *ngFor="let error of getErrors(form.get('activity'))">{{ error }}</div>
      </div>

      <div class="fields">
        <div class="input-block">
          <div class="pickers">
            <div>
              <label>Date</label>
              <ffcrm-datepicker formControlName="date" ngDefaultControl></ffcrm-datepicker>
            </div>
            <div>
              <label>Time</label>
              <ffcrm-timepicker formControlName="minutes" ngDefaultControl></ffcrm-timepicker>
            </div>
          </div>
        </div>
        <div class="error-fields">
          <div class="error-msg" [class.visible]="form.get('date')?.invalid && (form.get('date')?.touched || form.get('date')?.dirty)">
            <div *ngFor="let error of getErrors(form.get('date'))">{{ error }}</div>
          </div>
          <div class="error-msg" [class.visible]="form.get('minutes')?.invalid && (form.get('minutes')?.touched || form.get('minutes')?.dirty)">
            <div *ngFor="let error of getErrors(form.get('minutes'))">{{ error }}</div>
          </div>
        </div>

        <div class="checkbox-block">
          <div class="checkbox-control-wrapper">
            <input type="checkbox" id="isOvertime" formControlName="isOvertime">
            <label for="isOvertime">Overtime</label>
            <div class="tooltip-icon tooltip-trigger">?</div>
            <div class="tooltip-message">
              Work that has been done out of the working time
            </div>
          </div>
        </div>

         <div class="input-block">
          <label for="description">Comment</label>
          <textarea id="description" class="input-field textarea" formControlName="entry_description" rows="5" style="resize: none"></textarea>
          <div class="error-msg" [class.visible]="form.get('entry_description')?.invalid && (form.get('entry_description')?.touched || form.get('entry_description')?.dirty)">
            <div *ngFor="let error of getErrors(form.get('entry_description'))">{{ error }}</div>
          </div>
        </div>

        <div class="actions" *ngIf="timeEntryId">
          <button type="submit" class="btn btn-delete btn-block" (click)="openDeleteModal()">Delete</button>
          <button type="submit" class="btn btn-block" [disabled]="form.invalid" (click)="editAndClose()">Save changes</button>
        </div>

        <button *ngIf="!timeEntryId" type="submit" class="btn btn-block" [disabled]="form.invalid" (click)="createAndClose()">Create</button>
      </div>
    </form>
  </div>
</ffcrm-dialog>

<ffcrm-accept
  [isVisible]="isDelete"
  type="delete"
  description="Are you sure you want to delete the time entry?"
  acceptBtnText="Delete time entry"
  (cancel)="closeDeleteModal()"
  (accept)="removeTimeEntry()"
></ffcrm-accept>
