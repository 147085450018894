import { Pipe, PipeTransform } from '@angular/core';

// Models
import { GroupProject } from '../models/group-project.model';
import { Project } from '../models/project.model';
import { Group } from '../models/group.model';

@Pipe({ name: 'readonly' })
export class ReadonlyProjectGroupPipe implements PipeTransform {

  transform(value: Array<GroupProject | Group | Project>, ...args: unknown[]): Array<GroupProject | Group | Project> {
    return value?.filter(item => item?.isReadonly && item?.isActive);
  }
}
