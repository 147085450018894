<ffcrm-dialog
  [(visible)]="editProfileDialog"
  (visibleChange)="onStateChange()"
  [title]="title"
  [closable]="false"
  *ngIf="editProfileDialog">

  <ng-container *ngTemplateOutlet="content"></ng-container>

</ffcrm-dialog>

<ffcrm-dialog
  visible="true"
  (visibleChange)="onStateChange()"
  [title]="modalMode === 'add' ? 'Add user' : 'Edit user'"
  [closable]="false"
  *ngIf="modalMode">

  <ng-container *ngTemplateOutlet="content"></ng-container>

</ffcrm-dialog>

<ng-template #content>
  <div class="dialog-body">

    <div class="row">
      <div class="col-9">

        <form [formGroup]="form" class="right-border" (ngSubmit)="onSubmit()">

          <div class="input-block">
            <label for="firstName">First name</label>
            <input type="text" class="input-field" formControlName="firstName" id="firstName"
                   [class.error]="form.get('firstName').invalid && form.get('firstName').touched" >

            <div class="error-msg" [class.visible]="form.get('firstName')?.invalid && (form.get('firstName')?.touched || form.get('firstName')?.dirty)">
              <div *ngFor="let error of getErrors(form.get('firstName'))">{{ error }}</div>
            </div>

          </div>

          <div class="input-block">
            <label for="lastName">Last name</label>
            <input type="text" class="input-field" formControlName="lastName" id="lastName"
                   [class.error]="form.get('lastName').invalid && form.get('lastName').touched" >

            <div class="error-msg" [class.visible]="form.get('lastName')?.invalid && (form.get('lastName')?.touched || form.get('lastName')?.dirty)">
              <div *ngFor="let error of getErrors(form.get('lastName'))">{{ error }}</div>
            </div>

          </div>

          <div class="input-block">
            <label for="email">Email</label>
            <input type="text" class="input-field" formControlName="email" id="email"
                   [class.error]="form.get('email').invalid && form.get('email').touched" >

            <div class="error-msg" [class.visible]="form.get('email')?.invalid && (form.get('email')?.touched || form.get('email')?.dirty)">
              <div *ngFor="let error of getErrors(form.get('email'))">{{ error }}</div>
            </div>

          </div>

          <div class="input-block">
            <label for="jobTitle">Job title</label>
            <input type="text" class="input-field" formControlName="jobTitle" id="jobTitle"
                   [class.error]="form.get('jobTitle').invalid && form.get('jobTitle').touched" >

            <div class="error-msg" [class.visible]="form.get('jobTitle')?.invalid && (form.get('jobTitle')?.touched || form.get('jobTitle')?.dirty)">
              <div *ngFor="let error of getErrors(form.get('jobTitle'))">{{ error }}</div>
            </div>

          </div>

          <div class="input-block">
            <label for="phone">Phone number</label>
            <input type="text" class="input-field" formControlName="phoneNumber" id="phone"
                   [class.error]="form.get('phoneNumber').invalid && form.get('phoneNumber').touched" >

            <div class="error-msg" [class.visible]="form.get('phoneNumber')?.invalid && (form.get('phoneNumber')?.touched || form.get('phoneNumber')?.dirty)">
              <div *ngFor="let error of getErrors(form.get('phoneNumber'))">{{ error }}</div>
            </div>
          </div>

        </form>

      </div>

      <div class="col-3">
        <div class="image-container">
          <div class="hidden" style="overflow: hidden">
            <label for="profile" style="height: 100%">
              <span class="profile-image">
                <ffcrm-image [object]="tempUser" [labelKeys]="['firstName', 'lastName']" srcKey="photo"></ffcrm-image>
                <span class="overlay">
                  <span class="text">
                    <ng-container *ngIf="user">Edit photo</ng-container>
                    <ng-container *ngIf="!user">Add photo</ng-container>
                  </span>
                </span>
              </span>
            </label>
          </div>
          <input #photoInput type="file" id="profile" accept="image/gif, image/jpeg, image/png" (change)="processPhoto($event)">
        </div>
      </div>
    </div>

    <button (click)="onSubmit()" class="btn btn-block" [disabled]="form.invalid">
      <ng-container *ngIf="user">Save changes</ng-container>
      <ng-container *ngIf="!user">Add user</ng-container>
    </button>
  </div>
</ng-template>

<ffcrm-dialog [(visible)]="errorDialog" [type]="'info'">
  <div class="dialog-body-info">
    <i class="fas fa-times error"></i>
    <h1>Oops... 😨</h1>
    <p>File can’t be more than 2 MB</p>
  </div>
</ffcrm-dialog>

<!--<ffcrm-dialog [(visible)]="successDialog" [type]="'info'">-->
<!--  <div class="dialog-body-info">-->
<!--    <i class="fas fa-check success"></i>-->
<!--    <h1>Success 🥳</h1>-->
<!--    <ng-container *ngIf="user">-->
<!--      <p *ngIf="user.id === currentUserProfile.id">Your profile has been successfully updated</p>-->
<!--      <p *ngIf="user.id !== currentUserProfile.id">User has been successfully updated</p>-->
<!--    </ng-container>-->
<!--    <p *ngIf="!user">User has been successfully created</p>-->
<!--  </div>-->
<!--</ffcrm-dialog>-->


<ffcrm-image-cropper [(cropperDialog)]="imageCropper" (cropperDialogChange)="croppedImageDialogChange($event)" [image]="croppedImage" (croppedImage)="getCroppedImage($event)"></ffcrm-image-cropper>
