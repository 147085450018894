import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CdkDragDrop, transferArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { debounce } from 'typescript-debounce-decorator';
import { Observable } from 'rxjs';

// Components
import { SubscriptionsComponent } from '../../../shared/super/subscriptions-component';

// Models
import { DragNDropModalState } from '../../models/drag-n-drop-modal-state.model';
import { UserProfile } from '../../../shared/models/user-profile.model';
import { Group } from '../../../shared/models/group.model';

// Services
import { DRAG_N_DROP_SERVICE, IDragNDropService } from '../../services/injection-tokens/drag-n-drop-token';
import { UserService } from '../../../shared/services/user/user.service';
import { GroupService } from '../../services/group/group.service';


@Component({
  selector: 'ffcrm-add-users-drug-and-drop',
  templateUrl: './add-users-drug-and-drop.component.html',
  styleUrls: ['./add-users-drug-and-drop.component.scss']
})
export class AddUsersDrugAndDropComponent extends SubscriptionsComponent implements OnInit, OnDestroy {
  constructor(
    private userService: UserService,
    @Inject(DRAG_N_DROP_SERVICE) itemService: IDragNDropService,
  ) {
    super();

    this.itemService = itemService;
    this.$users = this.userService.$users;
  }

  public itemSelected;
  public itemService: IDragNDropService;
  public nonMembers: Array<UserProfile>;
  private coldNonMembers: Array<UserProfile>;
  public members: Array<UserProfile>;
  private coldMembers: Array<UserProfile>;
  public groupMembers: Array<UserProfile>;
  private coldGroupMembers: Array<UserProfile>;
  public lastLoadedGroup: Group;
  public title: string;
  public dropdownChoices;

  public $users: Observable<Array<UserProfile>>;
  public modalState: DragNDropModalState = {
    open: false,
    slug: '',
    mode: 'project',
    refresh: false,
  };

  @Input()
  set state(val: DragNDropModalState) {
    this.modalState = val;

    if (val.open) {
      this.groupMembers = [];
      this.coldGroupMembers = [];
      this.nonMembers = [];
      this.coldNonMembers = [];
      this.members = [];
      this.coldMembers = [];

      if (val.slug) {
        if (this.itemSelected?.slug !== val.slug) {
          this.loadItem(val.slug);
        } else {
          this.setMembers();
        }
      }
    }
  }

  get state(): DragNDropModalState {
    return this.modalState;
  }

  get modalTitleType(): string {
    let title = '';

    switch (this.state.mode) {
      case 'task': title = 'Task'; break;
      case 'project': title = 'Project'; break;
      case 'group': title = 'Group'; break;
      default: break;
    }

    return title;
  }

  public users: Array<UserProfile>;

  @Output() stateChange = new EventEmitter();
  @ViewChild('nonGroupParticipants') public nonGroupParticipants: TemplateRef<any>;
  @ViewChild('leftSearchInput') public leftSearchInput;
  @ViewChild('rightSearchInput') public rightSearchInput;

  private async loadItem(val): Promise<void> {
    try {
      this.itemSelected = await this.itemService.getManageItemInstance(val);
      this.title = this.itemSelected?.name || this.itemSelected?.title;
      this.setMembers();

      if (this.itemService instanceof GroupService) {
        this.lastLoadedGroup = this.itemSelected;
      }
    } catch (e) {
      console.error(e);
    }
  }

  private setMembers(): void {
    const nonMembers = [];

    if (this.state.mode !== 'task') {
      for (const user of this.users) {
        if (!(this.itemSelected?.members?.some((member) => member.id === user.id) ||
          this.itemSelected?.groupMembers?.some((member) => member.id === user.id))) {
          nonMembers.push(user);
        }
      }
    }

    if (this.state.mode === 'task') {
      this.members = this.itemSelected?.users;
      this.coldMembers = this.itemSelected?.users;
    } else {
      this.members = this.itemSelected?.members;
      this.coldMembers = this.itemSelected?.members;
      this.groupMembers = this.itemSelected?.groupMembers;
      this.coldGroupMembers = this.itemSelected?.groupMembers;
      this.nonMembers = nonMembers;
      this.coldNonMembers = nonMembers;
    }
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.$users?.subscribe((users: Array<UserProfile>) => {
        this.users = users;
        this.setMembers();
      })
    );

    this.subscriptions.push(
      this.itemService.$items?.subscribe((groups: Array<Group>) => {
        this.dropdownChoices = groups;
      })
    );

    this.subscriptions.push(
      this.itemService.$currentItem?.subscribe((group: Group) => {
        this.lastLoadedGroup = this.itemSelected;
        this.itemSelected = group;
        this.title = this.itemSelected?.name || this.itemSelected?.title;
        this.setMembers();
        this.state = {
          ...this.state,
          slug: group?.slug,
        };
      })
    );
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public dropUser(event: CdkDragDrop<Array<UserProfile>>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.updateItem();
  }

  private async updateItem(): Promise<void> {
    let members;
    if (this.members?.length === this.coldMembers?.length) {
      members = { members: this.members };
    } else {
      const leftBarMembers = [...this.coldMembers, ...this.members];
      const uniqueLeftBarMembers = Array.from( // Remove dublicates
        new Set(leftBarMembers.map(a => a.id))
      ).map(id =>  leftBarMembers.find(a => a.id === id));
      members = {
        members: uniqueLeftBarMembers
      };
    }

    try {
      const newMembers: any = await this.itemService.updateMembers(this.itemSelected?.slug, members).toPromise();
      this.state.refresh = true;
      this.members = newMembers.members;
      this.filterLeftColumnMembers(this.leftSearchInput.nativeElement?.value);
      this.coldMembers = newMembers.members;
      this.logSuccess(newMembers);
    } catch (e) {
      console.error(e);
    }
  }

  public async changeGroup(event): Promise<void> {
    await this.loadItem(event);
  }

  public dialogVisibleChange(): void {
    this.leftSearchInput.nativeElement.value = '';
    this.rightSearchInput.nativeElement.value = '';
    this.itemSelected = {
      ...this.itemSelected,
      members: this.coldMembers,
      nonMembers: this.coldNonMembers
    }
    this.stateChange.emit({
      ...this.state,
      open: false,
    });
  }

  @debounce(200)
  public filterNonMembers(value): void {
    this.nonMembers = this.coldNonMembers.filter((member: UserProfile) => {
      return (member?.firstName + ' ' + member?.lastName)?.toLowerCase().indexOf(value?.toLowerCase()) !== -1;
    });
  }

  @debounce(200)
  public filterLeftColumnMembers(value): void {
    this.members = this.coldMembers.filter((member: UserProfile) => {
      return (member?.firstName + ' ' + member?.lastName).toLowerCase().indexOf(value.toLowerCase()) !== -1;
    });

    this.groupMembers = this.coldGroupMembers.filter((member: UserProfile) => {
      return (member?.firstName + ' ' + member?.lastName).toLowerCase().indexOf(value.toLowerCase()) !== -1;
    });
  }

  public logSuccess(res): void {
    console.log(res);
  }
}
