<div class="back-btn">
  <button type="button" class="btn-icon" routerLink="/login"><i class="fas fa-arrow-left fa-fw"></i></button>
</div>

<div class="container">
  <div class="row">
    <div class="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">

      <div class="card">

        <h1>{{ mode }} password</h1>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <div class="input-block">
            <input type="email" [class.hidden]="true" class="input-field" id="email" formControlName="email"
                   [ngClass]="{'error': form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)}">
          </div>
          <div class="input-block">
            <label for="password">New password</label>
            <input type="password" class="input-field" id="password" formControlName="password" placeholder="Enter your new password"
                   [ngClass]="{'error': form.get('password')?.invalid && (form.get('password')?.dirty || form.get('password')?.touched)}">

            <div class="error-msg" [class.visible]="form.get('password')?.invalid && (form.get('password')?.dirty || form.get('password')?.touched)">
              <div *ngFor="let error of getErrors(form.get('password'))">{{ error }}</div>
            </div>
          </div>

          <div class="input-block">
            <label for="confirm_password">Confirm new password</label>
            <input type="password" class="input-field" id="confirm_password" formControlName="confirmPassword" placeholder="Re-enter new password"
                   [ngClass]="{'error': form.get('confirmPassword')?.invalid && (form.get('confirmPassword')?.dirty || form.get('confirmPassword')?.touched)}">

            <div class="error-msg" [class.visible]="form.get('confirmPassword')?.invalid && (form.get('confirmPassword')?.dirty || form.get('confirmPassword')?.touched)">

              <div *ngIf="form.get('confirmPassword')?.errors?.required">
                Password confirmation is required.
              </div>

              <div *ngIf="form.get('confirmPassword')?.errors?.mustMatch">
                Passwords does not match.
              </div>

              <div class="error-msg non-field-error" [class.visible]="form?.invalid && (form?.touched || form?.dirty)">
                <div *ngFor="let error of getErrors(form)">{{ error }}</div>
              </div>
            </div>
          </div>

          <input type="submit" class="btn btn-block" value="Save" [disabled]="form.invalid">

        </form>

      </div>

    </div>
  </div>
</div>

