import { Observable } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

// Components
import { SubscriptionsComponent } from '../../super/subscriptions-component';

// Services
import { TooltipService } from '../../services/tooltip.service';

@Component({
  selector: 'ffcrm-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent extends SubscriptionsComponent implements OnInit, OnDestroy {

  constructor(
    private tooltipServce: TooltipService,
  ) {
    super();
  }

  @Input() fadeTime = 1500;
  @Input() text = 'The link copied to the clipboard';
  private timeout: ReturnType<typeof setTimeout>;

  public get $isTooltipVisible(): Observable<boolean> {
    return this.tooltipServce.$isTooltipVisible;
  }

  public get $clickCords(): Observable<{ x: number; y: number; }> {
    return this.tooltipServce.$clickCords;
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.tooltipServce.$isTooltipVisible.subscribe(visible => {
        if (visible) {
          if (this.timeout) {
            clearTimeout(this.timeout);
          }

          this.timeout = setTimeout(() => {
            this.tooltipServce.hideTooltip();
          }, this.fadeTime);
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

}
