import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

// Utils
import parseQuery from '../../shared/utils/parse-query';

@Injectable({
  providedIn: 'root'
})
export class GoogleRedirectGuard implements CanActivate {
  constructor(
    private router: Router,
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean>  {
      if (
        window.location.href.includes('scope') &&
        window.location.href.includes('code') &&
        window.location.href.includes('state') &&
        localStorage.getItem('redirectUrl')
      ) {
        const redirectUrl = new URL(window.location.href);
        redirectUrl.searchParams.delete('state');
        redirectUrl.searchParams.delete('code');
        redirectUrl.searchParams.delete('scope');

        const redirectOrigin = localStorage.getItem('redirectUrl');
        localStorage.removeItem('redirectUrl');
        const redirectQueryParams =  parseQuery(localStorage.getItem('redirectQueryParams'));
        localStorage.removeItem('redirectQueryParams');

        await this.router.navigate([redirectOrigin], {
          queryParamsHandling: 'merge',
          queryParams: {
            confirm_url: window.location.href,
            redirect_url: redirectUrl.href,
            ...redirectQueryParams,
          }
        });
      }
      return false;
    }

}
