import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from '@angular/cdk/clipboard';

// Components
import { UserParticipationsModalComponent } from './components/user-participations-modal/user-participations-modal.component';
import { AddUsersDrugAndDropComponent } from './components/add-users-drug-and-drop/add-users-drug-and-drop.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CurrentProjectComponent } from './components/current-project/current-project.component';
import { DashboardLeftComponent } from './components/dashboard-left/dashboard-left.component';
import { UserComponentComponent } from './components/user-component/user-component.component';
import { ProjectsListComponent } from './components/projects-list/projects-list.component';
import { ProjectModalComponent } from './components/project-modal/project-modal.component';
import { CurrentTaskComponent } from './components/current-task/current-task.component';
import { GroupModalComponent } from './components/group-modal/group-modal.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { TaskEditComponent } from './components/task-edit/task-edit.component';
import { AddTimeComponent } from './components/add-time/add-time.component';
import { AddTaskComponent } from './components/add-task/add-task.component';
import { AllUsersComponent } from './pages/all-users/all-users.component';
import { OvertimeComponent } from './pages/overtime/overtime.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { RootComponent } from './root/root.component';
import { OvertimeLeftComponent } from './components/overtime-left/overtime-left.component';
import { AllUsersLeftComponent } from './components/all-users-left/all-users-left.component';
import { OvertimeModalComponent } from './components/overtime-modal/overtime-modal.component';

// Modules
import { MainRoutingModule } from './main-routing.module';
import { SharedModule } from '../shared/shared.module';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

// Services
import { YEAR_MONTH_FILTER_SERVICE } from './services/injection-tokens/month-year-filter-token';
import { DRAG_N_DROP_SERVICE, T } from './services/injection-tokens/drag-n-drop-token';
import { ImagesPreloaderService } from '../shared/services/images-preloader.service';
import { UserService } from '../shared/services/user/user.service';
import { GoogleExportService } from './services/google-export/google-export.service';

@NgModule({
  declarations: [
    RootComponent,
    HeaderComponent,
    HomeComponent,
    AllUsersComponent,
    OvertimeComponent,
    ChangePasswordComponent,
    DashboardLeftComponent,
    AddUsersDrugAndDropComponent,
    GroupModalComponent,
    CurrentProjectComponent,
    ProjectsListComponent,
    AddTaskComponent,
    TaskEditComponent,
    ProjectModalComponent,
    CurrentTaskComponent,
    AddTimeComponent,
    UserComponentComponent,
    UsersListComponent,
    UserParticipationsModalComponent,
    AllUsersLeftComponent,
    OvertimeLeftComponent,
    OvertimeModalComponent,
  ],
  exports: [
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    DragDropModule,
    ClipboardModule,
    RouterModule,
    LoadingBarHttpClientModule,
  ],
  providers: [
    UserService,
    ImagesPreloaderService,
    GoogleExportService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: UserService) => () => config.appInit(),
      deps: [UserService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ImagesPreloaderService) => () => config.appInit(),
      deps: [ImagesPreloaderService],
      multi: true,
    },
    {
      provide: DRAG_N_DROP_SERVICE,
      useClass: T
    },
    {
      provide: YEAR_MONTH_FILTER_SERVICE,
      useExisting: T,
    }
  ],
  bootstrap: [RootComponent],
})
export class MainModule {
}
