import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { UserProfile } from '../../../shared/models/user-profile.model';


export interface IDragNDropService {
  items: Array<any>;
  $items: Observable<Array<any>>;
  currentItem: any;
  $currentItem: Observable<any>;
  updateMembers(slug: string, members: { members: Array<UserProfile> }): Observable<void>;
  getManageItemInstance(slug: string): Promise<any>;
}

export const DRAG_N_DROP_SERVICE = new InjectionToken<IDragNDropService>('DRAG_N_DROP_SERVICE');

export class T {
  constructor() {
    throw new Error('No Provider for DRAG_N_DROP_SERVICE InjectionToken. Need provide service at parent component');
  }
}
