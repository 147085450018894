import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MousePos } from '../models/mouse-pos';

@Injectable({
  providedIn: 'root'
})
export class FloatingHelpService {
  private helpText: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private helpNeeded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private mousePos: BehaviorSubject<MousePos> = new BehaviorSubject<MousePos>({ mouseX: 0, mouseY: 0 });

  get $mousePos(): Observable<MousePos> {
    return this.mousePos.asObservable();
  }

  get $text(): Observable<string> {
    return this.helpText.asObservable();
  }

  get $helpNeeded(): Observable<boolean> {
    return this.helpNeeded.asObservable();
  }

  public setHelpText(value: string): void {
    this.helpText.next(value);
  }

  public setMousePos(event): void {
    this.mousePos.next({
      mouseX: event.clientX + 25,
      mouseY: event.clientY - 12,
    });
  }

  public setHelpState(state: boolean): void {
    this.helpNeeded.next(state);
  }
}
