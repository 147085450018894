import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { Injectable } from '@angular/core';

// Services
import { HttpClientFactoryService } from 'src/app/shared/services/http-client-factory.service';
import { IMonthYearFilterService } from '../../main/services/injection-tokens/month-year-filter-token';
import { UserService } from './user/user.service';

// Models
import { ApiClient } from './api-client.model';
import { MonthYear } from '../../main/models/month-year.model';
import { Paginate } from '../../main/interfaces/paginate.model';
import { OvertimeRecord, OvertimeStatistics } from '../../main/interfaces/overtime.model';
import { OvertimePost } from '../../main/interfaces/overtime-post.model';

@Injectable({
  providedIn: 'root'
})
export class OvertimeService implements IMonthYearFilterService{

  private overtimeHttp: ApiClient;
  private queries: BehaviorSubject<Params> = new BehaviorSubject<Params>(null);
  private monthesSubject: BehaviorSubject<Array<MonthYear>> = new BehaviorSubject<Array<MonthYear>>([]);
  private overtimesSubject: BehaviorSubject<Array<OvertimeRecord>> = new BehaviorSubject<Array<OvertimeRecord>>([]);
  private singleOvertimeSubject: BehaviorSubject<OvertimeRecord> = new BehaviorSubject<OvertimeRecord>(null);
  private statisticSubject: BehaviorSubject<OvertimeStatistics> = new BehaviorSubject<OvertimeStatistics>({});

  constructor(
    private httpFactory: HttpClientFactoryService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    this.overtimeHttp = this.httpFactory.getHttpClient(`/overtime/`);

    this.activatedRoute.queryParams.subscribe((queries: Params) => {
      this.queries.next(queries);
    });
  }

  get $overtimes(): Observable<Array<OvertimeRecord>> {
    return this.overtimesSubject.asObservable();
  }

  get overtimes(): Array<OvertimeRecord> {
    return this.overtimesSubject.value;
  }

  get monthes(): Array<MonthYear> {
    return this.monthesSubject.value;
  }

  get $monthes(): Observable<Array<MonthYear>> {
    return this.monthesSubject.asObservable();
  }

  get $statistics(): Observable<OvertimeStatistics> {
    return this.statisticSubject.asObservable();
  }

  get $singleOvertime(): Observable<OvertimeRecord> {
    return this.singleOvertimeSubject.asObservable();
  }

  public getOvertimes(queries?: Params): Observable<Paginate<OvertimeRecord>> {
    let queryParams = new HttpParams();

    if (queries.year && queries.month) {
      queryParams = queryParams.set('year', queries.year);
      queryParams = queryParams.set('month', queries.month);
    }

    if (queries.project && queries.project !== 'all') {
      queryParams = queryParams.set('project', queries.project);
    }

    if (this.userService.profile.isStaff && queries.user && queries.user !== 'all') {
      queryParams = queryParams.set('user', queries.user);
    }

    return this.overtimeHttp.get<Paginate<OvertimeRecord>>('', queryParams).pipe(
      tap((overtimes: Paginate<OvertimeRecord>) => {
        this.overtimesSubject.next(overtimes?.results);
        this.statisticSubject.next({
          selectedMonth: overtimes?.selectedMonth,
          previousMonth: overtimes?.previousMonth,
        });
      })
    );
  }

  public addOvertime(body: OvertimePost): Observable<OvertimePost> {
    return this.overtimeHttp.post<OvertimePost>('', body);
  }

  public async getMonthes(slug: string, queries?: Params): Promise<Array<MonthYear>> {
    let queryParams = new HttpParams();

    if (queries?.user) {
      queryParams = queryParams.set('user', queries?.user);
    }

    const monthes = await this.overtimeHttp.get<Array<MonthYear>>(slug, queryParams).toPromise();
    this.monthesSubject.next(monthes);

    return monthes;
  }

  public getOvertime(id: string | number): Observable<OvertimeRecord> {
    return this.overtimeHttp.get(`${id}/`)
      .pipe(
        tap((response: OvertimeRecord) => this.singleOvertimeSubject.next(response)),
      );
  }

  public deleteOvertime(id: string | number): Observable<any> {
    return this.overtimeHttp.delete(`${id}/`);
  }

  public editOvertime(id: number | string, body: OvertimePost): Observable<OvertimePost> {
    return this.overtimeHttp.put<OvertimePost>(`${id}/`, body);
  }
}
