import { Component, HostListener, OnInit } from '@angular/core';

import { FloatingHelpService } from '../../shared/services/floating-help.service';

@Component({
  selector: 'ffcrm-main',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

  constructor(private floatingHelpService: FloatingHelpService) { }

  public ngOnInit(): void {}

  @HostListener('mousemove', ['$event']) mouseMove(event): void {
    this.floatingHelpService.setMousePos(event);
  }

}
