import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RouterModule } from '@angular/router';

import { AllUsersRoutingModule } from './all-users-routing-module';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    AllUsersRoutingModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    ClipboardModule,
    RouterModule,
  ]
})
export class AllUsersModule { }
