<div class="current-item" tabindex="0">
  <div class="current-item-main-content"
       [class.readonly]="($project | async)?.isReadonly || monthIsClosed"
       [class.user]="!($profile | async)?.isStaff"
       *ngIf="!isNoProject; else ifNoProjects"
  >
    <div class="head with-margin-pillow">
      <a class="button-back" routerLink="../../" *ngIf="($profile | async)?.isStaff"></a>
      <div class="head-content">
        <div class="summary">
          <h1>
            {{ ($project | async)?.name }}
            <span class="project-name" *ngIf="groupName">({{ groupName }})</span>
            <span class="read-only-summary" *ngIf="($project | async)?.isReadonly">
              [Read only]
            </span>
          </h1>
          <div class="statistic">
            <div class="stat-item">
              <p class="text">Selected month</p>
              <p class="number">{{($tasks | async)?.selectedMonth || 0 | hhmm }}</p>
            </div>
            <div class="stat-item">
              <p class="text">Previous month</p>
              <p class="number">{{($tasks | async)?.previousMonth || 0 | hhmm }}</p>
            </div>
            <div class="stat-item">
              <p class="text">Total time</p>
              <p class="number">{{($tasks | async)?.totalTime || 0 | hhmm }}</p>
            </div>
            <div class="stat-item">
              <p class="text">Estimation</p>
              <p class="number">{{($tasks | async)?.estimation || 0 | hhmm }}</p>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="block">
            <a class="edit-button" routerLink="./edit" *ngIf="($profile | async)?.isStaff" queryParamsHandling="preserve">
              <i class="icon icon-edit icon-18px"></i>
            </a>
            <div class="search">
              <div class="input-block">
                <i class="icon icon-search search-icon"></i>
                <input #search class="input-field input-search" (input)="searchTasks()" type="text" id="search"
                       placeholder="Search">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="filter with-margin-pillow">
      <h2>Tasks</h2>
      <div class="filter-right">
        <input type="button" class="filter-button month-close" [class.closed]="monthIsClosed" (click)="openCloseMonthDialog()"
               *ngIf="($profile | async)?.isStaff && !($project | async)?.isReadonly"/>
        <input type="button" class="filter-button google" (click)="google()" *ngIf="($profile | async)?.isStaff"/>
        <input type="button" class="filter-button print" (click)="print()" *ngIf="($profile | async)?.isStaff"/>
        <div class="filters-wrapper">
          <ffcrm-select *ngIf="userInitOption && ($profile | async)?.isStaff === false"
                        class="select"
                        [options]="userSelectOptions"
                        (selected)="selectUserFilter($event)"
                        [model]="userInitOption"></ffcrm-select>
          <ffcrm-autocomplete *ngIf="($profile | async)?.isStaff"
                            (valueChange)="handleUsers($event)"
                            (focusEvent)="usersList = coldUsersList; dropdownShow = true;"
                            class="user-filter"
                            [value]="usernameFromQueries"
                            [dropdownShow]="dropdownShow"
                            [canWrite]="true"
                            [options]="usersList | fullname"
                            labelKey="fullName"
                            placeholder="Enter name"></ffcrm-autocomplete>
          <ffcrm-select *ngIf="initOption"
                        class="select"
                        [options]='selectOptions'
                        (selected)="selectMonthFilter($event)"
                        [value]="initOption"></ffcrm-select>
        </div>
      </div>
    </div>

    <ffcrm-table *ngIf="isAdmin || ($tasks | async)?.tasks?.length" selectableRows="true" [data]="tableData | highlightNewRow:differenceTableData" [skipCols]="skipCols" [cellTemplates]="tableSettings" (rowClicked)="openTimeEntriesPage($event)">
    </ffcrm-table>

    <div class="empty" *ngIf="!isAdmin && !($tasks | async)?.tasks?.length">
      <div class="empty-content" *ngIf="($tasks | async) !== null">
        It's time for a new startup, isn't it?
      </div>
    </div>

    <a class="btn btn-block btn-add with-margin-pillow" #addTaskButton *ngIf="($profile | async)?.isStaff === false && !monthIsClosed && !($project | async)?.isReadonly" routerLink="./task/add" queryParamsHandling="preserve">
      Add task
    </a>
  </div>
</div>
<ffcrm-accept
  [isVisible]="closeMonthDialog"
  [title]="monthIsClosed ? 'Open month' : 'Close month'"
  [description]="monthIsClosed ? 'The month will be opened. Users will be able to add time to this month again.' : 'The month will be closed. Users will not be able to add time to this month'"
  [acceptBtnText]="monthIsClosed ? 'Open month' : 'Close month'"
  (cancel)="closeMonthDialog = !closeMonthDialog"
  (accept)="closeMonth($event); closeMonthDialog = !closeMonthDialog"
></ffcrm-accept>

<ffcrm-add-users-drug-and-drop
  [(state)]="usersModalState"
></ffcrm-add-users-drug-and-drop>
<router-outlet></router-outlet>

<ng-template #ifNoProjects>
  <ffcrm-empty-list></ffcrm-empty-list>
</ng-template>

<ng-template #tasksTitleValueTemplate let-item>
  <div class="table-title-complete">
    <div class="table-title-complete-left">
      <p>{{ item?.name }}</p>
      <div class="users-members" (click)="openTaskMembers(item); $event.stopPropagation();" *ngIf="($profile | async)?.isStaff">
        <div class="users-members-circle" *ngFor="let user of item?.users; index as i"
             [style.transform]="'translate(' + i * 50 + '%, -50%)'">
          <ffcrm-image [object]="user" srcKey="photo" [labelKeys]="['firstName', 'lastName']" classList="mini"></ffcrm-image>
        </div>
        <div class="users-members-circle users-members-total"
             [style.transform]="'translate(' + item?.users?.length * 50 + '%, -50%)'">
          {{ item?.users?.length }}
        </div>
      </div>
    </div>
    <div class="table-title-difficulty" [class.hidden-input]="!item?.difficulty">
      {{ item?.difficulty?.title }}
    </div>
  </div>
</ng-template>

<ng-template #tasksSimpleValueTemplate let-item>
  <div>
    {{ item }}
  </div>
</ng-template>

<ng-template #taskExtraTimeValueTemplate let-item>
  <div *ngIf="item; else extraTimeElse" class="cell-with-check"></div>
  <ng-template #extraTimeElse>
    {{ '-' }}
  </ng-template>
</ng-template>

<ng-template #tasksLinkTemplate let-item>
  <div class="copy-link-wrapper" (click)="$event.stopPropagation(); this.showTooltip($event)">
    <div class="copy-link-text">
      {{ item }}
    </div>
    <input type="button" class="table-button task-link-copy-button" *ngIf="item" (click)="copyLink(item)" [tabIndex]="($profile | async)?.isStaff && 1">
  </div>
</ng-template>

<ng-template #tasksEditValueTemplate let-item>
  <div class="add-edit-task-buttons-wrapper" [class.with-add]="!($profile | async)?.isStaff" (click)="$event.stopPropagation()">
    <a [routerLink]="'./task/' + item + '/add-time'" class="table-button task-add-time-button"
       *ngIf="!($profile | async)?.isStaff" queryParamsHandling="preserve"></a>
    <a [routerLink]="'./task/' + item + '/edit'" class="table-button task-edit-button" (keypress)="clickTableRowByFocus($event)" queryParamsHandling="preserve" [tabIndex]="($profile | async)?.isStaff && 1"></a>
  </div>
</ng-template>

<ng-template #tasksHoursValueTemplate let-item>
  <div>
    {{ item | hhmm }}
  </div>
</ng-template>

<ng-template #tasksDataValueTemplate let-item>
  <div *ngFor="let data of item">
    {{ data | date }}
  </div>
</ng-template>

<ng-template #taskRowTemplate let-item>
  <div class="row-table-inside" [class.activity-hover]="$taskId && ($taskId | async) === item">
  </div>
</ng-template>

<ffcrm-accept
  [isVisible]="isConfirmGoogleSheetOpening"
  description="Do you want to open the document in google sheet?"
  type="sheet"
  [link]="spreadSheetLink"
  (accept)="isConfirmGoogleSheetOpening = false"
  (cancel)="isConfirmGoogleSheetOpening = false"
></ffcrm-accept>


<ffcrm-tooltip></ffcrm-tooltip>
