import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { debounce } from 'typescript-debounce-decorator';

// Components
import { SubscriptionsComponent } from '../../../shared/super/subscriptions-component';

// Models
import { MonthYear } from '../../models/month-year.model';
import { UserProfile } from '../../../shared/models/user-profile.model';
import { Profile } from '../../../shared/models/profile.model';
import { Activity } from '../../interfaces/activity.model';

// Services
import { NotificationService } from '../../services/notification/notification.service';
import { ActivityService } from '../../services/activity/activity.service';
import { headerAnimations } from '../../../shared/animations/select';
import { AuthService } from '../../../auth/services/auth.service';
import { UserService } from '../../../shared/services/user/user.service';
import { TaskHighlightService } from '../../../shared/services/task-highlight.service';

@Component({
  selector: 'ffcrm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: headerAnimations,
})
export class HeaderComponent extends SubscriptionsComponent implements OnInit, OnDestroy {

  @ViewChild('dropdown') dropDown;
  @ViewChild('activities') activities;
  @ViewChild('activities_block') activitiesBlock;
  @ViewChild('notifications') notifications;
  @ViewChild('activities_menu', { static: false }) activitiesMenu;
  @ViewChild('notifications_menu', { static: false }) notificationsMenu;

  private activityOffset = 0;
  private notificationsOffset = 0;
  private period = { id: 'all', label: 'All' };
  public loaded: boolean;
  public haveUnreadNotifications: boolean;
  public dropDownCollapsed: boolean;
  public activitiesOpen: boolean;
  public notificationsOpen: boolean;
  public activitiesList: Array<Activity> = [];
  public notificationsList: any = [];
  public selectOptions = [
    { id: 'all', label: 'All' },
    { id: 'current', label: 'Current month' },
    { id: 'previous', label: 'Previous month' },
  ];
  public usersList: Array<Profile>;
  private coldUsersList: Array<Profile>;

  get isStaff(): boolean {
    return this.userService?.profile?.isStaff;
  }

  public editProfileDialog: boolean;
  public changePasswordDialog: boolean;
  public readonly $profile: Observable<Profile>;
  public readonly $userSidebar: Observable<boolean>;
  public readonly $notificationsMessages: Observable<any>;

  constructor(private userService: UserService,
              private authService: AuthService,
              private activityService: ActivityService,
              private notificationService: NotificationService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private taskHighlightService: TaskHighlightService,
              ) {
    super();

    this.notificationService.getNotifications(
      Object.assign({offset: this.notificationsOffset}, this.queryParams
    )).toPromise().then(({ results }) => this.notificationsList = results);
    if (!this.isStaff) {
      this.activityService.getActivities({offset: this.activityOffset}).toPromise().then(r => this.activitiesList = r);
    }
    this.$profile = this.userService.$profile;
    this.$userSidebar = this.userService.$userSidebar;
    this.$notificationsMessages = this.notificationService.$notificationsMessages;
  }

  get queryParams(): Params {
    return this.activatedRoute.snapshot.queryParams;
  }

  get usernameFromQueries(): string {
    const id = +this.queryParams?.user_activity;

    if (!id) {
      return '';
    }

    const profile = this.usersList.find(user => user?.id === id);

    if (!profile?.firstName || !profile?.lastName) {
      return '';
    }

    return profile.firstName + ' ' + profile.lastName;
  }

  get isHomePath(): boolean {
    return this.router.url.includes('home');
  }

  get activityPeriod(): string {
    return this.period?.id;
  }

  get users(): Array<UserProfile> {
    return this.userService.usersSubj.value;
  }

  get currentUrl(): string {
    return this.router.url;
  }

  get currentDate(): MonthYear {
    const now = new Date(Date.now());
    return {
      year: now.getFullYear(),
      month: +now.getMonth() + 1,
    };
  }

  public async ngOnInit(): Promise<void> {
    if (this.notificationService.token) {
      this.subscriptions.push(
        this.$notificationsMessages.subscribe(() => {
          this.haveUnreadNotifications = true;
        }),
        this.userService.$users.subscribe((users) => {
          this.usersList = users;
          this.coldUsersList = users;
        }),
      );
    }
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public toggleDropdown(): void {
    this.dropDownCollapsed = !this.dropDownCollapsed;
  }

  public logout(): void {
    this.authService.logout();
    location.reload();
  }

  public changePassword(): void {
    this.dropDownCollapsed = false;
    this.changePasswordDialog = true;
  }

  public editProfile(): void {
    this.dropDownCollapsed = false;
    this.editProfileDialog = true;
  }

  public getUpdatedUser(user): void {
    console.log(user);
  }

  public toggleSidebar(): void {
    this.userService.toggleSidebar();
  }

  public setId(id): void {
    this.taskHighlightService.setTaskId(id);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement): void {
    const clickedInsideDropDown = this.dropDown?.nativeElement?.contains(targetElement);
    if (!clickedInsideDropDown) {
      this.dropDownCollapsed = false;
    }

    const clickedInsideActivitiesMenu = this.activitiesMenu?.nativeElement?.contains(targetElement);
    if (this.activities) {
      const clickedInsideActivities = this.activities?.nativeElement?.contains(targetElement);
      if (!clickedInsideActivities && !clickedInsideActivitiesMenu) {
        this.activitiesOpen = false;
      }
    }

    const clickedInsideNotificationsMenu = this.notificationsMenu?.nativeElement?.contains(targetElement);
    if (this.notifications) {
      const clickedInsideNotifications = this.notifications?.nativeElement?.contains(targetElement);
      if (!clickedInsideNotifications && !clickedInsideNotificationsMenu) {
        this.notificationsOpen = false;
      }
    }
  }

  public isLinkActive(requiredUrl): boolean {
    const regExp: RegExp = new RegExp (requiredUrl);
    return regExp.test(this.router.url);
  }

  public toggleNotifications(): void {
    this.notificationsOpen = !this.notificationsOpen;
    this.haveUnreadNotifications = false;
    if (this.notificationsOpen) {
      this.activitiesOpen = false;
    }
  }

  public toggleActivities(): void {
    this.activitiesOpen = !this.activitiesOpen;
    if (this.activitiesOpen) {
      this.notificationsOpen = false;
    }
  }

  public dateParserWithTime(timestamp: string): string {
    const date: Date = new Date(Date.parse(timestamp));
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = String(date.getDate()).padStart(2, '0');
    const minutes = date.getMinutes();
    const hour = date.getHours();
    const monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return `${day}.${monthsList[month]}.${year} / ${hour}:${minutes < 10 ? '0' + minutes : minutes}`;
  }

  public dateParser(timestamp: string): string {
    const date: Date = new Date(Date.parse(timestamp));
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = String(date.getDate()).padStart(2, '0');
    const monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return `${day}.${monthsList[month]}.${year}`;
  }

  public async selectOption(value): Promise<void> {
    if (this.usersList.find(user => user?.id === value) && +this.queryParams?.user_activity !== +value) {
      await this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          user_activity: value,
        },
        queryParamsHandling: 'merge',
      });

      this.activitiesBlock.nativeElement.scrollTop = 0;
      this.activityOffset = 0;
      const response = await this.activityService.getActivitiesList(
        Object.assign({offset: this.activityOffset}, this.queryParams)
      ).toPromise();
      this.activitiesList = response;
    }
  }

  public async handleUsers(value: string): Promise<void> {
    if (!value) {
      await this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          user_activity: 'all'
        },
        queryParamsHandling: 'merge',
      });

      this.activityOffset = 0;
      this.activitiesBlock.nativeElement.scrollTop = 0;
      const response = await this.activityService.getActivitiesList(
        Object.assign({offset: this.activityOffset}, this.queryParams)
      ).toPromise();
      this.activitiesList = response;
    }

    this.usersList = this.coldUsersList.filter(({ firstName, lastName }: Profile) => {
      return (firstName + ' ' + lastName)?.toLowerCase().indexOf(value?.toLowerCase()) !== -1;
    });
  }

  public filter(key): void {
    this.activityOffset = 0;
    this.activitiesBlock.nativeElement.scrollTop = 0;
    this.period = this.selectOptions.find((el) => el.id === key);

    if (key !== 'all') {
      this.activityService.getActivities({ period: key }).toPromise().then(r => this.activitiesList = r);
    } else {
      this.activityService.getActivities().toPromise().then(r => this.activitiesList = r);
    }
  }

  @debounce(350)
  public async activitiesScroll({ target }): Promise<void> {
    if ((target.scrollTop + target.clientHeight) >= target.scrollHeight - 1) {
      this.activityOffset += 15;
      if (this.isStaff) {
        const response = await this.activityService.getActivitiesList(
          Object.assign({offset: this.activityOffset}, this.queryParams)
        ).toPromise();
        this.activitiesList = this.activitiesList.concat(response);
      } else {
        const response = await this.activityService.getActivities(
          {offset: this.activityOffset,
          ...(this.period?.id !== 'all' && {prediod: this.period?.id})}
        ).toPromise();
        this.activitiesList = this.activitiesList.concat(response);
      }
    }
  }

  @debounce(350)
  public async notificationsScroll({ target }): Promise<void> {
    if ((target.scrollTop + target.clientHeight) >= target.scrollHeight - 1) {
      this.notificationsOffset += 15;
      if (this.isStaff) {
        const response = await this.notificationService.getNotifications(
          Object.assign({offset: this.notificationsOffset}, this.queryParams
        )).toPromise();
        if (response?.results) {
          this.notificationsList = this.notificationsList.concat(response?.results);
        }
      }
    }
  }
}
