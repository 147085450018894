import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightNewRow'
})
export class HighlightNewRowPipe implements PipeTransform {

  transform(data: Array<object>, difference: Array<object>): unknown {
    if (!difference || !difference.length || difference.length >= data.length) {
      return data;
    }

    difference?.forEach((item) => {
      const index = data.findIndex((dataItem) => JSON.stringify(item) === JSON.stringify(dataItem));
      if (data[index]) {
        (data[index] as any).isNew = true;
      }
    });

    return data;
  }
}
