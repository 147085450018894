import {Pipe, PipeTransform} from '@angular/core';

// Models
import { ManageProject } from '../models/manage-project.model';
import { Group } from '../models/group.model';


@Pipe({ name: 'starred'})
export class StarredProjectGroupPipe implements PipeTransform {
  transform(value: any, ...args): Array<ManageProject> | Array<Group> {
    return value?.filter(item => item?.isStarred && item?.isActive);
  }
}
