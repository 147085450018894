import { ApiClient } from './api-client.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpClientFactoryService {

  constructor(private http: HttpClient) { }

  public getHttpClient(path: string): ApiClient {
    return new ApiClient(path, this.http);
  }
}
