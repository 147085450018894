<ffcrm-dialog *ngIf="itemSelected"
              (choice)="changeGroup($event)"
              [(visible)]="state.open"
              (visibleChange)="dialogVisibleChange()"
              [dropdownChoices]="state.mode === 'group' ? dropdownChoices : null"
              dropdownIdKey="id"
              dropdownLabelKey="name"
              [titleKey]="state.mode === 'group' || state.mode === 'task' ? 'title' : state.mode === 'project' ? 'name' : null"
              [title]="itemSelected"
              [closable]="false"
              class="inner-diaog">
  <div *ngIf="members !== undefined" class="dialog-body">
    <div class="column">
      <div class="column-header">
        <span>{{ modalTitleType }} participants</span>
        <div class="a">
          <span class="count">{{ members?.length + (groupMembers?.length || 0) }} users</span>
          <span class="count" *ngIf="groupMembers?.length">{{ ': ' + groupMembers?.length }} group and {{ members?.length }} project users</span>
        </div>
      </div>
      <div class="card-list" cdkDropList #groupParticipants="cdkDropList"
           [cdkDropListConnectedTo]="[nonGroupParticipants]"
           (cdkDropListDropped)="dropUser($event)"
           [cdkDropListData]="members"
           [cdkDropListDisabled]="state.mode === 'task'"
      >
        <div class="card-container">
          <div class="left-search">
            <i class="icon icon-search search-icon"></i>
            <input #leftSearchInput class="input-field input-search" type="text" placeholder="Search" (input)="filterLeftColumnMembers($event.target.value)">
          </div>
          <ng-container *ngFor="let user of members">
            <div class="card-wrapper" cdkDrag>
              <div class="card-preview">
                <ffcrm-card [isAdmin]="user.isStaff" [isActive]="user.isActive" type="user" [instanceId]="user.id" [additionalClasses]="state.mode === 'task' ? 'no-pointer' : ''"></ffcrm-card>
              </div>
            </div>
          </ng-container>
          <ng-container *ngFor="let user of groupMembers">
            <div class="card-wrapper marked" cdkDrag cdkDragDisabled="true">
              <div class="card-preview">
                <ffcrm-card [isAdmin]="user.isStaff" [isActive]="user.isActive" marked="true" markText="group" type="user" [instanceId]="user.id" additionalClasses="no-pointer"></ffcrm-card>
              </div>
            </div>
          </ng-container>
          <ng-container>

          </ng-container>
        </div>
        <div class="drop-area-layer">
          <img src="../../../../assets/icons/add-circle.svg" alt="Add">
          <strong>Add new project member</strong>
        </div>
      </div>
    </div>
    <div class="column" [style.display]="state.mode !== 'task' ? 'block' : 'none'">
      <div class="column-header">
        <span>Non {{ modalTitleType.toLowerCase() }} participants</span>
        <span class="count">{{nonMembers?.length}} users</span>
      </div>
      <div class="card-list" cdkDropList #nonGroupParticipants="cdkDropList"
      [cdkDropListConnectedTo]="[groupParticipants]"
      (cdkDropListDropped)="dropUser($event)"
      [cdkDropListData]="nonMembers">
      <div class="card-container">
        <div class="right-search">
          <i class="icon icon-search search-icon"></i>
          <input #rightSearchInput class="input-field input-search" type="text" placeholder="Search" (input)="filterNonMembers($event.target.value)">
        </div>
        <ng-container *ngFor="let user of nonMembers">
          <div class="card-wrapper" cdkDrag>
            <div class="card-preview">
              <ffcrm-card [isAdmin]="user.isStaff" [isActive]="user.isActive" type="user" [instanceId]="user.id"></ffcrm-card>
            </div>
          </div>
        </ng-container>
        </div>
        <div class="drop-area-layer">
          <strong>Remove member from project</strong>
        </div>
      </div>
    </div>
  </div>
</ffcrm-dialog>
