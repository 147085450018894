import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

// Models
import { Project } from '../../../../shared/models/project.model';

// Services
import { UsersProjectService } from '../../../services/all-users/users-projects/users-project.service';


@Injectable({
  providedIn: 'root'
})
export class ProjectsAllResolver implements Resolve<Array<Project>> {

  constructor(private projectService: UsersProjectService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Project>> {
    return this.projectService.getAllProjecsAsync();
  }
}
