import { ActivatedRoute, Params, Router } from '@angular/router';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

// Components
import { MonthFilterComponent } from '../../../shared/super/month-filter-component';

// Services
import { IMonthYearFilterService, YEAR_MONTH_FILTER_SERVICE } from '../../services/injection-tokens/month-year-filter-token';
import { OvertimeService } from '../../../shared/services/overtime.service';
import { GoogleExportService } from '../../services/google-export/google-export.service';
import { UserService } from '../../../shared/services/user/user.service';
import { Title } from '@angular/platform-browser';

// Models
import { TableRowSettings } from '../../models/table-row-settings';
import { OvertimeRecord, OvertimeStatistics } from '../../interfaces/overtime.model';
import { UserProfile } from '../../../shared/models/user-profile.model';
import { MonthYear } from '../../models/month-year.model';
import { Profile } from '../../../shared/models/profile.model';

// Utils
import findDifferents from '../../../shared/utils/find-difference-in-objects';

@Component({
  selector: 'ffcrm-overtime',
  templateUrl: './overtime.component.html',
  styleUrls: ['./overtime.component.scss'],
  providers: [
    {
      provide: YEAR_MONTH_FILTER_SERVICE,
      useExisting: OvertimeService,
    },
  ],
})
export class OvertimeComponent extends MonthFilterComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    @Inject(YEAR_MONTH_FILTER_SERVICE) filterService: IMonthYearFilterService,
    private cd: ChangeDetectorRef,
    private overtimeService: OvertimeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private googleExportService: GoogleExportService,
    private titleService: Title
  ) {
    super(filterService);
    this.$userSidebar = this.userService.$userSidebar;
    this.$profile = this.userService.$profile;
    this.$statistics = this.overtimeService.$statistics;
  }

  @ViewChild('search') search;
  @ViewChild('editTableTemplate') public editTableTemplate: TemplateRef<any>;
  @ViewChild('userTemplate') public userTableTemplate: TemplateRef<any>;
  @ViewChild('projectTemplate') public projectTableTemplate: TemplateRef<any>;
  @ViewChild('taskTemplate') public taskTableTemplate: TemplateRef<any>;
  @ViewChild('dateTemplate') public dateTableTemplate: TemplateRef<any>;
  @ViewChild('hoursTemplate') public hoursTableTemplate: TemplateRef<any>;

  public $userSidebar: Observable<boolean>;
  public $statistics: Observable<OvertimeStatistics>;
  public $profile: Observable<Profile>;
  public tableData: Array<OvertimeRecord>;
  public differenceTableData: Array<OvertimeRecord>;
  public coldData: Array<OvertimeRecord>;
  public tableSettings: Array<TableRowSettings>;
  public skipCols: Array<TableRowSettings> = [];
  public currentMonth: MonthYear;
  public isConfirmGoogleSheetOpening = false;
  public spreadSheetLink: string;

  get profile(): Profile {
    return this.userService.profileSubj.value;
  }

  get users(): Array<UserProfile> {
    return this.userService.users;
  }

  get title(): string {
    if (this.activatedRoute.snapshot.queryParams?.user && this.activatedRoute.snapshot.queryParams?.user !== 'all') {
      const user = this.users.find(({ id }: UserProfile) => id.toString() === this.activatedRoute.snapshot.queryParams?.user);
      return user?.firstName + ' ' + user?.lastName;
    } else {
      return 'Overtime';
    }
  }

  private updateStateFromQueries(queries: Params): void {
    this.currentMonth = {
      year: queries?.year,
      month: queries?.month,
    };
  }

  async ngOnInit(): Promise<void> {
    const queryParams = this.activatedRoute.snapshot.queryParams;

    const confirmUrl = queryParams.confirm_url;
    const redirectUrl = queryParams.redirect_url;

    if (confirmUrl && redirectUrl) {
      const year: number = +queryParams?.year;
      const month: number = +queryParams?.month;
      const user: number = +queryParams?.user;

      const popUpSubscription = this.googleExportService.$popUpSubject.subscribe(link => {
        this.spreadSheetLink = link;
        this.isConfirmGoogleSheetOpening = true;
        popUpSubscription.unsubscribe();
      });

      this.googleExportService.exportUtil(confirmUrl, redirectUrl, 'startOvertimesWS', [year, month, user]);
    }

    this.titleService.setTitle('Timerilo - Overtime');

    this.subscriptions.push(
      this.overtimeService.$overtimes.subscribe((response: Array<OvertimeRecord>) => {
        if (this.tableData) {
          this.differenceTableData = findDifferents(this.tableData, response);
        }
        this.tableData = response ?? [];
        this.coldData = response ?? [];
      }),

      this.activatedRoute.queryParams.subscribe(async (queries: Params) => {
        const prevCurrentMonth = { ...this.currentMonth };

        this.updateStateFromQueries(queries);
        if (Object.keys(prevCurrentMonth).length) {
          await this.overtimeService.getOvertimes(queries).toPromise();
        }
      }),

      this.filterService.$monthes.subscribe(() => {
        this.setMonthes(this.currentMonth);
      }),
    );
  }

  ngAfterViewInit(): void {
    this.tableSettings = [
      {
        key: 'task', title: 'User', classes: 'overtime-user', template: this.userTableTemplate, sort: true,
        sortCallbacks: { asc: this.sortUserAsc, desc: this.sortUserDesc }
      },
      {
        key: 'id', title: '', classes: 'overtime-edit', template: this.editTableTemplate, sort: false,
      },
      {
        key: 'project', title: 'Project', classes: 'overtime-project', template: this.projectTableTemplate, sort: true,
        sortCallbacks: { asc: this.sortProjectAsc, desc: this.sortProjectDesc }
      },
      {
        key: 'task', title: 'Task', classes: 'overtime-task', template: this.taskTableTemplate, sort: true,
        sortCallbacks: { asc: this.sortTasksAsc, desc: this.sortTasksDesc }
      },
      {
        key: 'minutes', title: 'Hours', classes: 'overtime-hours', template: this.hoursTableTemplate, sort: true,
        sortCallbacks: { asc: this.sortHoursAsc, desc: this.sortHoursDesc }
      },
      {
        key: 'date', title: 'Date', classes: 'overtime-date', template: this.dateTableTemplate, sort: true,
        sortCallbacks: { asc: this.sortDateAsc, desc: this.sortDateDesc }
      },
    ];

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(() => {
        if (!this.userService.profile?.isStaff ||
           (this.activatedRoute.snapshot.queryParams?.user && this.activatedRoute.snapshot.queryParams?.user !== 'all')) {
          this.skipCols = this.tableSettings.filter((col: TableRowSettings) => {
            return col?.title === 'User';
          });
        }

        if (this.userService.profile?.isStaff) {
          this.skipCols = this.tableSettings.filter((col: TableRowSettings) => {
            return col?.key === 'id';
          });
        }
      })
    );

    /**
     * called to avoid "ExpressionChangedAfterItHasBeenCheckedError"
     * get Templates available only after ViewInit, otherwise it return undefined
     */
    this.cd.detectChanges();
  }

  public async selectMonthFilter(monthYear: MonthYear): Promise<void> {
    await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams: {
        year: monthYear?.year,
        month: monthYear?.month,
      },
    });
  }

  public searchTasks(): void {
    const keyword = this.search.nativeElement?.value.toLowerCase();
    this.tableData = this.coldData?.filter((overtime: OvertimeRecord) => (
        overtime?.project?.name?.toLowerCase().includes(keyword)
        || overtime?.task?.title?.toLowerCase().includes(keyword)
        || overtime?.task?.fullName?.toLowerCase().includes(keyword)
      )
    );
  }

  private sortUserAsc(a, b): number {
    return a?.task?.fullName?.localeCompare(b?.fullName);
  }

  private sortUserDesc(a, b): number {
    return b?.task?.fullName?.localeCompare(a?.fullName);
  }

  private sortProjectAsc(a, b): number {
    return a?.project?.name?.localeCompare(b?.project?.name);
  }

  private sortProjectDesc(a, b): number {
    return b?.project?.name?.localeCompare(a?.project?.name);
  }

  private sortTasksAsc(a, b): number {
    return a?.task?.title?.localeCompare(b?.task?.title);
  }

  private sortTasksDesc(a, b): number {
    return b?.task?.title?.localeCompare(a?.task?.title);
  }

  private sortHoursAsc(a, b): number {
    return a.minutes - b.minutes;
  }

  private sortHoursDesc(a, b): number {
    return b.minutes - a.minutes;
  }

  private sortDateAsc(a, b): number {
    const aDate = [a.date].sort((d1, d2) => Date.parse(d1) - Date.parse(d2))[0];
    const bDate = [b.date].sort((d1, d2) => Date.parse(d1) - Date.parse(d2))[0];
    if (aDate > bDate) {
      return 1;
    } else if (aDate < bDate) {
      return -1;
    }
    return 0;
  }

  private sortDateDesc(a, b): number {
    const aDate = [a.date].sort((d1, d2) => Date.parse(d1) - Date.parse(d2))[0];
    const bDate = [b.date].sort((d1, d2) => Date.parse(d1) - Date.parse(d2))[0];

    if (aDate < bDate) {
      return 1;
    } else if (aDate > bDate) {
      return -1;
    }
    return 0;
  }

  public async google(): Promise<void> {
    const response = await this.googleExportService.authGoogle(window.location.origin + '/home/google-auth/');
    localStorage.setItem('redirectUrl', window.location.pathname);
    localStorage.setItem('redirectQueryParams', window.location.search);
    if (response?.authUrl) {
      window.location.href = response?.authUrl;
    } else {
      alert('Error');
    }
  }

  public async print(): Promise<void> {
    console.log('todo: print function add');
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

}
