import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Group } from '../../shared/models/group.model';

import { GroupService } from '../services/group/group.service';
import { Project } from '../../shared/models/project.model';
import { UserService } from '../../shared/services/user/user.service';
import { ProjectService } from '../services/project/project.service';


@Injectable({
  providedIn: 'root'
})
export class GroupsProjectResolver implements Resolve<Array<Group | Project>> {

  constructor(private userService: UserService, private groupService: GroupService, private projectService: ProjectService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Group | Project>> {
    return this.userService.profile.isStaff ? this.groupService.getGroupsAsync() : this.projectService.getProjectsAsync();
  }
}
