import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TaskHighlightService {
  public taskId: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() { }

  get $taskId(): Observable<string> {
    return this.taskId.asObservable();
  }

  public setTaskId(id): void {
    this.taskId.next(id);
  }
}
