import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Task } from '../../shared/models/task.model';
import { Observable } from 'rxjs';
import { TaskService } from '../services/task/task.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TaskResolver implements Resolve<Task> {

  constructor(
    private taskService: TaskService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Task> {
    let queries = new HttpParams();

    if (route.queryParams?.year) {
      queries = queries.set('year', route.queryParams?.year);
    }

    if (route.queryParams?.month) {
      queries = queries.set('month', route.queryParams?.month);
    }

    if (+route.queryParams?.activity) {
      queries = queries.set('activity', route.queryParams?.activity);
    }

    return this.taskService.getCurrentTaskAsync(route.parent.params?.projectSlug || route.params?.projectSlug,
      route.params?.taskId, queries);
  }
}
