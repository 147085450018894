import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpClientFactoryService } from 'src/app/shared/services/http-client-factory.service';
import { ApiClient } from '../../../shared/services/api-client.model';
import { Paginate } from '../../interfaces/paginate.model';
import { Activity } from '../../interfaces/activity.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  http: ApiClient;

  constructor(
    private httpFactory: HttpClientFactoryService,
  ) {
    this.http = this.httpFactory.getHttpClient('/activity/');
  }

  public getActivities(userParams?): Observable<Array<Activity>> {
    let params = new HttpParams({ fromObject: userParams });

    params = params.set('limit', '15');

    if (userParams?.offset) {
      params = params.set('offset', userParams.offset);
    }

    if (userParams?.period) {
      params = params.set('period', userParams.period);
    }

    return this.http.get<Paginate<Activity>>('', params)
      .pipe(map((activities: Paginate<Activity>) => activities?.results)) as Observable<Array<Activity>>;
  }

  public getActivitiesList(userParams?: Params): Observable<Array<Activity>> {
    let queryParams = new HttpParams();

    if (userParams?.user_activity && userParams?.user_activity !== 'all') {
      queryParams = queryParams.set('user', userParams.user_activity);
    }

    queryParams = queryParams.set('limit', '15');

    if (userParams?.offset) {
      queryParams = queryParams.set('offset', userParams.offset);
    }

    return this.http.get<Paginate<Activity>>('activity-list/', queryParams)
      .pipe(map((activities: Paginate<Activity>) => activities?.results)) as Observable<Array<Activity>>;
  }
}
