import { Component } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

// Components
import { FormComponent } from '../../../shared/super/form-component';

// Pipes
import { validateAllFields } from '../../../shared/pipes/validate-all-fields.pipe';

// Services
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'ffcrm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends FormComponent {

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {
    super();
  }

  public loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  public onSubmit(): void {
    if (this.loginForm.valid) {
      this.authService.loginUser(this.loginForm.value).subscribe(
        async () => {
          await this.router.navigate(['/']);
        },
        (error) => {
          this.loginForm.setErrors({ incorrect: error.error.nonFieldErrors });
        });
    } else {
      validateAllFields(this.loginForm);
    }
  }
}
