<ffcrm-dialog [(visible)]="cropperDialog" (visibleChange)="visibleChange($event)" [isCreatePhoto]="true" title="Create a photo">
  <div class="dialog-body">
    <div class="cropper-wrapper">
      <angular-cropper #cropper [imageUrl]="image" [cropperOptions]="config"></angular-cropper>
      <div class="range">
        <i class="icon icon-image icon-18px"></i>
        <input #range type="range" min="0" max="100" value="50" class="slider" (input)="changeSize()">
        <i class="icon icon-image icon-24px"></i>
      </div>
    </div>

    <div class="actions">
      <button class="btn btn-light btn-block" (click)="visibleChange(false)">Cancel</button>
      <button class="btn btn-block" (click)="cropImage()">Upload</button>
    </div>
  </div>
</ffcrm-dialog>
