import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImagesPreloaderService {
  private imageList = [];

  public appInit(): void {
    this.preload(
      '../../assets/icons/billed.svg',
      '../../assets/icons/edit.svg',
      '../../assets/icons/exit.svg',
      '../../assets/icons/key.svg',
      '../../assets/icons/blue/edit-blue.svg',
      '../../assets/icons/blue/exit-blue.svg',
      '../../assets/icons/blue/key-blue.svg',
    );
  }

  private preload(...args: any[]): void {
    for (let i = 0; i < args.length; i++) {
      this.imageList[i] = new Image();
      this.imageList[i].src = args[i];
      // console.log('loaded: ' + args[i]);
    }
  }
}
