import { Component, Inject } from '@angular/core';

// Components
import { SubscriptionsComponent } from './subscriptions-component';

// Models
import { MonthYear } from '../../main/models/month-year.model';

// Services
import { IMonthYearFilterService, YEAR_MONTH_FILTER_SERVICE } from '../../main/services/injection-tokens/month-year-filter-token';


@Component({
  template: ''
})
export abstract class MonthFilterComponent extends SubscriptionsComponent {
  protected constructor(@Inject(YEAR_MONTH_FILTER_SERVICE) filterService: IMonthYearFilterService) {
    super();

    this.filterService = filterService;
  }

  protected filterService: IMonthYearFilterService;
  protected monthDisplays = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  public currentMonth;
  public selectOptions;
  public initOption;

  protected setMonthes(monthYear?: MonthYear): void {
    const monthes = this.filterService.monthes;

    try {
      const options = [];

      if (monthes?.length > 0) {
        monthes.forEach((month: MonthYear) => {
          options.push({
            id: month,
            label: `${this.monthDisplays[month.month]} ${month.year.toString()}`,
          });
        });
      } else {
        const now = new Date(Date.now());
        this.currentMonth = {
          month: now.getMonth() + 1,
          year: now.getFullYear()
        };
        options.push({
          id: this.currentMonth,
          label: `${this.monthDisplays[now.getMonth() + 1]} ${now.getFullYear().toString()}`,
        });
      }

      this.selectOptions = options;
      this.initOption = options.find((item) => item.id.month === +monthYear?.month) || options[0];
    }
    catch (err) {
      console.error(err);
    }
  }
}
