import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

// Models
import { UserTasksList } from '../interfaces/user-tasks-list.model';

// Services
import { UserTaskService } from '../services/user-task/user-task.service';


@Injectable({
  providedIn: 'root'
})
export class UserTasksResolver implements Resolve<UserTasksList> {

  constructor(private taskService: UserTaskService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserTasksList> {
    return this.taskService.getUserTasksAsync(route.params?.userId, route?.queryParams);
  }
}
