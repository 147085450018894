import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'ffcrm-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  public isAuthenticated = false;

  constructor(
    private location: Location,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.isAuthenticated = !(this.authService?.currentUserTokenValue === null);
  }

  goBack(): void {
    this.location?.back();
  }
}
