import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';

// Components
import { SubscriptionsComponent } from '../../../shared/super/subscriptions-component';

// Models
import { GroupProject } from '../../../shared/models/group-project.model';
import { Group } from '../../../shared/models/group.model';


@Component({
  selector: 'ffcrm-user-participations-modal',
  templateUrl: './user-participations-modal.component.html',
  styleUrls: ['./user-participations-modal.component.scss']
})
export class UserParticipationsModalComponent extends SubscriptionsComponent implements OnInit {
  public groups: Array<Group>;
  public projects: Array<GroupProject>;
  public selectedGroup: string;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) {
    super();
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.activatedRoute.data.subscribe((routeData: Data) => {
        this.groups = routeData?.groups;

        if (this.groups?.length) {
          this.selectGroup(this.groups[0]);
        }
      })
    );
  }

  public async onStateChange(): Promise<void> {
    await this.router.navigate(['../'], {
      queryParamsHandling: 'preserve',
      relativeTo: this.activatedRoute,
    });
  }

  public selectGroup(group: Group): void {
    this.selectedGroup = group.slug;
    this.projects = group.projects;
  }

}
