import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WebSocketSubject } from 'rxjs/internal-compatibility';

// Models
import { Paginate } from '../../interfaces/paginate.model';
import { Notification } from '../../interfaces/notification.model';
import { ApiClient } from '../../../shared/services/api-client.model';
import { NotificationParam } from '../../interfaces/notification-param.model';

// Services
import { HttpClientFactoryService } from '../../../shared/services/http-client-factory.service';

// Constants
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private http: ApiClient;
  private socketSubject: WebSocketSubject<any>;
  private userToken: string;

  get token(): string {
    return this.userToken;
  }

  constructor(private httpFactory: HttpClientFactoryService) {
    this.userToken = localStorage.getItem('token');
    this.http = this.httpFactory.getHttpClient('/activity/');
    if (this.token) {
      this.socketSubject = new WebSocketSubject(`${environment.wsRoot}/notifications/?token=${this.token}`);
    }
  }

  public getNotifications(notificationRequestParam?: NotificationParam): Observable<Paginate<Notification>> {
    let params = notificationRequestParam ? new HttpParams({ ...notificationRequestParam as HttpParams }) : new HttpParams({});
    params = params.set('limit', '15');

    if (notificationRequestParam?.offset) {
      params = params.set('offset', notificationRequestParam.offset.toString());
    }
    return this.http.get<Paginate<Notification>>('notifications', params);
  }

  get $notificationsMessages(): Observable<any> {
    return this.socketSubject?.asObservable() || new Observable();
  }

}
