import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';

// Components
import { MonthFilterComponent } from '../../../shared/super/month-filter-component';

// Models
import { TimeEntryActivity } from '../../../shared/models/time-entry-activity.model';
import { ProjectTasks } from '../../../shared/models/project-tasks.model';
import { TableRowSettings } from '../../models/table-row-settings';
import { Profile } from '../../../shared/models/profile.model';
import { MonthYear } from '../../models/month-year.model';
import { Task } from '../../../shared/models/task.model';

// Services
import { IMonthYearFilterService, YEAR_MONTH_FILTER_SERVICE } from '../../services/injection-tokens/month-year-filter-token';
import { TaskService } from '../../services/task/task.service';
import { UserService } from '../../../shared/services/user/user.service';
import { getDate } from '../../../shared/utils/getDate';

// Utils
import findDifferents from '../../../shared/utils/find-difference-in-objects';

@Component({
  selector: 'ffcrm-current-task',
  templateUrl: './current-task.component.html',
  styleUrls: ['./current-task.component.scss'],
  providers: [
    {
      provide: YEAR_MONTH_FILTER_SERVICE,
      useExisting: TaskService,
    },
  ],
})
export class CurrentTaskComponent extends MonthFilterComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    @Inject(YEAR_MONTH_FILTER_SERVICE) filterService: IMonthYearFilterService,
    private taskService: TaskService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
  ) {
    super(filterService);

    this.subscriptions.push(
      this.activatedRoute.params?.subscribe((params: Params) => {
        this.projectSlug = params.projectSlug;
      }),
    );

    this.$tasks = this.taskService.$tasks;
    this.$profile = this.userService.$profile;
    this.$currentTask = this.taskService.$currentTask;
  }

  public $tasks: Observable<ProjectTasks>;
  public $currentTask: Observable<Task>;
  public $profile: Observable<Profile>;

  public projectSlug;
  public currentTimeEntryId: number = null;
  public monthIsClosed = false;
  public tableSettings: Array<TableRowSettings> = [];
  public skipCols: Array<TableRowSettings> = [];
  public tableData = [];
  public differenceTableData = [];
  public prevTableData = [];
  public activitiesInitData;
  public activitiesList = [];
  public currentActivity;

  @ViewChild('tasksOvertimeValueTemplate') public tasksOvertimeValueTemplate: TemplateRef<any>;
  @ViewChild('tasksActivityValueTemplate') public tasksActivityValueTemplate: TemplateRef<any>;
  @ViewChild('tasksDescriptionValueTemplate') public tasksDescriptionValueTemplate: TemplateRef<any>;
  @ViewChild('tasksSimpleValueTemplate') public tasksSimpleValueTemplate: TemplateRef<any>;
  @ViewChild('tasksHoursValueTemplate') public tasksHoursValueTemplate: TemplateRef<any>;
  @ViewChild('tasksTitleValueTemplate') public tasksTitleValueTemplate: TemplateRef<any>;
  @ViewChild('tasksDateValueTemplate') public tasksDateValueTemplate: TemplateRef<any>;
  @ViewChild('tasksEditValueTemplate') public tasksEditValueTemplate: TemplateRef<any>;
  @ViewChild('tasksUserValueTemplate') public tasksUserValueTemplate: TemplateRef<any>;

  get profile(): Profile {
    return this.userService.profileSubj.value;
  }

  private updateStateFromQueries(): void {
    const queries = this.activatedRoute.snapshot.queryParams;

    this.currentMonth = {
      year: queries?.year,
      month: queries?.month,
    };

    this.currentActivity = this.taskService.activities
      .find((activity: TimeEntryActivity) => activity.id === +queries?.activity) || this.activitiesInitData;
    this.activitiesInitData = this.currentActivity;

    this.setMonthes(this.currentMonth);
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(() => {
        this.updateStateFromQueries();
      }),

      this.taskService.$currentTask.subscribe((currentTask: Task) => {
        if (this.tableData.length) {
          this.differenceTableData = findDifferents(this.tableData, currentTask?.spentTimeEntries);
        }

        this.tableData = currentTask?.spentTimeEntries;

        if (currentTask?.task) {
          this.titleService.setTitle(`Timerilo - ${currentTask?.task?.title}`);
        } else {
          this.titleService.setTitle(`Timerilo`);
        }

        this.updateStateFromQueries();
      }),

      this.taskService.activitiesSubject.asObservable().subscribe((activitiesList: Array<TimeEntryActivity>) => {
        const allActivities = { id: 0, activity: 'All' };
        this.activitiesList = [
          allActivities,
          ...activitiesList,
        ];

        if (!this.currentActivity) {
          this.currentActivity = allActivities;
          this.activitiesInitData = this.currentActivity;
        }
      }),

      this.filterService.$monthes.subscribe(() => {
        this.setMonthes(this.currentMonth);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public ngAfterViewInit(): void {
    this.tableSettings = [
      {
        key: 'id', title: '', template: this.tasksEditValueTemplate, sort: false,
        classes: 'table-task-id',
      },
      {
          key: 'user', title: 'User', template: this.tasksUserValueTemplate, sort: true,
          classes: 'table-user',
          sortCallbacks: { asc: this.sortByUserAsc, desc: this.sortByUserDesc }
      },
      {
        key: 'minutes', title: 'Hours', template: this.tasksHoursValueTemplate, sort: true,
        classes: 'table-date',
        sortCallbacks: { asc: this.sortByMinutesAsc, desc: this.sortByMinutesDesc }
      },
      {
        key: 'date', title: 'Date', template: this.tasksDateValueTemplate, sort: true,
        classes: 'table-date',
        sortCallbacks: { asc: this.sortByDateAsc, desc: this.sortByDateDesc }
      },
      {
        key: 'dateAdded', title: 'Date added', template: this.tasksDateValueTemplate, sort: true,
        classes: 'table-date-added',
        sortCallbacks: { asc: this.sortByDateAddedAsc, desc: this.sortByDateAddedDesc }
      },
      {
        key: 'isOvertime', title: 'Overtime', template: this.tasksOvertimeValueTemplate, sort: false,
        classes: 'table-overtime',
      },
      {
        key: 'entryDescription', title: 'Comment', template: this.tasksDescriptionValueTemplate, sort: true,
        classes: 'table-description',
        sortCallbacks: { asc: this.sortByCommentAsc, desc: this.sortByCommentDesc}
      },
      {
        key: 'activity', title: 'Activity', template: this.tasksActivityValueTemplate, sort: true,
        classes: 'table-task-activity',
        sortCallbacks: { asc: this.sortByActivityAsc, desc: this.sortByActivityDesc}
      },
    ];

    this.subscriptions.push(
      this.$tasks.subscribe(() => {
        if (!this.userService.profile?.isStaff) {
          this.skipCols = this.tableSettings.filter((col: TableRowSettings) => {
            return col?.key === 'user';
          });
        }
      })
    );

    /**
     * called to avoid "ExpressionChangedAfterItHasBeenCheckedError"
     * get Templates available only after ViewInit, otherwise it return undefined
     */
    this.cd.detectChanges();
  }

  public async filterTimeEntries(activity?: number): Promise<void> {
    await this.taskService.getCurrentTask(
      this.projectSlug,
      this.taskService.currentTaskSubject?.value?.task?.id,
      this.currentMonth.month,
      this.currentMonth.year,
      activity
      );
  }

  public async openAddTimeDialog(timeEntryId?: number): Promise<void> {
    if ((this.tableData?.find((entry) => entry.id === timeEntryId).isEditable) || this.profile?.isStaff) {
      await this.router.navigate([`./time-entry/${timeEntryId}`], {
        queryParamsHandling: 'merge',
        relativeTo: this.activatedRoute,
      });
    }
  }

  public isEditableTimeEntry(timeEntryId?: number): boolean {
    return timeEntryId && !this.profile?.isStaff ? this.tableData?.find((entry) => entry.id === timeEntryId).isEditable : true;
  }

  public async selectMonthFilter(month: MonthYear): Promise<void> {
    await this.router.navigate([], {
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
      queryParams: {
        year: month?.year,
        month: month?.month,
      },
    });

    await this.filterTimeEntries(this.activatedRoute.snapshot.queryParams?.activity);
  }

  public async selectActivityFilter(activityId: number): Promise<void> {
    this.currentActivity = this.activitiesList.find((activity) => activity?.id === activityId) || this.activitiesInitData;
    await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams: {
        activity: activityId,
      },
    });

    await this.filterTimeEntries(activityId);
  }

  public sortByUserAsc(a, b): number {
    return (a?.user?.firstName + ' ' + a?.user?.lastName)?.localeCompare((b?.user?.firstName + ' ' + b?.user?.lastName));
  }

  public sortByUserDesc(a, b): number {
    return (b?.user?.firstName + ' ' + b?.user?.lastName)?.localeCompare((a?.user?.firstName + ' ' + a?.user?.lastName));
  }

  public sortByMinutesAsc(a, b): number {
    return a?.minutes - b?.minutes;
  }

  public sortByMinutesDesc(a, b): number {
    return b?.minutes - a?.minutes;
  }

  public sortByDateAsc(a, b): number {
    return getDate(a?.date).getTime() - getDate(b?.date).getTime();
  }

  public sortByDateDesc(a, b): number {
    return getDate(b?.date).getTime() - getDate(a?.date).getTime();
  }

  public sortByDateAddedAsc(a, b): number {
    return getDate(a?.dateAdded).getTime() - getDate(b?.dateAdded).getTime();
  }

  public sortByDateAddedDesc(a, b): number {
    return getDate(b?.dateAdded).getTime() - getDate(a?.dateAdded).getTime();
  }

  public sortByCommentAsc(a, b): number {
    console.log(a);

    return a?.entryDescription?.localeCompare(b?.entryDescription);
  }

  public sortByCommentDesc(a, b): number {
    console.log(a);

    return b?.entryDescription?.localeCompare(a?.entryDescription);
  }

  public sortByActivityAsc(a, b): number {
    return a?.activity?.activity?.localeCompare(b?.activity?.activity);
  }

  public sortByActivityDesc(a, b): number {
    return b?.activity?.activity?.localeCompare(a?.activity?.activity);
  }

}
