import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TaskDifficulty } from 'src/app/shared/models/task-difficulty.model';
import { TaskService } from '../services/task/task.service';

@Injectable({
  providedIn: 'root'
})
export class TaskDifficultiesResolver implements Resolve<TaskDifficulty> {

  constructor(
    private taskService: TaskService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TaskDifficulty | any> {
    return this.taskService.getTaskDifficultiesAsync();
  }
}

