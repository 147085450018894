import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

// Models
import { MonthYear } from '../models/month-year.model';

// Guards
import { MonthYearGuard } from '../../shared/super/month-year-guard';

// Services
import { UserTaskService } from '../services/user-task/user-task.service';
import { UserService } from '../../shared/services/user/user.service';


@Injectable({
  providedIn: 'root'
})
export class TaskYearMonthGuard extends MonthYearGuard implements CanActivate {

  constructor(
    private userTaskService: UserTaskService,
    userService: UserService,
    router: Router) {
    super(router, userService);

    this.subscriptions.push(
      this.userTaskService.$monthes.subscribe((monthes: Array<MonthYear>) => {
        this.monthYearList = monthes;
      }),
    );
  }

  protected async loadMonthes(slug: string | number, userMonthes: boolean): Promise<void> {
    await this.userTaskService.getUserMonthYearList(+slug);
  }

  protected loadConditions(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this.slugId = this.cleanUrl(route.params?.userId);
    this.additionalMonthesCondition = true;
    this.mainCondition = route.params?.userId;
    this.conditionsDifferent = route?.params?.userId !== this.userTaskService.currentUser;
    this.monthYearList = this.userTaskService.monthes;
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    this.loadConditions(route, state);
    return await this.canActivateCustom(route, state);
  }
}
