import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

// Components
import { FormComponent } from '../../../shared/super/form-component';

// Services
import { AlertService } from '../../../shared/services/alert.service';
import { AuthService } from '../../services/auth.service';

// Validators
import { MustMatch } from '../../../shared/utils/must-match.validator';
import { validateAllFields } from '../../../shared/pipes/validate-all-fields.pipe';


@Component({
  selector: 'ffcrm-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss']
})
export class RecoveryPasswordComponent extends FormComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService) {
    super();

    this.form.setValidators([MustMatch('password', 'confirmPassword')]);
  }

  public mode: string;
  public form = this.fb.group({
    email: ['', [Validators.required]],
    password: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]],
  });

  async ngOnInit(): Promise<void> {
    if (!this.route.snapshot.queryParams?.token) {
      await this.router.navigate(['/404'], { skipLocationChange: true });
    }

    this.form.get('email').patchValue(this.authService.validEmail);

    this.mode = this.route.snapshot.data?.mode === 'CREATE_PASSWORD' ? 'Create' : 'Forgot';
  }

  async onSubmit(): Promise<void> {
    if (this.form.valid) {
      let message = '';

      try {
        switch (this.route.snapshot.data?.mode) {
          case 'CREATE_PASSWORD': {
            const response = await this.authService.createPassword(this.getRequestData()).toPromise();
            const email = response.email;
            this.form.addControl('email', this.fb.control(email, [Validators.required]));
            await this.authService.loginUser(this.form.value).toPromise();
            message = 'Password has been created';
            break;
          }
          case 'RECOVERY_PASSWORD': {
            await this.authService.resetPassword(this.getRequestData()).toPromise();
            message = 'Password has been updated';
            break;
          }
          default: return;
        }

        await this.alertService.showAlert({
          success: true,
          message,
        });

        await this.router.navigate([''], {
          queryParamsHandling: '',
        });
      } catch (e) {
        await this.getErrorsFromResponse(e.error);

        if (e.error?.token) {
          this.form.setErrors({ error: e.error.token[0] });
        }
      }
    } else {
      validateAllFields(this.form);
    }
  }

  public getRequestData(): object {
    return {
      password: this.form.get('password').value,
      token: this.route.snapshot.queryParams?.token,
    };
  }

}
