<div class="slider">
  <div class="line"></div>
  <div class="break dot1"></div>
  <div class="break dot2"></div>
  <div class="break dot3"></div>
  <div class="break dot4"></div>
</div>
<nav [class.squoshed]="($userSidebar | async) === false">
  <div class="left">
    <div class="logo-wrapper">
      <ffcrm-logo></ffcrm-logo>
    </div>
  </div>

  <div class="header-links">
    <button class="menu-burger" (click)="toggleSidebar()"></button>
    <ul>
      <li [routerLink]="['/home']" [style.pointerEvents]="isLinkActive('/home') ? 'none': 'auto'" [class.active]="isLinkActive('home')" queryParamsHandling="merge" [queryParams]="currentDate">Home</li>
      <li [routerLink]="['/all-users']" [style.pointerEvents]="isLinkActive('/all-users') ? 'none': 'auto'" [class.active]="isLinkActive('all-users')" [queryParams]="currentDate" queryParamsHandling="merge" *ngIf="($profile | async)?.isStaff">All users</li>
      <li [routerLink]="['/overtime']" [style.pointerEvents]="isLinkActive('/overtime') ? 'none': 'auto'" [class.active]="isLinkActive('overtime')" [queryParams]="currentDate">Overtimes</li>
    </ul>
  </div>

  <div class="right">
    <div #dropdown style="display: flex">
      <div class="user" (click)="toggleDropdown()">
        <!--        <img src="{{user.photo || noPhotoUserImage}}" alt="user-pic">-->
        <div class="img-wrapper">
          <ffcrm-image [object]="$profile | async" srcKey="photo" [labelKeys]="['firstName', 'lastName']" border="50%"></ffcrm-image>
        </div>
        <span class="name">{{($profile | async)?.firstName}} {{($profile | async)?.lastName}}</span>
        <i [@rotateArrow]="dropDownCollapsed" class="fas fa-caret-down"></i>
      </div>

      <div class="drop-down"
           [@openClose]="dropDownCollapsed"
           (@openClose.start)="$event.element.style.display = 'block'"
           (@openClose.done)="$event.element.style.display = ($event.toState ? 'block' : 'none')">
        <ul>
          <li><i class="icon icon-email icon-18px"></i><span class="email"> {{ ($profile | async)?.email }}</span></li>
          <li><i class="icon icon-phone icon-18px"></i> {{($profile | async)?.phoneNumber}}</li>
          <li class="divider"></li>
          <li class="button" (click)="editProfile()"><i class="icon icon-edit icon-18px"></i>Edit</li>
          <li class="button" (click)="changePassword()"><i class="icon icon-key icon-18px"></i>Change password</li>
          <li class="button" (click)="logout()"><i class="icon icon-exit icon-18px"></i>Logout</li>
        </ul>
      </div>
    </div>


    <div #notifications_menu class="notifications-menu" [class.unread]="haveUnreadNotifications" (click)="toggleNotifications()"></div>
    <div #activities_menu class="activities-menu" [class.opened]="activitiesOpen" (click)="toggleActivities()">
      <i class="fas fa-times" [style.display]="activitiesOpen ? 'block' : 'none'"></i>
    </div>

    <div #activities class="activities" *ngIf="activitiesOpen" [@slideInOut]>
      <div class="heading">
        <h1>Activity</h1>
        <ffcrm-select *ngIf="!isStaff" class="activities-select" [options]="selectOptions" [value]="activityPeriod" (selected)="filter($event)"></ffcrm-select>
        <ffcrm-autocomplete *ngIf="isStaff"
                            (selected)="selectOption($event)"
                            (valueChange)="handleUsers($event)"
                            [value]="usernameFromQueries"
                            class="user-filter"
                            [clearBtn]="true"
                            [canWrite]="true"
                            [options]="usersList | fullname"
                            labelKey="fullName"
                            placeholder="Enter name"></ffcrm-autocomplete>
      </div>

      <div class="activities-block" (scroll)="activitiesScroll($event)" #activities_block>
        <div class="no-activity" *ngIf="activitiesList?.length === 0">
          <div>
            <img src="../../../../assets/icons/mailbox.svg" alt="mailbox" />
            <p>
              You have no activity
            </p>
          </div>
        </div>
        <div class="activity"
            [class.has-date]="dateParser(activitiesList[i]?.createdAt) !== dateParser(activitiesList[i - 1]?.createdAt)"
            *ngFor="let activity of activitiesList; let i = index;"
            (mouseenter)="setId(activity.task)"
            (mouseleave)="setId('')">
          <h2 class="date" *ngIf="dateParser(activitiesList[i]?.createdAt) !== dateParser(activitiesList[i - 1]?.createdAt)">{{ dateParser(activitiesList[i]?.createdAt) }}</h2>
          <div class="top-data">
            <div class="left-data">
              <div class="user-pic">
                <ffcrm-image [object]="activity?.user" srcKey="photo" [labelKeys]="[activity?.user.fullName]" [border]="'50%'"></ffcrm-image>
              </div>
              <div class="text-data">
                <p class="user-name">{{ activity?.user.fullName }}</p>
                <p class="timestamp text-gray">{{ dateParserWithTime(activity.createdAt) }}</p>
                <p class="text-gray">{{ activity?.dateAdded | date }}</p>
              </div>
            </div>
            <div class="right-data">
              <div class="text-data">
                <p class="text-gray">{{ activity?.project?.name }}</p>
                <p class="text-gray">{{ activity?.minutes | hhmm  }}
                  <img class="overtime-moon" *ngIf="activity.isOvertime" src="../../../../assets/icons/half-moon.svg" alt="Overtime" />
                </p>
              </div>
            </div>
          </div>
          <div class="divider"></div>
          <div class="bottom-data">
            <div class="text-data">
              <p class="task-title">{{ activity?.title }}</p>
            </div>
          </div>
        </div>
      </div>
      <ng-template #noActivities>
        <p class="activities-empty">Oh, looks so empty! Start working now! 🚀</p>
      </ng-template>
    </div>

    <div [@slideInOut] class="activities" #notifications *ngIf="notificationsOpen">
      <div class="heading">
        <h1>Notifications</h1>
        <div class="icon icon-close" (click)="toggleNotifications()"></div>
      </div>

        <div class="activities-block" *ngIf="notificationsList?.length else noNotifications" (scroll)="notificationsScroll($event)">
          <div class="activity"
            [class.has-date]="dateParser(notificationsList[i]?.created) !== dateParser(notificationsList[i - 1]?.created)"
            *ngFor="let activity of notificationsList; let i = index;"
            >
            <h2 class="date"
              *ngIf="dateParser(notificationsList[i]?.created) !== dateParser(notificationsList[i - 1]?.created)"
            >{{ dateParser(notificationsList[i]?.created) }}</h2>
            <div class="top-data">
              <div class="left-data">
                <div class="user-pic">
                  <ffcrm-image [object]="activity?.initiator" srcKey="photo" [labelKeys]="['fullName']" [border]="'50%'"></ffcrm-image>
                </div>
                <div class="text-data">
                  <p class="user-name">{{ activity?.initiator.fullName}}</p>
                  <p class="text-gray">{{ activity?.created | date }}</p>
                </div>
              </div>
              <div class="right-data">
                <div class="text-data">
                  <p class="text-gray">{{ activity?.target?.name || activity?.target.fullName }}</p>
                </div>
              </div>
            </div>
            <div class="divider"></div>
            <div class="bottom-data">
              <div class="text-data">
                <p class="task-title">{{ activity.type }}</p>
              </div>
            </div>
          </div>
        </div>

      <ng-template #noNotifications>
        <div class="no-activity">
          <div>
            <img src="../../../../assets/icons/mailbox.svg" alt="mailbox" />
            <p>
              You have no notisfaction
            </p>
          </div>
        </div>
      </ng-template>

    </div>
  </div>

  <ffcrm-change-password [(changePasswordDialog)]="changePasswordDialog"></ffcrm-change-password>
  <ffcrm-profile-modal [(editProfileDialog)]="editProfileDialog" [user]="$profile | async"
                      (updatedUser)="getUpdatedUser($event)"></ffcrm-profile-modal>

</nav>
