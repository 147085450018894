import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

// Models
import { UserProfile } from '../../shared/models/user-profile.model';

// Services
import { UserService } from '../../shared/services/user/user.service';


@Injectable({
  providedIn: 'root'
})
export class UsersResolver implements Resolve<Array<UserProfile>> {

  constructor(
    private userService: UserService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<UserProfile>> {
    let params;

    if (route.queryParams['user-filter']) {
      switch (route.queryParams['user-filter']) {
        case 'all': break;
        case 'active': params = { isActive: true }; break;
        case 'blocked': params = { isActive: false }; break;
      }
    }

    if (route.queryParams?.project) {
      params = { ...params, project: route.queryParams?.project };
    }

    if (route.routeConfig.path === 'overtime') {
      params = { ...params, isSuperUser: false, ...(this.userService.profile.isStaff && { project: 'all' }) };
    }

    return this.userService.profile.isStaff ? this.userService.getAllUsersAsync(route.data?.usersFilter || params) : null;
  }
}
