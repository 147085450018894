import { MonthYear } from '../../main/models/month-year.model';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

// Components
import { SubscriptionsComponent } from './subscriptions-component';

// Services
import { UserService } from '../services/user/user.service';


export class MonthYearGuard extends SubscriptionsComponent {
  constructor(private router: Router,
              private userService: UserService) {
    super();
  }

  protected slugId: string;
  protected additionalMonthesCondition: boolean;
  protected mainCondition: boolean;
  protected conditionsDifferent: boolean;
  protected monthYearList: Array<MonthYear>;

  /**
   * Clean string from query params
   * @param url input string, which need to be cleaned
   */
  protected cleanUrl(url: string): string {
    if (url.includes('?')) {
      return url?.slice(0, +url.indexOf('?'));
    }

    return url;
  }

  protected loadConditions(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    throw new Error('Not implemented');
  }

  protected async loadMonthes(slug: string, additionalParam: boolean): Promise<void> {
    throw new Error('Not implemented');
  }

  protected async canActivateCustom(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (this.additionalMonthesCondition && !this.userService.profile.isStaff) { // specific to 'current project' page
      if (!route.queryParams['task-filter']) {
        await this.router.navigate([this.cleanUrl(state.url)], {
          queryParamsHandling: 'merge',
          queryParams: {
            'task-filter': 'my',
          },
        });
      }

      return false;
    }

    if (this.mainCondition) {
      if (this.conditionsDifferent) { // if requested things is different
        await this.loadMonthes(this.slugId, (route.queryParams['task-filter']
          && route.queryParams['task-filter'] === 'my')
          && !this.userService.profile.isStaff);
      }

      if (this.monthYearList.length) {
        if (route.queryParams?.month && route.queryParams?.year) {
          if (!this.monthYearList.filter((item: MonthYear) => item.month === +route.queryParams?.month &&
            item.year === +route.queryParams?.year).length) { // if can't find date - set first date from array
            await this.router.navigate([this.cleanUrl(state.url)], {
              queryParams: {
                ...route.queryParams,
                year: this.monthYearList[0]?.year,
                month: this.monthYearList[0]?.month,
              },
            });

            return false;
          }
        }

        if (!route.queryParams?.month || !route.queryParams?.year) { // if no queries - set first in list
          await this.router.navigate([this.cleanUrl(state.url)], {
            queryParams: {
              ...route.queryParams,
              year: this.monthYearList[0]?.year,
              month: this.monthYearList[0]?.month,
            },
          });

          return false;
        }
      } else {
        const date = new Date(Date.now());

        if (+route.queryParams?.month === date.getMonth() + 1 && +route.queryParams?.year === date.getFullYear()) { // date is current - ok
          return true;
        } else {
          await this.router.navigate([this.cleanUrl(state.url)], { // else set current and redirect
            queryParams: {
              ...route.queryParams,
              year: date.getFullYear(),
              month: date.getMonth() + 1,
            },
          });

          return false;
        }
      }
    } else {
      await this.loadMonthes(this.slugId, !(this.additionalMonthesCondition && this.userService.profile.isStaff));
    }

    return true;
  }

}
