import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { ComponentCanDeactivate } from '../../main/interfaces/component-can-deactivate';
import { SubscriptionsComponent } from './subscriptions-component';

export class FormComponent extends SubscriptionsComponent implements ComponentCanDeactivate {
  public form: FormGroup;

  public getErrors(field: AbstractControl): Array<string> {
    const result: Array<string> = [];

    for (const error in field.errors) {
      if (field.errors.hasOwnProperty(error)) {
        switch (error) {
          case 'required':
            result.push('This field is required!');
            break;
          case 'email':
            result.push('Email in invalid!');
            break;
          default:
            result.push(field.errors[error]);
        }
      }
    }

    return result;
  }

  public async getErrorsFromResponse(errors: object): Promise<void> {
    for (const field in errors) {
      if (errors.hasOwnProperty(field)) {
        if (errors[field] instanceof Array) {
          this.form.get(field)?.setErrors({ incorrect: errors[field][0] });
        } else {
          this.form.get(field)?.setErrors({ incorrect: errors[field] });
        }
      }
    }
  }

  public canDeactivate(): boolean | Observable<boolean> {
    if (this.form.touched && this.form.dirty) {
      return confirm('Seems you have some half-edited form left. Proceed anyway?');
    } else {
      return true;
    }
  }
}
