<div class="select" #dropDown>
  <button [class.hidden-input]="canWrite || (!canWrite && searchMode)" class="input-field" (mouseup)="goToSearchMode()">{{ label }}</button>
  <input [class.hidden-input]="canWrite || (!canWrite && !searchMode)" (focusout)="helpBlur()" #helpField type="text"
         class="input-field" (input)="input()" value="{{ label }}" [placeholder]="placeholder" (focus)="focus()">
  <input [class.hidden-input]="!canWrite" #inputField type="text" class="input-field" [(ngModel)]="value" [placeholder]="placeholder" (focus)="focus()">
  <i *ngIf="!clearBtn" [@rotateArrow]="dropdown" class="fas fa-caret-down"></i>
  <button *ngIf="clearBtn" (click)="value = ''" class="clear">
    <span></span>
    <span></span>
  </button>
  <div class="dropdown"
        *ngIf="this.options?.length && dropdownShow"
        [@openClose]="dropdown"
        (@openClose.start)="$event.element.style.display = 'block'"
        (@openClose.done)="animationCallback($event)">
    <!-- <span class="triangle">▲</span> -->
    <div class="items-list">
      <div class="items">
        <div class="item" (click)="select(item)" *ngFor="let item of this.options; let i = index">
          <p class="text border-bottom">{{ item[labelKey] }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
