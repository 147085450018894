<ffcrm-dialog [visible]="true" (visibleChange)="dialogVisibleChange()" [title]="group ? 'Edit group' : 'New group'" [closable]="true">
  <div class="dialog-body">
    <form  [formGroup]="form">
      <div class="fields">
        <div class="left">
          <div class="input-block">
            <label for="group_name">Title</label>
            <input formControlName="name" type="text" id="group_name" class="input-field" placeholder="Enter group name" (input)="createSlug()"
                   [class.error]="form.get('name').invalid && form.get('name').touched">

            <div class="error-msg" [class.visible]="form.get('name')?.invalid && (form.get('name')?.touched || form.get('name')?.dirty)">
              <div *ngFor="let error of getErrors(form.get('name'))">{{ error }}</div>
            </div>
          </div>

          <div class="input-block">
            <div class="tooltip-block">
              <label for="group_slug">Slug</label>
              <div class="tooltip-icon tooltip-trigger">?</div>
              <div class="tooltip-message">
                A "slug" is a way of generating a valid URL, generally using data already obtained. For instance, a slug uses the title of an article to generate a URL
              </div>
            </div>
            <input formControlName="slug" type="text" id="group_slug" class="input-field"
                   [class.error]="form.get('slug').invalid && form.get('slug').touched" />

            <div class="error-msg" [class.visible]="form.get('slug')?.invalid && (form.get('slug')?.touched || form.get('slug')?.dirty)">
              <div *ngFor="let error of getErrors(form.get('slug'))">{{ error }}</div>
            </div>

          </div>

          <div class="input-block">
            <div class="add-photo">
              <input #photoInput type="file" id="group_image" accept="image/gif, image/jpeg, image/png" (change)="imageSelect($event)">
              <label for="group_image">
                <div class="image">
                  <img src="{{ image ? image : '../assets/images/no-image.svg' }}" [style.padding]="image ? '0': ''" alt="">
                </div>
                <span>{{ image ? 'Edit photo' : 'Add photo' }}</span>
              </label>
            </div>

            <div class="checkboxes">
              <div class="checkbox-block">
                <input type="checkbox" id="is_active" formControlName="isActive">
                <label for="is_active">Is active</label>
              </div>
              <div class="checkbox-block">
                <input type="checkbox" id="is_readonly" formControlName="isReadOnly">
                <label for="is_readonly">Is readonly</label>
              </div>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="head">
            <h4>Members</h4>
            <span class="users-amount">{{selectedUsers.length || 0}} users</span>
          </div>
          <div class="search">
            <div class="input-block">
              <i class="icon icon-search search-icon"></i>
              <input #search class="input-field input-search" type="text" id="search" placeholder="Search" (input)="filterUsers()">
            </div>
          </div>
          <div class="users-list" *ngIf="($users | async)">
            <ul>
              <li *ngFor="let user of filteredUsers">
                <div class="checkbox-block">
                  <input #users type="checkbox" id="user_{{user.id}}" [checked]="user.checked" value="{{user.id}}" (change)="membersCheckboxChange($event)">
                  <label for="user_{{user.id}}">{{user.firstName}} {{user.lastName}}</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="actions">
        <button *ngIf="group" [disabled]="form.invalid" class="btn btn-block" (click)="updateGroup()">Save changes</button>
        <button *ngIf="!group" [disabled]="form.invalid" class="btn btn-light btn-block" (click)="createAndNew()">Create & New</button>
        <button *ngIf="!group" [disabled]="form.invalid" class="btn btn-block" (click)="createAndClose()">Create & Close</button>
      </div>
    </form>

  </div>

</ffcrm-dialog>

<ffcrm-image-cropper [(cropperDialog)]="imageCropper" [image]="croppedImage" (croppedImage)="getCroppedImage($event)">
</ffcrm-image-cropper>
