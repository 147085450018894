import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

// Components
import { FormComponent } from '../../../shared/super/form-component';

// Pipes
import { validateAllFields } from '../../../shared/pipes/validate-all-fields.pipe';

// Services
import { AlertService } from '../../../shared/services/alert.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'ffcrm-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends FormComponent implements OnInit {

  expiredError: string;

  constructor(private http: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private alertService: AlertService) {
    super();
    route?.queryParams?.subscribe((queryParam: any) => {
      this.expiredError = queryParam.error;
    });
  }

  public form = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
  });

  public ngOnInit(): void {
    const email = this.route.snapshot.queryParams?.email;

    if (email) {
      this.form.get('email').patchValue(email);
    }
  }

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      try {
        await this.http.forgotPassword(this.form.value).toPromise();
        await this.alertService.showAlert({
          success: true,
          message: 'Mail has been send',
        });

        await this.router.navigate([`../login/`], {
          relativeTo: this.route,
        });
      } catch (e) {
        await this.getErrorsFromResponse(e.error);
      }
    } else {
      validateAllFields(this.form);
    }
  }
}
