import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {cellsAnimation} from '../../animations/timepicker';

@Component({
  selector: 'ffcrm-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  animations: cellsAnimation,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true,
    },
  ],
})
export class TimepickerComponent implements ControlValueAccessor {

  constructor() {
  }

  @Input() set value(value: number) {
    this.inputValue = value || 0;

    this.valueChange.emit(this.inputValue || 0);
    this.onChange(this.inputValue || 0);
    this.onTouched();
  }

  get value(): number {
    return this.inputValue;
  }

  @Output() public valueChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() public timeSelected = new EventEmitter();

  private inputValue = 0;

  public minutes = [
    0, 1, 2, 3, 5,
    10, 11, 12, 13, 15,
    20, 21, 22, 23, 25,
    30, 31, 32, 33, 35,
    40, 41, 45, 50, 55,
  ];
  public selectedHours = 0;
  public selectedMinutes = 0;

  public state = true; // true -> hours, false -> minutes

  public toggleState(): void {
    this.state = !this.state;
  }

  public selectTime(time: number): void {
    this.selectedMinutes = time;
    this.value = (+this.selectedHours * 60) + +this.selectedMinutes;
    this.state = !this.state;
  }

  public selectHours(hours: number): void {
    this.selectedHours = hours;
    this.value = (+this.selectedHours * 60) + +this.selectedMinutes;
    this.state = !this.state;
  }

  private onChange = (value: number): void => {};

  private onTouched = (): void => {};

  public registerOnChange(fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  public writeValue(value: number): void {
    this.inputValue = value;

    if (value) {
      this.selectedMinutes = Math.floor(value) % 60;
      this.selectedHours = (Math.floor(value) - +this.selectedMinutes) / 60;
    }
  }
}
