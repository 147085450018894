<div class="current-item">
  <div class="current-item-main-content"
    [class.user]="!($profile | async)?.isStaff">
    <div class="head with-margin-pillow">
      <a class="button-back" routerLink="../../" queryParamsHandling="preserve"></a>
      <div class="head-content">
        <div class="summary">
          <h1>
            {{($currentTask | async)?.task?.title}}
            <span class="read-only-summary" *ngIf="($currentTask | async)?.task?.projectReadonly">{{ ' [Project is read only]' }}</span>
            <span class="read-only-summary" *ngIf="!($currentTask | async)?.task?.projectIsActive">{{ ' [Project is not active]' }}</span>
          </h1>
          <div class="statistic">
            <div class="stat-item">
              <p class="text">Current month time</p>
              <p class="number">{{($currentTask | async)?.currentTime || 0 | hhmm }}</p>
            </div>
            <div class="stat-item">
              <p class="text">Current month overtime</p>
              <p class="number">{{($currentTask | async)?.overtimes || 0 | hhmm }}</p>
            </div>
            <div class="stat-item">
              <p class="text">Total time</p>
              <p class="number">{{($currentTask | async)?.minutes || 0 | hhmm }}</p>
            </div>
            <div class="stat-item">
              <p class="text">Total overtime</p>
              <p class="number">{{($currentTask | async)?.currentOvertime || 0 | hhmm }}</p>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="block">
            <a class="edit-button"
               *ngIf="(!($currentTask | async)?.projectReadonly && ($currentTask | async)?.projectIsActive) || ($profile | async)?.isStaff"
               routerLink="./edit"
               queryParamsHandling="preserve"
            >
              <i class="icon icon-edit icon-18px"></i>
            </a>
            <div class="search">
              <div class="input-block"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="filter with-margin-pillow">
      <h2>Time entries</h2>
      <div class="filters-wrapper">
        <ffcrm-select *ngIf="activitiesInitData" class="select" labelKey="activity" [options]="activitiesList"
                      (selected)="selectActivityFilter($event)"
                      [value]="activitiesInitData"></ffcrm-select>
        <ffcrm-select *ngIf="initOption" class="select" [options]='selectOptions' (selected)="selectMonthFilter($event)"
                      [value]="initOption"></ffcrm-select>
      </div>
    </div>

    <ffcrm-table selectableRows="true" [data]="tableData | highlightNewRow:differenceTableData" [skipCols]="skipCols" [cellTemplates]="tableSettings" (rowClicked)="openAddTimeDialog($event?.id)"></ffcrm-table>
    <a class="btn btn-block btn-add with-margin-pillow" routerLink="./time-entry/add" queryParamsHandling="preserve"
       *ngIf="($profile | async)?.isStaff === false &&
              ($currentTask | async)?.task?.projectReadonly === false &&
              ($currentTask | async)?.task?.projectIsActive === true &&
              ($currentTask | async)?.monthIsClosed === false">
      Add time
    </a>
  </div>
</div>

<router-outlet></router-outlet>

<ng-template #tasksTitleValueTemplate let-item>
  <div class="table-task-title">
    {{ item?.title }}
  </div>
</ng-template>

<ng-template #tasksUserValueTemplate let-item>
  <div class="table-task-title">
    {{ item?.firstName + ' ' + item?.lastName }}
  </div>
</ng-template>

<ng-template #tasksSimpleValueTemplate let-item>
  <div>
    {{ item }}
  </div>
</ng-template>

<ng-template #tasksOvertimeValueTemplate let-item>
  <div *ngIf="item; else overtimeElse" class="cell-with-check"></div>
  <ng-template #overtimeElse>
    {{ '-' }}
  </ng-template>
</ng-template>

<ng-template #tasksHoursValueTemplate let-item>
  <div>
    {{ item | hhmm }}
  </div>
</ng-template>

<ng-template #tasksEditValueTemplate let-item>
  <div class="add-edit-task-buttons-wrapper" [class.is-hiding]="!isEditableTimeEntry(item)" [class.with-add]="!($profile | async)?.isStaff" (click)="$event.stopPropagation()">
    <a class="table-button task-edit-button" [routerLink]="'./time-entry/' + item" queryParamsHandling="preserve" [tabIndex]="($profile | async)?.isStaff && 1"></a>
  </div>
</ng-template>

<ng-template #tasksDateValueTemplate let-item>
  {{ item | date }}
</ng-template>

<ng-template #tasksActivityValueTemplate let-item>
  <div>
    {{ item?.activity }}
  </div>
</ng-template>

<ng-template #tasksDescriptionValueTemplate let-item>
  <div>
    {{ item }}
  </div>
</ng-template>
