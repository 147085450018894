import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'ffcrm-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(100),
      ]),
      transition('* => void', [
        animate(100, style({ opacity: 0 }))
      ])
    ]),
  ],
})
export class DialogComponent {

  private modalTitle: string;
  public isVisible: boolean;
  public selectValue: string;

  @Input() set visible(visible: boolean) {
    this.isVisible = visible;

    if (visible === true && this.type === 'info' && this.timeoutClose) {
      setTimeout(() => {
        this.close();
      }, 1700);
    }
  }

  @Input() timeoutClose = true;
  @Input() closable = true;
  @Input() backButton = false;
  @Input() backButtonEnabled = false;
  @Input() isCreatePhoto = false;
  @Input() userTitle: string;
  @Input() set title(value) {
    if (this.titleKey && value[this.titleKey]) {
      this.modalTitle = value[this.titleKey];
    } else {
      this.modalTitle = value;
    }
  }

  get title(): string {
    return this.modalTitle;
  }

  @Input() type;
  @Input() dropdownChoices;
  @Input() dropdownIdKey = 'id';
  @Input() dropdownLabelKey = 'name';
  @Input() titleKey: string;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() choice: EventEmitter<string> = new EventEmitter<string>();
  @Output() backButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  public itemSelected(event): void {
    this.choice.emit(this.dropdownChoices?.find(currentOption => currentOption[this.dropdownIdKey] === event)?.slug);
  }

  public close(param?): void {
    if (this.closable || param !== 'overlay') {
      setTimeout(() => {
        this.visibleChange.emit(this.visible);
      }, 100);
    }
  }

  public backButtonClick(): void {
    this.backButtonClicked.emit();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    if (this.isVisible) {
      this.close();
    }
  }

}
