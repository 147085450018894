import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbDatepicker, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getDate } from '../../utils/getDate';

@Component({
  selector: 'ffcrm-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent implements ControlValueAccessor {

  @Input() set value(value: string | NgbDate) {
    if (typeof value === 'string') {
      this.inputValue = value || '';

      this.valueChange.emit(this.inputValue || '');
      this.onChange(this.inputValue || '');
      this.onTouched();
    } else if (value instanceof NgbDate) {
      this.value = `${value?.year}-${value.month}-${value.day}`;
    }
  }

  get value(): string | NgbDate {
    return this.inputValue;
  }

  constructor(public i18n: NgbDatepickerI18n) { }

  @ViewChild(NgbDatepicker, { static: true }) datepicker: NgbDatepicker;

  @Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() daySelected = new EventEmitter();

  private inputValue = '';

  public swipeMonth(val: number): void {
    let year = this.datepicker.state.months[0].year;
    let month = this.datepicker.state.months[0].month;

    if (val > 0 && month === 12) {
      year++;
      month = 0;
    }

    if (val < 0 && month === 1) {
      year--;
      month = 13;
    }

    this.datepicker.navigateTo({ year, month: month + val });
  }

  private onChange = (value: string): void => {};

  private onTouched = (): void => {};

  public registerOnChange(fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  public writeValue(value: string): void {
    this.inputValue = value;

    const date = getDate(value);
    this.datepicker.navigateTo({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    });
    this.datepicker.focusSelect();
  }
}
