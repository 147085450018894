import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  HostListener,
  forwardRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { selectAnimations } from '../../animations/select';

@Component({
  selector: 'ffcrm-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  animations: selectAnimations,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent implements ControlValueAccessor, OnChanges {

  @Input() private idKey = 'id';
  @Input() private model;
  @Input() public labelKey = 'label';
  @Input() public canWrite = false;
  @Input() public clearBtn = false;
  @Input() public placeholder = '';
  @Input() public dropdownShow = true;
  @Output() public selected = new EventEmitter();
  @Output() public focusEvent = new EventEmitter();
  @Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('dropDown') dropDown;
  @ViewChild('inputField') inputField;
  @ViewChild('helpField') helpField;
  // tslint:disable-next-line:variable-name
  private _options = [];
  private originalOptions = [];
  private inputValue: string;
  public dropdown = false;
  public searchMode = false;

  get value(): string {
    return this.inputValue;
  }

  @Input() set value(value: string) {
    this.inputValue = value || '';

    this.valueChange.emit(this.inputValue || '');
    this.onChange(this.inputValue || '');
    this.onTouched();
  }

  get options(): object[] {
    return this._options;
  }

  @Input()
  set options(value) {
    this.originalOptions = value;
    this._options = this.originalOptions?.reverse();

    if (this.model !== undefined) {
      this.model = this.options?.find(currentOption => currentOption[this.idKey] === this.model);
    } else {
      this.model = this.options?.find(currentOption => currentOption[this.idKey] === this.inputValue);
    }
  }

  get label(): string {
    return this.model ? this.model[this.labelKey] : '';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.model = this.options?.find(currentOption => currentOption[this.labelKey] === this.value) || this.model;
  }

  public animationCallback(event): void {
    event.element.style.display = event.toState === true ? 'block' : 'none';
  }

  public focus(): void {
    let keyword = '';
    if (!this.canWrite) {
      keyword = this.helpField.nativeElement.value?.toLowerCase();
    } else {
      keyword = this.value?.toLowerCase();
    }

    this.dropdown = true;
    this.focusEvent.emit();
  }

  public input(): void {
    const keyword = this.helpField.nativeElement.value.toLowerCase();
    if (keyword) {
      this._options = this.originalOptions?.filter(option => option[this.labelKey]?.toLowerCase().includes(keyword));
    } else {
      this._options = this.originalOptions;
    }
    this.dropdown = !!this.options.length;
  }

  public select(item): void {
    this.model = this.options.find(currentOption => currentOption[this.idKey] === item[this.idKey]);
    if (this.canWrite) {
      this.value = item[this.labelKey];
    } else {
      this.value = item[this.idKey];
    }
    this.dropdown = false;
    this.selected.emit(item[this.idKey]);
  }

  public helpBlur(): void {
    this.searchMode = false;
  }

  public goToSearchMode(): void {
    this.searchMode = true;
    setTimeout(() => this.helpField.nativeElement.focus(), 0);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement): void {
    const clickedInsideDropDown = this.dropDown?.nativeElement?.contains(targetElement);
    if (!clickedInsideDropDown) {
      this.dropdown = false;
    }
  }

  public registerOnChange(fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  public writeValue(value: string): void {
    this.inputValue = value;
    this.model = this.options?.find(currentOption => currentOption[this.idKey] === value);
    this.selected.emit(this.model);
  }

  private onChange = (value: string): void => { };

  private onTouched = (): void => { };
}
