import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { cases, keysToCase } from '../utils/case-to-case';

@Injectable()
export class CaseInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({ body: keysToCase(request?.body, cases.snake) });
    return next.handle(request).pipe(map(event => {
      if (event instanceof HttpResponse) {
        event = event.clone({ body: keysToCase(event?.body, cases.camel) });
      }
      return event;
    }));
  }
}
