import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { RecoveryPasswordComponent } from './pages/recovery-password/recovery-password.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';

// Guards
import { RestoreTokenGuard } from './guards/restore-token.guard';
import { LoginGuard } from './guards/login.guard';


const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'recovery-password',
    pathMatch: 'full',
    component: RecoveryPasswordComponent,
    canActivate: [LoginGuard, RestoreTokenGuard],
    data: {
      mode: 'RECOVERY_PASSWORD',
    },
  },
  {
    path: 'forgot-password',
    pathMatch: 'full',
    component: ForgotPasswordComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'create-password',
    pathMatch: 'full',
    component: RecoveryPasswordComponent,
    canActivate: [LoginGuard, RestoreTokenGuard],
    data: {
      mode: 'CREATE_PASSWORD',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
