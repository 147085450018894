import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ProjectService } from '../services/project/project.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ManageProject } from '../../shared/models/manage-project.model';

@Injectable({
  providedIn: 'root'
})
export class ManageProjectResolver implements Resolve<ManageProject> {

  constructor(
    private projectService: ProjectService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ManageProject | any> {
    return this.projectService.getManageProjectAsync(route.params?.projectSlug).pipe(catchError((err) => this.router.navigateByUrl('/')));
  }
}
