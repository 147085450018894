import {Component, Input, OnInit, Output, EventEmitter, ViewChild, HostListener, OnChanges, forwardRef} from '@angular/core';
import { selectAnimations } from '../../animations/select';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

// Models
import { TaskDifficulty } from '../../../shared/models/task-difficulty.model';

@Component({
  selector: 'ffcrm-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  animations: selectAnimations,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() classList = 'select';
  @Input() labelKey = 'label';
  @Input() idKey = 'id';
  @Input() model: object;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() difficultySelected: TaskDifficulty | object | string = 'Select...';

  inputValue: number;

  get value(): number {
    return this.inputValue;
  }

  @Input() set value(value: number) {
    this.inputValue = value || 0;
    this.onChange(this.inputValue || 0);
    this.onTouched();
  }

  @Output() selected = new EventEmitter();

  @ViewChild('dropDown') dropDown;

  // tslint:disable-next-line:variable-name
  private _options: any[];

  get options(): object[] {
    return this._options;
  }

  @Input()
  set options(value) {
    if (value){
      this.originalOptions = [...value];
      this._options = this.originalOptions?.reverse();

      if (this.model !== undefined) {
        this.model = this.options?.find(currentOption => currentOption[this.idKey] === this.model);
      } else {
        this.model = this.options?.find(currentOption => currentOption[this.idKey] === this.inputValue);
      }
    }
  }

  originalOptions = [];
  dropdown = false;
  constructor() { }


  ngOnInit(): void {
    if (!(this.model instanceof Object)) {
      this.model = this.options?.find(currentOption => currentOption[this.idKey] === this.model);
    }
  }

  ngOnChanges(): void {
    this.model = this.options?.find(currentOption => currentOption[this.idKey] === this.value) || this.model;
  }

  get label(): string {
    let labe = this.placeholder ? this.placeholder : 'Select...';

    if (this.model) {
      labe = this.model[this.labelKey];
    } else if (this.inputValue) {
      labe = this.options.find((option) => option[this.idKey] === this.inputValue[this.idKey])[this.labelKey];
    }

    return labe;
  }

  toggle(): void {
    this.dropdown = !this.dropdown;
  }

  select(item): void {
    if (this.type === 'difficulty') {
      this.difficultySelected = item;
    }
    this.value = item[this.idKey];
    this.model = this.options.find(
      currentOption => currentOption[this.idKey] === this.value
    );
    this.dropdown = false;
    this.selected.emit(this.value);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement): void {
    const clickedInsideDropDown = this.dropDown.nativeElement.contains(targetElement);
    if (!clickedInsideDropDown) {
      this.dropdown = false;
    }
  }

  private onChange = (value: number): void => {};

  private onTouched = (): void => {};

  public registerOnChange(fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  public writeValue(value: number): void {
    this.inputValue = value;
    this.model = this.options?.find(currentOption => currentOption[this.idKey] === value);
    if (this.type === 'difficulty' && this.model) {
      this.difficultySelected = this.model;
    }
    this.selected.emit(this.value);
  }
}
