import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

// Components
import { FormComponent } from '../../../shared/super/form-component';

// Models
import { TimeEntryActivity } from '../../../shared/models/time-entry-activity.model';
import { TimeEntry } from '../../../shared/models/time-entry.model';
import { Task } from '../../../shared/models/task.model';

// Services
import { ProjectService } from '../../services/project/project.service';
import { AlertService } from '../../../shared/services/alert.service';
import { TaskService } from '../../services/task/task.service';
import { SummaryService } from '../../services/summary/summary.service';
import { UserService } from '../../../shared/services/user/user.service';

// Validators
import { taskDateValidator, taskTimeValidator } from '../../../shared/validators/task-validators';
import { getDate } from '../../../shared/utils/getDate';


@Component({
  selector: 'ffcrm-add-time',
  templateUrl: './add-time.component.html',
  styleUrls: ['./add-time.component.scss']
})
export class AddTimeComponent extends FormComponent implements OnInit, OnDestroy {

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private taskService: TaskService,
    private projectService: ProjectService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private summaryService: SummaryService,
    private userService: UserService,
  ) {
    super();
    this.form = this.getCleanForm();
    this.$currentTask = this.taskService.$currentTask;

    this.subscriptions.push(
      this.activatedRoute?.params?.subscribe((params: Params) => {
        this.taskId = params?.taskId;
      })
    );

    this.subscriptions.push(
      this.activatedRoute?.parent?.params?.subscribe((params: Params) => {
        this.projectSlug = params?.projectSlug;
      })
    );

    this.activatedRoute.data.subscribe((data: Data) => {
      this.timeEntryActivities = data.activities;
    });
  }

  public profile = this.userService.profile;
  private projectSlug: string;
  public timeEntryId: number = null;
  public timeEntriesList: Array<TimeEntry> = [];
  public currentTaskId: number = null;
  public $currentTask: Observable<Task>;
  public timeEntryActivities: Array<TimeEntryActivity> = [];
  public form = this.getCleanForm();
  public isDelete: boolean;

  set timeEntry(value: number) {
    this.timeEntryId = value;
    const timeEntry = this.timeEntriesList?.find((item: TimeEntry) => item?.id === value);

    if (timeEntry) {
      this.form.reset({
        ...timeEntry,
        activity: typeof timeEntry.activity === 'number' ? timeEntry.activity : timeEntry.activity?.id,
      });
    } else {
      this.form = this.getCleanForm();
    }
  }

  get username(): string {
    const timeEntry = this.timeEntriesList?.find((item: TimeEntry) => item?.id === this.timeEntryId);
    return timeEntry.user.firstName + ' ' + timeEntry.user.lastName;
  }

  get taskId(): number {
    return this.currentTaskId;
  }

  set taskId(value: number) {
    if (value) {
      this.currentTaskId = +value;
    } else {
      this.form = this.getCleanForm();
    }
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.taskService.$currentTask?.subscribe(async (task: Task) => {
        this.timeEntriesList = task?.spentTimeEntries;
        if (task?.task?.id) {
          this.currentTaskId = task?.task?.id;

          this.activatedRoute?.params?.subscribe((params: Params) => {
            if (params?.timeEntryId) {
              this.timeEntry = +params.timeEntryId;
            }
          }).unsubscribe();
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public getCleanForm(): FormGroup {
    const date = new Date(Date.now());
    return this.fb.group({
      id: [0],
      minutes: [0, [taskTimeValidator]],
      date: [`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, [taskDateValidator]],
      entry_description: ['', [Validators.required]],
      isOvertime: [false],
      activity: []
    });
  }

  public async onStateChange(newDate?: string): Promise<void> {
    this.activatedRoute.queryParams.subscribe(async (queries: Params) => {
      const date = getDate(newDate);
      const queryParams = newDate ? {
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      } : queries;

      if (window.location.href.includes(`tasks/${this.taskId}/time-entry/add`)) {
        await this.router.navigate(['../../'], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge',
          queryParams,
        });
      } else if (window.location.href.includes(`task/${this.taskId}/add-time`)) {
        await this.router.navigate(['../../../'], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge',
          queryParams,
        });
      } else {
        await this.router.navigate(['../../'], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge',
          queryParams,
        });
      }
    }).unsubscribe();
    this.summaryService.refreshSummaryAsync();
  }

  private async manageTimeEntry(needClose?: boolean): Promise<void> {
    try {
      this.form.markAllAsTouched();

      if (this.form.valid) {
        if (this.timeEntryId) {
          await this.taskService.editTimeEntry(this.projectSlug, this.currentTaskId, this.form.value);
          await this.projectService.updateMonthes(this.form.get('date').value);
        } else {
          this.form.addControl('task', this.fb.control([]));
          this.form.get('task').setValue(this.currentTaskId);
          await this.taskService.addTimeEntry(this.projectSlug, this.form.value);
          await this.projectService.updateMonthes(this.form.get('date').value);

          if (window.location.href.includes(`tasks/${this.taskId}/time-entry/add`)) {
            await this.taskService.getCurrentTask(this.projectSlug, this.currentTaskId);
          } else if (window.location.href.includes(`task/${this.taskId}/add-time`)) {
            await this.taskService.getTasks(this.projectSlug);
          }
        }
      } else {
        return ;
      }

      await this.alertService.showAlert({
        success: true,
        message: 'Time entry success added!'
      });

      this.form.markAsUntouched();
      if (needClose) {
        await this.onStateChange(this.form.get('date').value);
      }

      this.form.patchValue(this.getCleanForm()?.value);
      this.form = this.getCleanForm();
    } catch (e) {
      this.form.removeControl('task');
      await this.getErrorsFromResponse(e.error);
    }
  }

  public openDeleteModal(): void {
    this.isDelete = true;
  }

  public closeDeleteModal(): void {
    this.isDelete = false;
  }

  public async removeTimeEntry(): Promise<void> {
    try {
      const response = await this.taskService.removeTimeEntry(this.timeEntryId);
      const code = response.status;

      await this.alertService.showAlert({
        success: true,
        message: 'Time entry success removed!'
      });

      if (code === 204) {
        await this.taskService.getCurrentTask(this.projectSlug, this.currentTaskId);
        await this.onStateChange();
      }
      else if (code === 205) {
        await this.router.navigate(['../../../../'], {
          replaceUrl: true,
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge',
        });
      }

    } catch (e) {
      await this.getErrorsFromResponse(e);
      console.error(e);
    }

    this.isDelete = false;
  }

  public async editAndClose(): Promise<void> {
    await this.manageTimeEntry(true);
  }

  public async createAndNew(): Promise<void> {
    await this.manageTimeEntry(false);
  }

  public async createAndClose(): Promise<void> {
    await this.manageTimeEntry(true);
  }

  public async datepickerDate(date): Promise<void> {
    this.form.get('date').patchValue(date || '');
    this.form.get('date').markAsTouched();
  }

}
