import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// Constants
import { environment } from '../../../../environments/environment';

// Services
import { ApiClient } from '../../../../app/shared/services/api-client.model';
import { HttpClientFactoryService } from 'src/app/shared/services/http-client-factory.service';

// Models
import { GoogleAuth, GoogleConfirm } from '../../interfaces/google-auth.model';

@Injectable({
  providedIn: 'root'
})
export class GoogleExportService {
  private authHttp: ApiClient;
  private confirmHttp: ApiClient;
  private userToken: string;
  private popUpSubject: Subject<string> = new Subject();

  constructor(
    private httpFactory: HttpClientFactoryService,
    private router: Router,
  ) {
    this.userToken = localStorage.getItem('token');
    this.authHttp = this.httpFactory.getHttpClient(`/sheets/auth/`);
    this.confirmHttp = this.httpFactory.getHttpClient(`/sheets/auth/confirm/`);
  }

  get token(): string {
    return this.userToken;
  }

  get $popUpSubject(): Observable<string> {
    return this.popUpSubject.asObservable();
  }

  public async authGoogle(location: string): Promise<GoogleAuth> {
    const locationUrl = new URL(location);
    locationUrl.searchParams.delete('state');
    locationUrl.searchParams.delete('code');
    locationUrl.searchParams.delete('scope');

    return await this.authHttp.post<GoogleAuth>('', {
      redirectUri: locationUrl.href,
    }).toPromise();
  }

  public async confirmGoogle(currentLocation: string, redirectLocation: string): Promise<GoogleConfirm> {
    return await this.confirmHttp.post<GoogleConfirm>('', {
      response: currentLocation,
      redirectUri: redirectLocation,
    }).toPromise();
  }

  private startTasksWS(groupSlug: string, projectSlug: string, year: number, month: number, user?: number): WebSocket {
    const ws = new WebSocket(`${environment.wsRoot}/sheets/${groupSlug}/${projectSlug}/?token=${this.token}`);

    ws.onopen = () => {
      ws.send(JSON.stringify({
        type: 'sheets',
        year,
        month,
        ...(user && { user })
      }));
    };

    this.openModalConfirm(ws);
    return ws;
  }

  private startPersonalTasksWS(year: number, month: number, user: number): WebSocket {
    const ws = new WebSocket(`${environment.wsRoot}/sheets/tasks/${user}/?token=${this.token}`);

    ws.onopen = () => {
      ws.send(JSON.stringify({
        type: 'sheets',
        year,
        month,
      }));
    };

    this.openModalConfirm(ws);
    return ws;
  }

  private startOvertimesWS(year: number, month: number, user?: number): WebSocket {
    const ws = new WebSocket(`${environment.wsRoot}/sheets/overtimes/?token=${this.token}`);

    ws.onopen = () => {
      ws.send(JSON.stringify({
        type: 'sheets',
        year,
        month,
        ...(user && { user })
      }));
    };

    this.openModalConfirm(ws);
    return ws;
  }

  public async exportUtil(
    confirmUrl: string,
    redirectUrl: string,
    websocketName: string,
    websocketArguments: Array<any>
  ): Promise<WebSocket> {
    try {
      await this.confirmGoogle(confirmUrl, redirectUrl);
      await this.router.navigate([], {
        queryParams: {
          confirm_url: null,
          redirect_url: null,
        },
        queryParamsHandling: 'merge',
      });

      return this[websocketName](...websocketArguments);
    } catch (e) {
     console.error(e?.message);
    }
  }

  private openModalConfirm(ws: WebSocket): void {
    ws.onmessage = (response) => {
      this.popUpSubject.next(JSON.parse(response?.data)?.url);
    };
  }
}
