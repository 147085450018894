import { Injectable } from '@angular/core';
import { Group } from '../../shared/models/group.model';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GroupService } from '../services/group/group.service';

@Injectable({
  providedIn: 'root'
})
export class GroupResolver implements Resolve<Group> {

  constructor(
    private groupService: GroupService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Group> | Promise<Group> | Group {
    return this.groupService.getGroupAsync(route.params?.groupSlug);
  }
}
