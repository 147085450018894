import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { MonthYear } from '../../models/month-year.model';


export interface IMonthYearFilterService {
  monthes: Array<MonthYear>;
  $monthes: Observable<Array<MonthYear>>;
}

export const YEAR_MONTH_FILTER_SERVICE = new InjectionToken<IMonthYearFilterService>('YEAR_MONTH_FILTER_SERVICE');

export class T {
  constructor() {
    throw new Error('No Provider for YEAR_MONTH_FILTER_SERVICE InjectionToken. Need provide service at parent component');
  }
}
