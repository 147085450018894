import { animate, state, style, transition, trigger } from '@angular/animations';

export const selectAnimations = [
  trigger('openClose', [
    state('true', style({
      opacity: 1,
    })),
    state('false', style({
      opacity: 0,
    })),
    transition('* => *', [
      animate('150ms linear')
    ]),
  ]),
  trigger('rotateArrow', [
    state('true', style({
      transform: 'rotate(-180deg)'
    })),
    state('false', style({
      transform: 'rotate(0)'
    })),
    transition('* => *', [animate('150ms linear')])
  ]),
];

export const headerAnimations = [
  trigger('openClose', [
    state('true', style({
      opacity: 1
    })),
    state('false', style({
      opacity: 0
    })),
    transition('* => *', [
      animate('150ms linear')
    ]),
  ]),
  trigger('rotateArrow', [
    state('true', style({
      transform: 'rotate(-180deg)'
    })),
    state('false', style({
      transform: 'rotate(0)'
    })),
    transition('* => *', [animate('150ms linear')])
  ]),
  trigger('slideInOut', [
    transition(':enter', [
      style({ transform: 'translateX(100%)' }),
      animate('100ms ease-in', style({ transform: 'translateX(0%)' }))
    ]),
    transition(':leave', [
      animate('100ms ease-in', style({ transform: 'translateX(100%)' }))
    ])
  ])
];
