import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ffcrm-accept',
  templateUrl: './accept.component.html',
  styleUrls: ['./accept.component.scss']
})
export class AcceptComponent {

  constructor() { }

  @Input() isVisible = false;
  @Input() description: string;
  @Input() title = 'Accepting';
  @Input() link: string;
  @Input() acceptBtnText = 'Accept';
  @Input() type = 'accept';
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() accept: EventEmitter<boolean> = new EventEmitter<boolean>();

  public acceptClick($event): void {
    this.accept.emit($event);
  }

  public cancelClick($event): void {
    this.cancel.emit($event);
  }

}
