export class ChangePasswordError {
  oldPassword: string[];
  newPassword: string[];

  constructor(errors: any) {
    errors.hasOwnProperty('old_password') ? this.oldPassword = errors.old_password : this.oldPassword = null;
    errors.hasOwnProperty('new_password') ? this.newPassword = errors.new_password : this.newPassword = null;
  }

  haveOldPassword(): boolean {
    return !!this.oldPassword;
  }

  haveNewPassword(): boolean {
    return !!this.newPassword;
  }
}
