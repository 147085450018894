import { Injectable } from '@angular/core';
import { AlertBody } from '../models/alert-body.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor() { }

  private alertSubject: Subject<AlertBody> = new Subject<AlertBody>();

  get $alert(): Observable<AlertBody> {
    return this.alertSubject.asObservable();
  }

  public async showAlert(body: AlertBody): Promise<void> {
    if (body && body?.message) {
      this.alertSubject.next(body);
    }
  }
}
