import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

// Interfaces
import { OvertimeRecord } from '../interfaces/overtime.model';

// Services
import { OvertimeService } from '../../shared/services/overtime.service';

@Injectable({
  providedIn: 'root'
})
export class SingleOvertimeResolver implements Resolve<OvertimeRecord | unknown> {

  constructor(
    private overtimeService: OvertimeService,
    private router: Router,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OvertimeRecord | unknown> {
    if (route.params?.overtimeId) {
      return this.overtimeService.getOvertime(route.params?.overtimeId).pipe(catchError((err) => this.router.navigateByUrl('/')));
    }
  }
}
