import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { MousePos } from '../../models/mouse-pos';

import { FloatingHelpService } from '../../services/floating-help.service';

@Component({
  selector: 'ffcrm-floating-help',
  templateUrl: './floating-help.component.html',
  styleUrls: ['./floating-help.component.scss']
})
export class FloatingHelpComponent {

  constructor(private floatingHelpService: FloatingHelpService) {
    this.$helpText = this.floatingHelpService.$text;
    this.$mousePos = this.floatingHelpService.$mousePos;
    this.$helpNeeded = this.floatingHelpService.$helpNeeded;
  }

  public readonly $helpText: Observable<string>;
  public readonly $mousePos: Observable<MousePos>;
  public readonly $helpNeeded: Observable<boolean>;
}
