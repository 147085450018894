import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

// Models
import { ProjectAutocomplete } from '../../../shared/models/project-autocomplete.model';
import { TimeEntryActivity } from '../../../shared/models/time-entry-activity.model';
import { TaskDifficulty } from '../../../shared/models/task-difficulty.model';
import { ProjectTasks } from '../../../shared/models/project-tasks.model';
import { IDragNDropService } from '../injection-tokens/drag-n-drop-token';
import { UserProfile } from '../../../shared/models/user-profile.model';
import { ApiClient } from '../../../shared/services/api-client.model';
import { TimeEntry } from '../../../shared/models/time-entry.model';
import { MonthYear } from '../../models/month-year.model';
import { Paginate } from '../../interfaces/paginate.model';
import { Task } from '../../../shared/models/task.model';
import { OvertimeTask } from '../../interfaces/overtime.model';

// Services
import { HttpClientFactoryService } from '../../../shared/services/http-client-factory.service';
import { IMonthYearFilterService } from '../injection-tokens/month-year-filter-token';
import { UserService } from '../../../shared/services/user/user.service';

// Utils
import { getDate } from '../../../shared/utils/getDate';


@Injectable({
  providedIn: 'root'
})
export class TaskService implements IDragNDropService, IMonthYearFilterService {
  private http: ApiClient;
  private manageHttp: ApiClient;
  private taskHttp: ApiClient;
  private queries: BehaviorSubject<Params> = new BehaviorSubject<Params>(null);
  public tasksListSubject: BehaviorSubject<ProjectTasks> = new BehaviorSubject<ProjectTasks>(null);
  public currentTaskSubject: BehaviorSubject<Task> = new BehaviorSubject<Task>(null);
  public activitiesSubject: BehaviorSubject<TimeEntryActivity[]> = new BehaviorSubject<TimeEntryActivity[]>([]);
  public difficultiesSubject: BehaviorSubject<TaskDifficulty[]> = new BehaviorSubject<TaskDifficulty[]>([]);
  private monthesSubject: BehaviorSubject<Array<MonthYear>> = new BehaviorSubject<Array<MonthYear>>([]);

  constructor(
    private userService: UserService,
    private httpFactory: HttpClientFactoryService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.taskHttp = this.httpFactory.getHttpClient(`/tasks/`);
    this.manageHttp = this.httpFactory.getHttpClient(`/projects/manage/`);
    this.http = this.httpFactory.getHttpClient(`/projects/`);
    this.activatedRoute.queryParams.subscribe((queries: Params) => {
      this.queries.next(queries);
    });
  }

  get items(): Array<any> {
    return this.tasksListSubject?.value?.tasks;
  }

  get $items(): Observable<Array<any>> {
    return this.tasksListSubject.asObservable().pipe(map((list: ProjectTasks) => list?.tasks));
  }

  get currentItem(): any {
    return this.currentTaskSubject.value;
  }

  get $currentItem(): Observable<any> {
    return this.currentTaskSubject.asObservable();
  }

  public updateMembers(slug: string, members: { members: Array<UserProfile> }): Observable<void> {
    throw new Error('Method not implemented.');
  }

  public async getManageItemInstance(slug: string): Promise<any> {
    return this.tasksListSubject.value.tasks.find((task: Task) => task.id === +slug);
  }

  get $tasks(): Observable<ProjectTasks> {
    return this.tasksListSubject.asObservable();
  }

  get tasks(): Array<Task | OvertimeTask> {
    return this.tasksListSubject.value?.tasks;
  }

  get taskListObject(): ProjectTasks {
    return this.tasksListSubject.value;
  }

  get $currentTask(): Observable<Task> {
    return this.currentTaskSubject.asObservable();
  }

  get currentTask(): Task {
    return this.currentTaskSubject.value;
  }

  get activities(): Array<TimeEntryActivity> {
    return this.activitiesSubject.value;
  }

  get $activities(): Observable<Array<TimeEntryActivity>> {
    return this.activitiesSubject.asObservable();
  }

  get monthes(): Array<MonthYear> {
    return this.monthesSubject.value;
  }

  get $monthes(): Observable<Array<MonthYear>> {
    return this.monthesSubject.asObservable();
  }

  private retrieveTaskListAsync(projectSlug: string, queries?: HttpParams): Observable<ProjectTasks> {
    let queryParams = new HttpParams();

    if (queries) {
      queryParams = queries;
    }

    if (this.userService.profile?.isStaff) {
      return this.manageHttp.get<ProjectTasks>(`${projectSlug}/tasks/`, queryParams).pipe(tap((tasks: ProjectTasks) => {
        this.tasksListSubject.next(tasks);
      }));
    } else {
      return this.http.get<ProjectTasks>(`${projectSlug}/tasks/`, queryParams).pipe(tap((tasks: ProjectTasks) => {
        this.tasksListSubject.next(tasks);
      }));
    }
  }

  public async getTasks(projectSlug: string, month?: number, year?: number, userFilter?: boolean): Promise<ProjectTasks> {
    let projectTasks: ProjectTasks;
    let queryParams = this.getMonthYearHttpParams(year, month);

    const filterQuery = this.queries.value['task-filter'];
    const userId = this.queries.value?.task_user;

    if ((userFilter || filterQuery && filterQuery !== 'all') && !this.userService.profile.isStaff) {
      queryParams = queryParams.set('user', this.userService.profile.id.toString());
    }

    if (userId && userId !== 'all' && this.userService.profile.isStaff) {
      queryParams = queryParams.set('user', userId);
    }

    projectTasks = await this.retrieveTaskListAsync(projectSlug, queryParams).toPromise();

    this.tasksListSubject.next(projectTasks);
    return projectTasks;
  }

  public getTasksAsync(projectSlug: string, queries?: Params | HttpParams): Observable<ProjectTasks> {
    return this.retrieveTaskListAsync(projectSlug, queries as HttpParams).pipe(shareReplay());
  }

  private retrieveTaskAsync(projectSlug: string, taskId: number, queries?: HttpParams): Observable<Task> {
    let queryParams = new HttpParams();

    if (queries) {
      queryParams = queries;
    }

    if (this.userService.profile?.isStaff) {
      return this.manageHttp.get<Task>(`${projectSlug}/tasks/${taskId}/spent-time/`, queryParams).pipe(tap((task: Task) => {
          this.currentTaskSubject.next(task);
          this.monthesSubject.next(task?.task.months);
        }));
    } else {
      return this.http.get<Task>(`${projectSlug}/tasks/${taskId}/spent-time/`, queryParams).pipe(tap((task: Task) => {
        this.currentTaskSubject.next(task);
        this.monthesSubject.next(task?.task.months);
      }));
    }
  }

  private getMonthYearHttpParams(year?: number, month?: number): HttpParams {
    let queryParams = new HttpParams();

    if (month) {
      queryParams = queryParams.set('month', month.toString());
      if (year) {
        queryParams = queryParams.set('year', year.toString());
      }
    } else if (this.queries.value?.month && this.queries.value?.year) {
      queryParams = queryParams.set('month', this.queries.value?.month?.toString());
      queryParams = queryParams.set('year', this.queries.value?.year?.toString());
    }

    return queryParams;
  }

  public async getCurrentTask(projectSlug: string, taskId: number, month?: number, year?: number, activity?: number): Promise<void> {
    let currentTask: Task;
    let queryParams = this.getMonthYearHttpParams(year, month);

    if (+activity) {
      queryParams = queryParams.set('activity', activity.toString());
    } else if (+this.queries.value?.activity) {
      queryParams = queryParams.set('activity', this.queries.value?.activity?.toString());
    }

    currentTask = await this.retrieveTaskAsync(projectSlug, taskId, queryParams).toPromise();
    this.currentTaskSubject.next(currentTask);
  }

  public getCurrentTaskAsync(projectSlug: string, taskId: number, queries?: Params | HttpParams): Observable<Task> {
    return this.retrieveTaskAsync(projectSlug, taskId, queries as HttpParams).pipe(shareReplay());
  }

  public async closeAllTasks(): Promise<void> {
    await this.tasksListSubject.next(null);
  }

  public async removeTask(projectSlug: string, taskId: number): Promise<void> {
    try {
      if (this.userService.profile?.isStaff) {
        await this.http.delete(`manage/${projectSlug}/tasks/${taskId}/`).toPromise();
      } else {
        await this.http.delete(`${projectSlug}/tasks/${taskId}/`).toPromise();
      }

      await this.getTasks(projectSlug);
    } catch (e) {
      throw e;
    }
  }

  public async editTask(projectSlug: string, taskId: number, task: Task): Promise<void> {
    try {
      if (this.userService.profile?.isStaff) {
        await this.http.put(`manage/${projectSlug}/tasks/${taskId}/`, task).toPromise();
      } else {
        await this.http.put(`${projectSlug}/tasks/${taskId}/`, task).toPromise();
      }

      await this.getTasks(projectSlug);
      await this.getCurrentTask(projectSlug, taskId);
    } catch (e) {
      throw e;
    }
  }

  public async addTask(projectSlug: string, task: Task): Promise<void> {
    try {
      const date = getDate(task?.date);
      const queries = this.activatedRoute.snapshot.queryParams;
      const queryDate = getDate(`${queries?.year}-${queries?.month}-01`);
      await this.http.post(`${projectSlug}/tasks/`, task).toPromise();

      if (date.getFullYear() === queryDate.getFullYear() && date.getMonth() === queryDate.getMonth()) {
        await this.getTasks(projectSlug, date.getMonth() + 1, date.getFullYear());
      }
    } catch (e) {
      throw e;
    }
  }

  public async addTimeEntry(projectSlug: string, timeEntry: TimeEntry, refreshTask?: boolean): Promise<void> {
    try {
      await this.http.post(`${projectSlug}/tasks/`, timeEntry).toPromise();

      if (refreshTask) {
        await this.getTasks(projectSlug);
      }
    } catch (e) {
      throw e;
    }
  }

  public async editTimeEntry(projectSlug: string, taskId: number, timeEntry: TimeEntry): Promise<void> {
    await this.taskHttp.put<TimeEntry>(`spent_time/${timeEntry?.id}/`, timeEntry).toPromise();
    await this.getCurrentTask(projectSlug, taskId);
  }

  public async removeTimeEntry(timeEntryId: number): Promise<HttpResponse<TimeEntry>> {
    try {
      return await this.taskHttp.delete<TimeEntry>(`spent_time/${timeEntryId}/`).toPromise();
    } catch (e) {
      throw e;
    }
  }

  public getTimeEntryActivitiesAsync(): Observable<Array<TimeEntryActivity>> {
    return this.taskHttp.get<Paginate<TimeEntryActivity>>('spent_time/activities/').pipe(
      map((activities: Paginate<TimeEntryActivity>) => {
        if (activities?.results) {
          this.activitiesSubject.next(activities.results);
        }
        return activities?.results;
      })
    );
  }

  public getTaskDifficultiesAsync(): Observable<Array<TaskDifficulty>> {
    return this.taskHttp.get<Paginate<TaskDifficulty>>('difficulties/').pipe(
      map((difficulties: Paginate<TaskDifficulty>) => {
        if (difficulties?.results) {
          this.difficultiesSubject.next(difficulties.results);
        }
        return difficulties?.results;
      })
    );
  }

  public async closeMonth(projectSlug: string, year: number, month: number, isClosed: boolean): Promise<void> {
    try {
      await this.http.post(`manage/${projectSlug}/month/${year}/${month}/`, {
        isClosed: !isClosed || false,
      }).toPromise();
      await this.getTasks(projectSlug, month, year);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public autocomplete(reqString: string, projectSlug: string): Promise<Array<ProjectAutocomplete>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('title', reqString);
    return this.http.get<Paginate<ProjectAutocomplete>>(`${projectSlug}/tasks/autocomplete/`, httpParams)
      .pipe(map((result: Paginate<ProjectAutocomplete>) => result?.results)).toPromise();
  }
}
