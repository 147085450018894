<div class="wrapper">
  <div class="grid">
    <div class="logo-wrapper">
      <ffcrm-logo></ffcrm-logo>
    </div>
    <div class="summary">
      <h1>Dashboard</h1>
      <div class="statistic">
        <div class="projects-stat">
          <p class="text">{{ profile?.isStaff ? 'Active groups' : 'Active Projects'}}</p>
          <p class="number">{{ profile?.isStaff ? ($summary | async)?.activeGroups : ($summary | async)?.activeProjects }}</p>
        </div>
        <div class="groups-stat">
          <p class="text">{{ profile?.isStaff ? 'Active projects' : 'Overtime' }}</p>
          <p class="number">{{ profile?.isStaff ? ($summary | async)?.activeProjects : (($summary | async)?.overtime | hhmm) }}</p>
        </div>
        <div class="users-stat">
          <p class="text">{{ profile?.isStaff ? 'Active users' : 'Total time' }}</p>
          <p class="number">{{ profile?.isStaff ? (($summary | async)?.activeUsers) : (($summary | async)?.totalTime | hhmm) }}</p>
        </div>
      </div>
    </div>

    <div class="groups-search" *ngIf="$summary | async">
      <div class="groups">
        <div class="heading">
          <h2>{{ profile?.isStaff ? 'Users' : 'Projects' }}</h2>
          <ffcrm-select *ngIf="profile?.isStaff" class="activity-select" [options]="selectOptions" (selected)="filterUsersByActivity($event)"></ffcrm-select>
        </div>
        <div class="search">
          <div class="input-block">
            <i class="icon icon-search search-icon"></i>
            <input #search class="input-field input-search" type="text" (input)="filterItems($event.target)" placeholder="Search">
          </div>
        </div>
      </div>
    </div>


    <div class="items-list" [class.no-items]="!projects.length && !users.length">
      <div class="items-list-no-groups" *ngIf="!coldProjects?.length && !coldUsers?.length">
        <img class="no-groups-folder" src="../../../../assets/images/empty-folder.svg"
             (alt)="profile?.isStaff ? 'No users' :  'No projects'" />
        <p class="no-groups-text">{{ profile?.isStaff ? 'No users' : 'No projects' }}</p>
      </div>

      <div [class.projects-list]="!profile?.isStaff" [class.users-list]="profile?.isStaff">
        <div class="static-items" *ngIf="projects.length || users.length">
          <ul>
            <li (click)="profile?.isStaff ? chooseUser('all') : chooseProject('all')" [class.selected-item]="isSelected('all')">
              <div class="image-container">
                <div class="image-wrapper all-projects"></div>
              </div>
              <div class="name">{{profile?.isStaff ? 'All users' : 'All projects'}}</div>
            </li>
          </ul>
        </div>
        <div class="starred" *ngIf="(projects | starred)?.length">
          <p class="group-title">STARRED PROJECTS</p>

          <ul>
            <li *ngFor="let project of projects | starred" (click)="chooseProject(project.slug)" [class.selected-item]="isSelected(project.slug)">

              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
              <div class="star" (click)="$event.preventDefault(); $event.stopPropagation(); toggleProjectStar(project)">
                <i class="icon icon-star icon-18px"></i>
              </div>
            </li>
          </ul>
        </div>

        <div class="other" *ngIf="(projects | other)?.length">
          <p class="group-title">OTHER PROJECTS</p>

          <ul>
            <li *ngFor="let project of projects | other" (click)="chooseProject(project.slug)" [class.selected-item]="isSelected(project.slug)">

              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
              <div class="star" (click)="$event.preventDefault(); $event.stopPropagation(); toggleProjectStar(project)">
                <i class="icon icon-star-gray icon-18px"></i>
              </div>
            </li>
          </ul>
        </div>

        <div class="users" *ngIf="users?.length">
          <ul>
            <li *ngFor="let user of users" (click)="chooseUser(user.id)" [class.selected-item]="isSelected(user.id.toString())">
              <div class="image-container" (mouseenter)="showHelp(user?.firstName + ' ' + user?.lastName)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="user" srcKey="photo" [labelKeys]="['firstName']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name overtime-user-name">
                {{ user?.firstName + ' ' + user?.lastName }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
