import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

// Services
import { UserService } from '../../shared/services/user/user.service';
import { UserProfile } from '../../shared/models/user-profile.model';


@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<UserProfile> {
  constructor(private userService: UserService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserProfile> | Promise<UserProfile> | UserProfile {
    return this.userService.getUserAsync(+route.params?.userId);
  }
}
