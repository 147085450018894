<ffcrm-dialog [(visible)]="changePasswordDialog" (visibleChange)="dialogVisibleChange()" [title]="'Change password'" *ngIf="changePasswordDialog" [closable]="false">
  <div class="dialog-body">
    <form [formGroup]="changePasswordForm" (ngSubmit)="savePassword()">

      <div class="input-block">
        <label for="old_password">Current password</label>
        <input type="password" class="input-field" formControlName="oldPassword" placeholder="Enter your password" id="old_password" name="old_password" (input)="clearBackendErrors()"
               [ngClass]="{'error': oldPassword.invalid && (oldPassword.dirty || oldPassword.touched) || (changePasswordErrors && changePasswordErrors.haveOldPassword()) === true}">

        <div class="error-msg" *ngIf="oldPassword.invalid && (oldPassword.dirty || oldPassword.touched)">

          <div *ngIf="oldPassword.errors.required">
            Current password is required.
          </div>

        </div>

        <div class="error-msg" [style.opacity]="0" *ngIf="!(oldPassword.invalid && (oldPassword.dirty || oldPassword.touched) || (changePasswordErrors && changePasswordErrors.haveOldPassword()))">
          1
        </div>

        <div *ngIf="changePasswordErrors && changePasswordErrors.haveOldPassword()">
          <p *ngFor="let error of changePasswordErrors.oldPassword" class="error-msg"> {{ error }} </p>
        </div>

      </div>



      <div class="input-block">
        <label for="new_password">New password</label>
        <input type="password" class="input-field" formControlName="newPassword" placeholder="Enter your new password" id="new_password" name="new_password" (input)="clearBackendErrors()"
               [ngClass]="{'error': newPassword.invalid && (newPassword.dirty || newPassword.touched) || (changePasswordErrors && changePasswordErrors.haveNewPassword()) === true}">

        <div class="error-msg" *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)">
          <div *ngIf="newPassword.errors.required">
            New password is required.
          </div>
        </div>

        <div class="error-msg" [style.opacity]="0" *ngIf="!(newPassword.invalid && (newPassword.dirty || newPassword.touched) || (changePasswordErrors && changePasswordErrors.haveNewPassword()))">
          1
        </div>

        <div *ngIf="changePasswordErrors && changePasswordErrors.haveNewPassword()">
          <p *ngFor="let error of changePasswordErrors.newPassword" class="error-msg"> {{ error }} </p>
        </div>

      </div>

      <div class="input-block">
        <label for="confirm_password">Confirm new password</label>
        <input type="password" class="input-field" formControlName="confirmPassword" placeholder="Re-enter new password" id="confirm_password" name="confirm_password"
               [ngClass]="{'error': confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched) === true}">

        <div class="error-msg" *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">

          <div *ngIf="confirmPassword.errors.required">
            Password confirmation is required.
          </div>

          <div *ngIf="confirmPassword.errors.mustMatch">
            Passwords does not match.
          </div>

        </div>

        <div class="error-msg" [style.opacity]="0" *ngIf="!(confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched))">
          1
        </div>

      </div>


      <button class="btn btn-block">Save changes</button>
    </form>
  </div>
</ffcrm-dialog>

<ffcrm-dialog [(visible)]="successDialog" [type]="'info'">
  <div class="dialog-body-success">
    <i class="fas fa-check"></i>
    <h1>Success</h1>
    <p>Your password has been successfully changed</p>
  </div>
</ffcrm-dialog>
