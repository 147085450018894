<div class="main-wrapper">
  <div class="grid" *ngIf="!userPage">
    <div class="head">

      <div class="summary">
        <h1>All users</h1>

        <div class="statistic">
          <div class="stat-item">
            <p class="text">Active users</p>
            <p class="number">{{ summary?.activeUsers }}</p>
          </div>
          <div class="stat-item">
            <p class="text">Blocked users</p>
            <p class="number">{{ summary?.inactiveUsers }}</p>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="block">
          <div class="search">
            <div class="input-block">
              <i class="icon icon-search search-icon"></i>
              <input #search class="input-field input-search" type="text" id="search" placeholder="Search" (input)="filterUsers()">
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="filter">
      <h2>{{ filterHeader }}</h2>
      <ffcrm-select class="user-select" [options]="selectOptions" [value]="selectFilterValue" (selected)="selectedFilter($event)"></ffcrm-select>
    </div>
    <div class="users" #usersBlock>
      <div class="cards">
        <ng-container *ngFor="let user of filteredUsers">
          <ffcrm-card type="user" [isAdmin]="user.isStaff" [isActive]="user.isActive" [instanceId]="user.id" [routerLink]="'./' + user.id" queryParamsHandling="preserve"></ffcrm-card>
        </ng-container>
      </div>
    </div>
    <div class="add-button">
      <a class="btn btn-block btn-add" routerLink="./add" queryParamsHandling="preserve">
        <i class="icon icon-add"></i>
        Add user
      </a>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
