import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

// Guards
import { OvertimeMonthYearGuard } from '../../shared/super/overtime-month-year-guard';

// Models
import { MonthYear } from '../models/month-year.model';

// Services
import { OvertimeService } from '../../shared/services/overtime.service';
import { UserService } from '../../shared/services/user/user.service';


@Injectable({
  providedIn: 'root',
})
export class OvertimeYearMonthGuard extends OvertimeMonthYearGuard implements CanActivate {
  constructor(
    private overtimeService: OvertimeService,
    userService: UserService,
    router: Router
  ) {
    super(router, userService);

    this.subscriptions.push(
      this.overtimeService.$monthes.subscribe((monthes: Array<MonthYear>) => {
        this.monthYearList = monthes;
      }),
    );
  }

  private queryParams;

  protected loadConditions(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this.mainCondition = !route.queryParams?.year && !route.queryParams?.month;
    this.queryParams = route.queryParams;
  }

  protected async loadMonthes(userMonthes: boolean): Promise<void> {
    const user = await this.queryParams?.user;

    if (userMonthes || user) {
      await this.overtimeService.getMonthes('months/', {
        ...(user && { user })
      });
    } else {
      await this.overtimeService.getMonthes('months/all/');
    }
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    this.loadConditions(route, state);
    return await this.canActivateCustom(route, state);
  }
}
