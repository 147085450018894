import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// Models
import { ApiClient } from '../../shared/services/api-client.model';
import { environment } from '../../../environments/environment';
import { LoginFormModel } from '../models/login.model';

// Services
import { HttpClientFactoryService } from '../../shared/services/http-client-factory.service';
import { ValidateCheck } from '../models/validate-check.model';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private http: ApiClient;
  private currentUserTokenSubject: BehaviorSubject<string>;
  public currentUserToken: Observable<string>;
  private validatedEmail: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private httpFactory: HttpClientFactoryService) {
    this.http = this.httpFactory.getHttpClient('/auth/');
    this.currentUserTokenSubject = new BehaviorSubject(localStorage.getItem('token'));
    this.currentUserToken = this.currentUserTokenSubject.asObservable();
  }

  get validEmail(): string {
    return this.validatedEmail.value;
  }

  public get currentUserTokenValue(): any {
    return this.currentUserTokenSubject.value;
  }

  public loginUser(login: LoginFormModel): Observable<object> {
    return this.http.post('', login).pipe(map((token: any) => {
      localStorage.setItem('token', token.token);
      this.currentUserTokenSubject.next(token.token);
      return token;
    }));
  }

  public logout(): void {
    localStorage.removeItem('token');
    this.currentUserTokenSubject.next(null);
  }

  public forgotPassword(data): Observable<object> {
    return this.http.post('reset-password/', data);
  }

  public resetPassword(data): Observable<object> {
    return this.http.post('reset-password/change-password/', data);
  }

  public checkChangePassword(token: string): Observable<ValidateCheck> {
    return this.http.get<ValidateCheck>(`reset-password/validate/${token}/`).pipe(tap((response: ValidateCheck) => {
      this.validatedEmail.next(response?.email || '');
    }));
  }

  public changePassword(data): Observable<object> {
    return this.http.post('change-password/', data);
  }

  public createPassword(data): Observable<any> {
    return this.http.post('create-password/', data);
  }

  public checkCreatePassword(token: string): Observable<ValidateCheck> {
    return this.http.get<ValidateCheck>(`create-password/validate/${token}/`).pipe(tap((response: ValidateCheck) => {
      this.validatedEmail.next(response?.email || '');
    }));
  }
}
