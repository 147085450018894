import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

// Services
import { AuthService } from '../services/auth.service';
import { ValidateCheck } from '../models/validate-check.model';


@Injectable({
  providedIn: 'root'
})
export class RestoreTokenGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    let result: ValidateCheck;
    try {
      switch (route.data.mode) {
        case 'RECOVERY_PASSWORD': result = await this.authService.checkChangePassword(route.queryParams?.token).toPromise(); break;
        case 'CREATE_PASSWORD': result = await this.authService.checkCreatePassword(route.queryParams?.token).toPromise(); break;
      }

      if (!result.isValid) {
        await this.router.navigate(['/forgot-password'], { queryParams: { error: 'expired' } });
      }

      return result?.isValid;
    } catch (error) {
      if (error?.status === 404) {
        await this.router.navigate(['/404'], { skipLocationChange: true });
      }
    }

    return false;
  }
}
