import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';

// Components
import { SubscriptionsComponent } from '../../../shared/super/subscriptions-component';

// Models
import { SummaryAdmin } from '../../interfaces/summary-admin.model';
import { Profile } from '../../../shared/models/profile.model';
import { Project } from '../../../shared/models/project.model';

// Services
import { UsersProjectService } from '../../services/all-users/users-projects/users-project.service';
import { FloatingHelpService } from '../../../shared/services/floating-help.service';
import { UserService } from '../../../shared/services/user/user.service';
import { SummaryService } from '../../services/summary/summary.service';
import { ProjectService } from '../../services/project/project.service';


@Component({
  selector: 'ffcrm-all-users-left',
  templateUrl: './all-users-left.component.html',
  styleUrls: ['./all-users-left.component.scss']
})
export class AllUsersLeftComponent extends SubscriptionsComponent implements OnInit {

  constructor(
    private userService: UserService,
    private summaryService: SummaryService,
    private floatingHelpService: FloatingHelpService,
    private projectService: ProjectService,
    private userProjectService: UsersProjectService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();

    this.$profile = this.userService.$profile;
    this.$summary = this.summaryService.$summary as Observable<SummaryAdmin>;
    this.$userSidebar = this.userService.$userSidebar;
  }

  private projectsAll: Array<Project>;

  public readonly $profile: Observable<Profile>;
  public readonly $userSidebar: Observable<boolean>;
  public readonly $summary: Observable<SummaryAdmin>;

  public filteredProjects: Array<Project>;
  public projectSlug: string;

  @ViewChild('search') public search;

  public ngOnInit(): void {
    this.projectSlug = this.activatedRoute.snapshot.queryParams?.project;

    this.subscriptions.push(
      this.userProjectService.$leftPanelProjects.subscribe((projects: Array<Project>) => {
        this.projectsAll = projects;

        this.filterItems();
      }),
    );
  }

  public filterItems(): void {
    let keyword = '';

    if (this.summaryService.summary && this.search) {
      keyword = this.search?.nativeElement?.value.toLowerCase();
    }

    if (this.projectsAll?.length) {
      this.filteredProjects = this.projectsAll?.filter((project: Project) => project.name.toLowerCase().includes(keyword));
    } else {
      this.filteredProjects = [];
    }
  }

  public async toggleProjectStar(project): Promise<void> {
    try {
      if (project.isStarred) {
        await this.userProjectService.unStarProject(project.slug);
      } else {
        await this.userProjectService.starProject(project.slug);
      }
    } catch (e) {
      console.error(e);
    }
  }

  public isSelected(slug: string): boolean {
    if (slug === 'all') {
      return !this.activatedRoute.snapshot?.queryParams?.project || this.activatedRoute.snapshot?.queryParams?.project === 'all';
    }

    return this.activatedRoute.snapshot?.queryParams?.project === slug;
  }

  public async selectProject(projectSlug: string): Promise<void> {
    await this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        project: projectSlug,
      },
    });
  }

  public showHelp(newText: string): void {
    if (!this.userService.userSidebar) {
      this.floatingHelpService.setHelpState(true);
      this.floatingHelpService.setHelpText(newText);
    }
  }

  public closeHelp(): void {
    this.floatingHelpService.setHelpState(false);
  }

}
