import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllUsersComponent } from '../main/pages/all-users/all-users.component';
import { AuthGuard } from '../shared/helpers/auth.guard';
import { UsersResolver } from '../main/resolvers/users-resolver.service';
import { UsersListComponent } from '../main/components/users-list/users-list.component';
import { ProfileModalComponent } from '../shared/components/profile-modal/profile-modal.component';
import { UserComponentComponent } from '../main/components/user-component/user-component.component';
import { TaskYearMonthGuard } from '../main/guards/task-year-month.guard';
import { UserResolver } from '../main/resolvers/user-resolver.service';
import { UserGroupsResolver } from '../main/resolvers/user-groups-resolver.service';
import { UserTasksResolver } from '../main/resolvers/user-tasks-resolver.service';
import { UserParticipationsModalComponent } from '../main/components/user-participations-modal/user-participations-modal.component';


const allUsersRoutes: Routes = [
  {
    path: '',
    component: UsersListComponent,
    children: [
      {
        path: 'add',
        component: ProfileModalComponent,
        data: {
          mode: 'add',
        },
      },
    ],
  },
  {
    path: ':userId',
    component: UserComponentComponent,
    canActivate: [AuthGuard, TaskYearMonthGuard],
    resolve: {
      user: UserResolver,
      groups: UserGroupsResolver,
      tasks: UserTasksResolver,
    },
    children: [
      {
        path: 'edit',
        component: ProfileModalComponent,
        data: {
          mode: 'edit',
        },
      },
      {
        path: 'participations',
        component: UserParticipationsModalComponent,
      },
    ],
  },
];

const routes = [
  {
    path: 'all-users',
    component: AllUsersComponent,
    canActivate: [AuthGuard],
    resolve: {
      users: UsersResolver,
    },
    children: allUsersRoutes,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AllUsersRoutingModule {
}
