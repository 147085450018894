<div class="nav" *ngIf="isAuthenticated">
  <ffcrm-header></ffcrm-header>
</div>
<div class="content">
  <div class="wrapper">
    <ul>
      <li><img class="image" src="assets/images/404.png"></li>
      <li><p class="caption">Sorry page does not found</p></li>
      <li><button class='back' (click)="goBack()">Revert back</button></li>
    </ul>
  </div>
</div>
<router-outlet></router-outlet>
