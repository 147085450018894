import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';

// Guards
import { MonthYearGuard } from '../../shared/super/month-year-guard';

// Models
import { MonthYear } from '../models/month-year.model';

// Services
import { ProjectService } from '../services/project/project.service';
import { UserService } from '../../shared/services/user/user.service';


@Injectable({
  providedIn: 'root',
})
export class ProjectYearMonthGuard extends MonthYearGuard implements CanActivate {
  constructor(
    private projectService: ProjectService,
    userService: UserService,
    private activatedRoute: ActivatedRoute,
    router: Router,
  ) {
    super(router, userService);

    this.subscriptions.push(
      this.projectService.$monthes.subscribe((monthes: Array<MonthYear>) => {
        this.monthYearList = monthes;
      }),
    );
  }

  protected loadConditions(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this.slugId = this.cleanUrl(route.params?.projectSlug);
    this.additionalMonthesCondition = !route.queryParams['task-filter'];
    this.mainCondition = route.params?.projectSlug && !route.children.length && !route.params?.taskId && !route.params?.timeEntryId;
    this.conditionsDifferent = route.params?.projectSlug !== this.projectService.project?.slug;
  }

  protected async loadMonthes(slug: string, userMonthes: boolean): Promise<void> {
    if (!this.projectService.project || this.projectService.project?.slug !== slug) {
      await this.projectService.getMonthes(slug, userMonthes);
    }
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    this.loadConditions(route, state);
    return await this.canActivateCustom(route, state);
  }
}
