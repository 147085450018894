import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

// Models
import { ApiClient } from '../../../../shared/services/api-client.model';
import { Project } from '../../../../shared/models/project.model';
import { Paginate } from '../../../interfaces/paginate.model';

// Services
import { HttpClientFactoryService } from '../../../../shared/services/http-client-factory.service';


@Injectable({
  providedIn: 'root'
})
export class UsersProjectService {

  constructor(
    private httpFactory: HttpClientFactoryService,
  ) {
    this.http = this.httpFactory.getHttpClient('/projects/');
    this.adminHttp = this.httpFactory.getHttpClient('/projects/manage/');
    this.userHttp = this.httpFactory.getHttpClient('/users/');
  }

  private http: ApiClient;
  private userHttp: ApiClient;
  private adminHttp: ApiClient;
  private currentUserId: number;
  private allProjects: BehaviorSubject<Array<Project>> = new BehaviorSubject<Array<Project>>([]);
  private userProjects: BehaviorSubject<Array<Project>> = new BehaviorSubject<Array<Project>>([]);
  private leftPanelProjects: BehaviorSubject<Array<Project>> = new BehaviorSubject<Array<Project>>([]);

  get $projectsAll(): Observable<Array<Project>> {
    return this.allProjects.asObservable();
  }

  get projectsAll(): Array<Project> {
    return this.allProjects.value;
  }

  get $projectsUser(): Observable<Array<Project>> {
    return this.userProjects.asObservable();
  }

  get projectsUser(): Array<Project> {
    return this.userProjects.value;
  }

  get $leftPanelProjects(): Observable<Array<Project>> {
    return this.leftPanelProjects.asObservable();
  }

  get userId(): number {
    return this.currentUserId;
  }

  set userId(value: number) {
    if (value) {
      this.leftPanelProjects.next(this.userProjects.value);
    } else {
      this.leftPanelProjects.next(this.allProjects.value);
    }

    this.currentUserId = value;
  }

  public getAllProjecsAsync(): Observable<Array<Project>> {
    return this.http.get<Paginate<Project>>(``).pipe(
      map((projects: Paginate<Project>) => {
        return projects?.results;
      }),
      tap((projects: Array<Project>) => {
        this.allProjects.next(projects);
        this.leftPanelProjects.next(this.allProjects.value);
      }),
    );
  }

  public getProjectsForUserAsync(userId: number): Observable<Array<Project>> {
    return this.userHttp.get<Paginate<Project>>(`${userId}/projects/`).pipe(
      map((projects: Paginate<Project>) => {
        return projects?.results;
      }),
      tap((projects: Array<Project>) => {
        this.userProjects.next(projects || []);
        this.leftPanelProjects.next(this.userProjects.value);
      }),
    );
  }

  public async starProject(projectSlug): Promise<void> {
    try {
      await this.adminHttp.post(`${projectSlug}/star/`, null).toPromise();

      if (this.userId) {
        await this.getProjectsForUserAsync(this.userId).toPromise();
      } else {
        await this.getAllProjecsAsync().toPromise();
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async unStarProject(projectSlug): Promise<void> {
    try {
      await this.adminHttp.delete(`${projectSlug}/star/`).toPromise();

      if (this.userId) {
        await this.getProjectsForUserAsync(this.userId).toPromise();
      } else {
        await this.getAllProjecsAsync().toPromise();
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
