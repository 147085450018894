import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AlertBody} from '../shared/models/alert-body.model';
import {AlertService} from '../shared/services/alert.service';

@Component({
  selector: 'ffcrm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Timerilo';

  constructor(private alertService: AlertService) {
    this.$alert = this.alertService.$alert;
  }

  public $alert: Observable<AlertBody>;
  public alertIsShowing = false;

  public ngOnInit(): void {
    this.$alert.subscribe(() => {
      this.alertIsShowing = true;
      setTimeout(() => {
        this.alertIsShowing = false;
      }, 2000);
    });
  }
}
