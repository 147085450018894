<div class="main-wrapper">
  <div class="wrapper">
    <div class="head with-margin-pillow">
      <div class="left">
        <div class="name">
          <div class="arrow" routerLink="../" queryParamsHandling="preserve">
            <img src="/assets/icons/back.svg" alt="arrow">
          </div>
          <h1> {{ user?.firstName }} {{ user?.lastName }} </h1>
        </div>
        <div class="statistic">
          <div class="stat-item">
            <div class="text">Selected month</div>
            <div class="number">{{ tasks?.selectedMonth | hhmm }}</div>
          </div>
          <div class="stat-item">
            <div class="text">Previous month</div>
            <div class="number">{{ tasks?.previousMonth | hhmm }}</div>
          </div>
          <div class="stat-item">
            <div class="text">Overtime</div>
            <div class="number">{{ tasks?.overtimes | hhmm }}</div>
          </div>
          <div class="stat-item">
            <div class="text">Active projects</div>
            <div class="number">{{ tasks?.activeProjects }}</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="actions">
          <input type="button" class="filter-button filter-top icon-block" *ngIf="user?.isActive" (click)="openDialog('blockUserDialog')" />
          <input type="button" class="filter-button filter-top icon-block icon-button-red" *ngIf="!user?.isActive" (click)="openDialog('unblockUserDialog')" />
          <a class="icon-button button-project" routerLink="./participations" queryParamsHandling="preserve">
            <i class="icon icon-project icon-18px"></i>
          </a>
          <a class="icon-button" routerLink="./edit" queryParamsHandling="preserve">
            <i class="icon icon-edit icon-18px"></i>
          </a>
        </div>
        <div class="search">
          <div class="input-block">
            <i class="icon icon-search search-icon"></i>
            <input #search class="input-field input-search" type="text" placeholder="Search" (input)="filter()">
          </div>
        </div>
      </div>
    </div>
    <div class="info with-margin-pillow">
      <h2>Information</h2>
      <div class="actions filter-right">
        <input type="button" class="filter-button google" (click)="google()" />
        <input type="button" class="filter-button print" />

        <div class="filters-wrapper">
          <ffcrm-select *ngIf="initOption"
                        class="select"
                        [options]='selectOptions'
                        (selected)="selectMonthFilter($event)"
                        [value]="initOption"></ffcrm-select>
        </div>
      </div>
    </div>
    <p *ngIf="!tableData?.length" class="empty-placeholder">
      Emptiness and meteorites ☄️
    </p>
    <div class="table" *ngIf="tableData?.length">
      <ffcrm-table [data]="tableData" [cellTemplates]="tableSettings"></ffcrm-table>

      <ng-template #projectTemplate let-item>
        {{ item ? item.name : '-' }}
      </ng-template>

      <ng-template #taskTemplate let-item>
        {{ item ? item : '-' }}
      </ng-template>

      <ng-template #hoursTemplate let-item>
        {{ item ? (item | hhmm) : '-' }}
      </ng-template>

      <ng-template #overtimeTemplate let-item>
        {{ item ? (item | hhmm) : '-' }}
      </ng-template>

      <ng-template #datesOvertime let-items>
        <p *ngFor="let item of items">
          {{ item }}
        </p>
      </ng-template>

      <ng-template #commentTemplate let-item>
        {{ item ? item : '-' }}
      </ng-template>

      <ng-template #externalLinkTemplate let-item>
        <div class="link" *ngIf="item">
          <p>{{ item }}</p>
          <button [cdkCopyToClipboard]="item" [tabIndex]="($profile | async)?.isStaff && 1" (click)="$event.stopPropagation(); showTooltip($event)">
            <i class="icon icon-copy"></i>
          </button>
        </div>
        <p *ngIf="!item">-</p>
      </ng-template>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<ffcrm-accept
  [isVisible]="blockUserDialog"
  title="Block user"
  description="If you block the user, he will not be able to perform any actions"
  acceptBtnText="Block user"
  (cancel)="closeDialog('blockUserDialog')"
  (accept)="blockUser()"
></ffcrm-accept>

<ffcrm-accept
  [isVisible]="unblockUserDialog"
  title="Unblock user"
  description="Are you sure?"
  acceptBtnText="Unblock user"
  (cancel)="closeDialog('unblockUserDialog')"
  (accept)="unblockUser()"
></ffcrm-accept>

<ffcrm-accept
  [isVisible]="isConfirmGoogleSheetOpening"
  description="Do you want to open the document in google sheet?"
  type="sheet"
  [link]="spreadSheetLink"
  (accept)="isConfirmGoogleSheetOpening = false"
  (cancel)="isConfirmGoogleSheetOpening = false"
></ffcrm-accept>

<ffcrm-tooltip></ffcrm-tooltip>
