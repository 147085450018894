<div class="wrapper">
  <router-outlet></router-outlet>
</div>
<ffcrm-dialog [(visible)]="alertIsShowing" [type]="'info'">
  <div class="dialog-body-info">
    <i class="fas fa-check success" *ngIf="($alert | async)?.success"></i>
    <i class="fas fa-times error" *ngIf="!($alert | async)?.success"></i>
    <h1 *ngIf="($alert | async)?.success">Success 🥳</h1>
    <h1 *ngIf="!($alert | async)?.success">Oops... 😨</h1>
    <p>{{ ($alert | async)?.message }}</p>
  </div>
</ffcrm-dialog>
