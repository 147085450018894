import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';

// Components
import { SubscriptionsComponent } from '../../../shared/super/subscriptions-component';

// Interfaces
import { Profile } from '../../../shared/models/profile.model';
import { Summary } from '../../interfaces/summary.model';
import { SummaryAdmin } from '../../interfaces/summary-admin.model';
import { Project } from '../../../shared/models/project.model';
import { ManageProject } from '../../../shared/models/manage-project.model';
import { UserProfile } from './../../../shared/models/user-profile.model';

// Services
import { FloatingHelpService } from '../../../shared/services/floating-help.service';
import { SummaryService } from '../../services/summary/summary.service';
import { ProjectService } from '../../services/project/project.service';
import { UserService } from '../../../shared/services/user/user.service';
import { OvertimeService } from '../../../shared/services/overtime.service';

@Component({
  selector: 'ffcrm-overtime-left',
  templateUrl: './overtime-left.component.html',
  styleUrls: ['./overtime-left.component.scss']
})
export class OvertimeLeftComponent extends SubscriptionsComponent implements OnInit, OnDestroy {

  @ViewChild('search') search: ElementRef;

  constructor(
    private projectService: ProjectService,
    private userService: UserService,
    private summaryService: SummaryService,
    private overtimeService: OvertimeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private floatingHelpService: FloatingHelpService,
  ) {
    super();

    this.$projects = this.projectService.$manageProjects;
    this.$userSidebar = this.userService.$userSidebar;
    this.$summary = this.summaryService.$summary;
  }

  public readonly $projects: Observable<Array<Project | ManageProject>>;
  public readonly $userSidebar: Observable<boolean>;
  public readonly $summary: Observable<SummaryAdmin | Summary>;

  public projects: Array<Project | ManageProject> = [];
  public coldProjects: Array<Project | ManageProject> = [];

  public users: Array<UserProfile> = [];
  public coldUsers: Array<UserProfile> = [];

  private isActive: boolean | string = 'all';

  get profile(): Profile {
    return this.userService.profileSubj.value;
  }

  public readonly selectOptions = [{label: 'Active', id: 1}, {label: 'Inactive', id: 2}, {label: 'All', id: 3}];

  public async ngOnInit(): Promise<void> {
      this.subscriptions.push(
        this.$projects.subscribe((response: Array<Project | ManageProject>) => {
          this.coldProjects = response;
          this.projects = response;
          if (this.search?.nativeElement?.value) {
            this.filterItems({value: this.search?.nativeElement?.value});
          }
        }),

        this.userService.$users.subscribe((response: Array<UserProfile>) => {
          this.coldUsers = response;
          if (!this.search?.nativeElement?.value) {
            this.users = response;
          }
        }),
      );
  }

  public filterItems({ value }): void {
    if (this.profile?.isStaff) {
      this.users = this.coldUsers.filter(({ firstName, lastName, isActive }: UserProfile) => {
        return (firstName + ' ' + lastName)?.toLowerCase().indexOf(value.toLowerCase()) !== -1 &&
          (this.isActive === 'all' || isActive === this.isActive);
      });
    } else {
      this.projects = this.coldProjects.filter(({ name }: Project | ManageProject) => {
        return name?.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });
    }
  }

  public filterUsersByActivity(id: number): void {
    if (id === 3) {
      this.users = this.coldUsers;
      this.isActive = 'all';
      return;
    }

    this.isActive = id === 1 ? true : false;
    this.users = this.coldUsers.filter((user: UserProfile) => user.isActive === this.isActive);
  }

  public async toggleProjectStar(project): Promise<void> {
    try {
      if (project.isStarred) {
        await this.projectService.unStarProject(project.slug);
      } else {
        await this.projectService.starProject(project.slug);
      }
    } catch (e) {
      console.error(e);
    }
  }

  public chooseProject(project: string): void {
    this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        project
      },
      queryParamsHandling: 'merge',
    });
  }

  public async chooseUser(user: string): Promise<void> {
    if (user !== 'all') {
      await this.overtimeService.getMonthes('months/', { user });
    } else {
      await this.overtimeService.getMonthes('months/all/');
    }

    this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        user
      },
      queryParamsHandling: 'merge',
    });
  }

  public isSelected(slug: string): boolean {
    if (slug === 'all') {
      if (!this.profile.isStaff) {
        return !this.activatedRoute.snapshot?.queryParams?.project ||
          this.activatedRoute.snapshot?.queryParams?.project === 'all';
      } else {
        return !this.activatedRoute.snapshot?.queryParams?.user ||
          this.activatedRoute.snapshot?.queryParams?.user === 'all';
      }
    }

    return !this.profile.isStaff ? (
        this.activatedRoute.snapshot?.queryParams?.project === slug
      ) : (
        this.activatedRoute.snapshot?.queryParams?.user === slug
      );
  }

  public showHelp(newText: string): void {
    if (!this.userService.userSidebar) {
      this.floatingHelpService.setHelpState(true);
      this.floatingHelpService.setHelpText(newText);
    }
  }

  public closeHelp(): void {
    this.floatingHelpService.setHelpState(false);
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
