import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { slugify, transliterate as tr } from 'transliteration';
import { Observable } from 'rxjs';

// Components
import { FormComponent } from '../../../shared/super/form-component';

// Models
import { ManageProject } from '../../../shared/models/manage-project.model';
import { UserProfile } from '../../../shared/models/user-profile.model';
import { ProjectError } from '../../models/project-errors.model';
import { UserCheckbox } from '../../models/user-checkbox.model';
import { Group } from '../../../shared/models/group.model';

// Pipes
import { validateAllFields } from '../../../shared/pipes/validate-all-fields.pipe';

// Services
import { ProjectService } from 'src/app/main/services/project/project.service';
import { GroupService } from 'src/app/main/services/group/group.service';
import { UserService } from '../../../shared/services/user/user.service';
import { TaskService } from '../../services/task/task.service';
import { AlertService } from '../../../shared/services/alert.service';

// Utils
import imageResolving from '../../../shared/utils/resolve-upload-file';

@Component({
  selector: 'ffcrm-project-modal',
  templateUrl: './project-modal.component.html',
  styleUrls: ['./project-modal.component.scss']
})
export class ProjectModalComponent extends FormComponent implements OnInit, OnDestroy {

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private groupService: GroupService,
    private projectService: ProjectService,
    private taskService: TaskService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    super();

    this.$users = this.userService.$users;
    this.$groups = this.groupService.$groups;
    this.$currentManageProject = this.projectService.$project as Observable<ManageProject>;
  }

  private projectSlug: string;
  private groupSlug: string;

  @ViewChild('search') public search;
  @ViewChild('photoInput') public photoInput;

  public isEdit = false;
  public title: string;

  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    slug: new FormControl('', [Validators.required]),
    group: new FormControl('', [Validators.required]),
    estimation: new FormControl(''),
    overtime: new FormControl(1.0, [Validators.required]),
    isBillable: new FormControl(false),
    isActive: new FormControl(false),
    isReadonly: new FormControl(false),
    members: new FormArray([])
  });

  public image: string;
  public croppedImage;
  public imageCropper: boolean;
  private uploadImage: boolean;

  public $users: Observable<UserProfile[]>;
  public filteredUsers = [];
  public selectedUsers = [];

  public $groups: Observable<Array<Group>>;
  public $currentManageProject: Observable<ManageProject>;
  public project: ManageProject;
  public backendErrors: ProjectError;
  public activeGroups: Array<Group>;
  public currentGroup: Group;

  get users(): Array<UserProfile> {
    return this.userService.usersSubj.value;
  }

  get groups(): Array<Group> {
    return this.groupService.groupsSubject.value;
  }

  public ngOnInit(): void {
    this.buildForm();

    this.subscriptions.push(
      this.activatedRoute?.parent?.params.subscribe(async (params: Params) => {
        this.groupSlug = params?.groupSlug;
        this.projectSlug = params?.projectSlug;
      })
    );

    this.subscriptions.push(
      this.$currentManageProject.subscribe(async (project: ManageProject) => {
        if (this?.projectSlug) {
          this.project = project;
          this.image = this.project?.image;
        }
        this.buildForm();
      })
    );
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public async dialogVisibleChange(): Promise<void> {
    if (window.location.href.includes(this.project?.slug)) { // TODO: need refactor this
      await this.router.navigate(['../'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
      });
    } else {
      await this.router.navigate(['../../'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
      });
    }
  }

  public canDeactivate(): boolean | Observable<boolean> {
    if (this.form.touched) {
      return confirm('Seems you have some half-edited form left. Proceed anyway?');
    }
    else {
      return true;
    }
  }

  private filterGroups(): void {
    if (this.groups?.length) {
      this.activeGroups = this.groups.filter((group) => group.isActive === true);
      this.currentGroup = this.groups.find((group) => group.slug === this.groupSlug);
    }

    if (this.activeGroups && this.currentGroup?.isActive !== true) {
      this.activeGroups.push(this.currentGroup);
    }
  }

  public async imageSelect(event): Promise<void> {
    const file: File = event.target.files[0];
    const reader = new FileReader();

    imageResolving(file, () => {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.croppedImage = reader.result;
        this.imageCropper = true;
      };
    }, async (message) => {
      await this.alertService.showAlert({
        success: false,
        message: message as string,
      });
    });

    this.photoInput.nativeElement.value = '';
  }

  public getCroppedImage(image: string): void {
    this.image = image;
    this.uploadImage = true;
  }

  public clearBackErrors(): void {
    this.backendErrors = null;
  }

  public async update(): Promise<void> {
    if (this.form.valid) {
      await this.updateProject();
    } else {
      validateAllFields(this.form);
    }
  }

  public async createAndNew(): Promise<void> {
    if (this.form.valid) {
      await this.createProject(false);
    } else {
      validateAllFields(this.form);
    }
  }

  public async createAndClose(): Promise<void> {
    if (this.form.valid) {
      await this.createProject(true);
    } else {
      validateAllFields(this.form);
    }
  }

  public async createProject(closeDialog: boolean): Promise<void> {
    const project = this.form.value;

    if (project.estimation) {
      project.estimation = Math.floor(project.estimation * 60);
    }

    if (this.uploadImage) {
      project.image = this.image;
    }

    project.members = this.selectedUsers;

    try {
      await this.projectService.createProject(project);
      this.image = '';
      await this.alertService.showAlert({
        success: true,
        message: 'Project has been successfully created',
      });

      if (closeDialog) {
        this.buildForm();
        await this.dialogVisibleChange();
      } else {
        this.buildForm();
        this.selectedUsers = [];
      }
    } catch (e) {
      await this.getErrorsFromResponse(e.error);
    }
  }

  private async updateProject(): Promise<void> {
    const project = this.form.value;

    if (this.uploadImage) {
      project.image = this.image;
    }

    if (project.estimation) {
      project.estimation = Math.floor(project.estimation * 60);
    }

    project.members = this.selectedUsers;

    try {
      const newSlug = this.form.value?.slug;
      await this.projectService.updateProject(this.project?.slug, project);
      this.image = '';

      await this.taskService.getTasks(this.project?.slug);

      await this.alertService.showAlert({
        success: true,
        message: 'Project has been successfully updated',
      });

      this.buildForm();

      if (this.projectSlug !== newSlug) {
        await this.router.navigate([`home/groups/${this.groupSlug}/projects/${newSlug}`], {
          queryParamsHandling: 'merge',
        });
      } else {
        await this.dialogVisibleChange();
      }
    } catch (e) {
      await this.getErrorsFromResponse(e.error);
      await this.alertService.showAlert({
        success: false,
        message: `${e.message}`,
      });
    }
  }

  public createSlug(): void {
    this.clearBackErrors();
    this.form.get('slug').setValue(slugify(tr(this.form.get('name').value)));
  }

  public membersCheckboxChange(event): void {
    const checked = event.target.checked;
    const members = this.form.value.members.map(member => {
      if (member.id.toString() === event.target.value) {
        member.checked = checked;
      }
      return member;
    });
    this.selectedUsers = this.getSelectedUsers();
    this.form.patchValue({ members });
  }

  public buildForm(): void {
    this.form = this.fb.group({
      name: new FormControl(this.project?.name || '', [Validators.required]),
      slug: new FormControl(this.project?.slug || '', [Validators.required]),
      estimation: new FormControl(+((this.project?.estimation || 0) / 60).toFixed(2) || ''),
      overtimesRate: new FormControl(this.project?.overtimesRate || 1.0, [Validators.required]),
      group: new FormControl(this.project?.group?.slug || this.groupSlug || '', [Validators.required]),
      isBillable: new FormControl(!!this.project?.isBillable),
      isActive: new FormControl(this.project?.isActive ?? true),
      isReadonly: new FormControl(!!this.project?.isReadonly),
      members: this.buildUsersControl(),
    });
    this.selectedUsers = this.getSelectedUsers();
    this.filteredUsers = this.form?.value?.members;
    this.filterGroups();
  }

  public buildUsersControl(): FormArray {
    const arr = this.users?.map(user => {
      const userCheckbox = new UserCheckbox(user);
      if (this.project && this.project?.members?.some(member => member?.id === userCheckbox?.id)) {
        userCheckbox.checked = true;
      }
      return this.fb.control(userCheckbox);
    });
    return arr ? this.fb?.array(arr) : new FormArray([]);
  }

  public getSelectedUsers(): Array<UserCheckbox> {
    return this.form?.value?.members.filter(member => {
      return member?.checked;
    });
  }

  public filterUsers(): void {
    let keyword = '';
    if (this.search) {
      keyword = this.search?.nativeElement?.value?.toLowerCase();
    }
    this.filteredUsers = this.form?.value?.members?.filter(user =>
      (user?.firstName?.toLowerCase() + ' ' + user?.lastName?.toLowerCase()).includes(keyword)
    );
  }
}
