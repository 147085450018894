<div class="container">
  <div class="row">
    <div class="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">
      <div class="card">
        <h1>Login</h1>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" id="login-form">
          <div class="input-block">
            <label for="email">Email address</label>
            <input type="text" class="input-field" id="email" formControlName="email" placeholder="Enter your email address"
                   [class.error]="loginForm.get('email').invalid && (loginForm.get('email').dirty || loginForm.get('email').touched)">

            <div class="error-msg" [class.visible]="loginForm.get('email')?.invalid && (loginForm.get('email')?.touched || loginForm.get('email')?.dirty)">
              <div *ngFor="let error of getErrors(loginForm.get('email'))">{{ error }}</div>
            </div>
          </div>

          <div class="input-block">
            <label for="password">Password</label>
            <input type="password" class="input-field" id="password" formControlName="password" placeholder="Enter your password"
                   [class.error]="loginForm.get('password').invalid && loginForm.get('password').touched">

            <div class="error-msg" [class.visible]="loginForm.get('password')?.invalid && (loginForm.get('password')?.touched || loginForm.get('password')?.dirty)">
              <div *ngFor="let error of getErrors(loginForm.get('password'))">{{ error }}</div>
            </div>
          </div>

          <input type="submit" class="btn btn-block" value="Login" [disabled]="loginForm.invalid">

          <div class="flex-center">
            <a routerLink="/forgot-password"> Forgot password?</a>
          </div>

          <div class="error-msg non-field-error" [class.visible]="loginForm?.invalid && (loginForm?.touched || loginForm?.dirty)">
            <div *ngFor="let error of getErrors(loginForm)">{{ error }}</div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
