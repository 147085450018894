import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { AngularCropperjsModule } from 'angular-cropperjs';

// Components
import { FloatingHelpComponent } from './components/floating-help/floating-help.component';
import { ProfileModalComponent } from './components/profile-modal/profile-modal.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { TimepickerComponent } from './components/timepicker/timepicker.component';
import { EmptyListComponent } from './components/empty-list/empty-list.component';
import { SelectComponent } from './components/select/select.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { ImageComponent } from './components/image/image.component';
import { TableComponent } from './components/table/table.component';
import { CardComponent } from './components/card/card.component';
import { AcceptComponent } from './components/accept-delete/accept.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { LogoComponent } from './components/logo/logo.component';

// Directives
import { NgVarDirective } from './directives/ng-var.directive';

// Pipes
import { InactiveProjectGroupPipe } from './pipes/inactive-project-group.pipe';
import { ReadonlyProjectGroupPipe } from './pipes/readonly-project-group.pipe';
import { StarredProjectGroupPipe } from './pipes/starred-project-group.pipe';
import { OtherProjectGroupPipe } from './pipes/other-project-group.pipe';
import { HoursPipe } from './pipes/hours.pipe';
import { HhmmPipe } from './pipes/hhmm.pipe';
import { HighlightNewRowPipe } from './pipes/highlight-new-row.pipe';
import { FullnamePipe } from './pipes/fullname.pipe';

@NgModule({
  declarations: [
    SelectComponent,
    DialogComponent,
    InactiveProjectGroupPipe,
    ReadonlyProjectGroupPipe,
    StarredProjectGroupPipe,
    OtherProjectGroupPipe,
    ImageCropperComponent,
    ImageComponent,
    TableComponent,
    AutocompleteComponent,
    TimepickerComponent,
    DatepickerComponent,
    ConfirmationComponent,
    CardComponent,
    HoursPipe,
    LogoComponent,
    FloatingHelpComponent,
    NgVarDirective,
    HhmmPipe,
    EmptyListComponent,
    ProfileModalComponent,
    AcceptComponent,
    TooltipComponent,
    HighlightNewRowPipe,
    FullnamePipe,
  ],
  exports: [
    SelectComponent,
    DialogComponent,
    InactiveProjectGroupPipe,
    ReadonlyProjectGroupPipe,
    OtherProjectGroupPipe,
    StarredProjectGroupPipe,
    ImageCropperComponent,
    ImageComponent,
    TableComponent,
    AutocompleteComponent,
    TimepickerComponent,
    DatepickerComponent,
    ConfirmationComponent,
    CardComponent,
    HoursPipe,
    LogoComponent,
    FloatingHelpComponent,
    NgVarDirective,
    HhmmPipe,
    FullnamePipe,
    EmptyListComponent,
    ProfileModalComponent,
    AcceptComponent,
    TooltipComponent,
    HighlightNewRowPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularCropperjsModule,
    NgbDatepickerModule,
  ],
  providers: []
})

export class SharedModule {
}
