<div class="content" [class.squoshed]="($userSidebar | async) === false">
  <div class="left-col-wraper">
    <div class="left-col full-height">
      <ffcrm-all-users-left></ffcrm-all-users-left>
    </div>
  </div>
  <div class="dashboard-wrapper">
    <div class="dashboard">
      <div class="wrapper full-height">
        <div class="right-col full-height">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
