import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Profile } from '../../shared/models/profile.model';
import { Project } from '../../shared/models/project.model';
import { Group } from '../../shared/models/group.model';

import { UserService } from '../../shared/services/user/user.service';
import { GroupService } from '../services/group/group.service';
import { ProjectService } from '../services/project/project.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private groupService: GroupService,
    private projectService: ProjectService,
    private router: Router,
  ) { }

  get profile(): Profile {
    return this.userService.profileSubj.value;
  }

  public async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      let profile = this.profile;

      if (!profile) {
        profile = await this.userService.getProfile();
      }

      if (profile?.isStaff) {
        const groups = await this.groupService.getGroups();
        const starredGroups = groups.filter((group: Group) => group.isStarred);

        if (starredGroups.length) {
          await this.router.navigate([`home/groups/${starredGroups[0]?.slug}`], { queryParamsHandling: 'preserve' });
        } else if (groups.length) {
          await this.router.navigate([`home/groups/${groups[0]?.slug}`], { queryParamsHandling: 'preserve' });
        } else {
          await this.router.navigate([`home/groups`], { queryParamsHandling: 'preserve' });
        }
      } else {
        const projects = await this.projectService.getUsersProjects();
        const starredProjects = projects.filter((project: Project) => project?.isStarred);

        if (starredProjects.length) {
          await this.router.navigate([`home/projects/${starredProjects[0]?.slug}`], { queryParamsHandling: 'preserve' });
        } else if (projects.length) {
          await this.router.navigate([`home/projects/${projects[0]?.slug}`], { queryParamsHandling: 'preserve' });
        } else {
          await this.router.navigate([`home/projects`], { queryParamsHandling: 'preserve' });
        }
      }

      return false;
    }

}
