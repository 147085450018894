import { Pipe, PipeTransform } from '@angular/core';
import { percentForMinutes } from '../utils/minutes-to-percent';

@Pipe({
  name: 'hours'
})
export class HoursPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string | number {
    if (value) {
      let minutes: string | number = Math.floor(value) % 60;
      const hours = Math.floor((Math.floor(value) - minutes) / 60);
      minutes = percentForMinutes(minutes);
      return `${hours}.${minutes}`;
    } else {
      return 0;
    }
  }

}
