import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {Project} from '../../models/project.model';
import {GroupService} from '../../../main/services/group/group.service';
import {Group} from '../../models/group.model';
import {UserService} from '../../services/user/user.service';
import {UserProfile} from '../../models/user-profile.model';
import { GroupProject } from '../../models/group-project.model';
import { ManageProject } from '../../models/manage-project.model';

@Component({
  selector: 'ffcrm-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  $group: Observable<Group>;
  $users: Observable<UserProfile[]>;

  user: UserProfile;
  project: Project | GroupProject;
  projectMembersPhotos: string[];
  constructor(
    private groupService: GroupService,
    private userService: UserService
  ) {
    this.$group = groupService.$currentGroup;
    this.$users = userService.$users;
  }

  @Input() type: string;
  @Input() marked: boolean;
  @Input() markText: string;
  @Input() instanceId: number;
  @Input() additionalClasses: string;
  @Input() instance: UserProfile | Project | ManageProject;
  @Input() actions: boolean;
  @Input() isAdmin: boolean;
  @Input() isActive: boolean;

  @Output() starProjectEvent = new EventEmitter();
  @Output() unStarProjectEvent = new EventEmitter();
  @Output() addUsersEvent = new EventEmitter();

  public ngOnInit(): void {
    if (this.isProject) {
      this.instance ? this.setProject() : this.loadProject();
    } else if (this.isUser) {
      this.instance ? this.user = this.instance as UserProfile : this.loadUser();
    }
  }

  public async loadProject(): Promise<void> {
    this.$group.subscribe(group => {
      this.project = group?.projects?.filter(project => project.id === this.instanceId)[0];
      this.projectMembersPhotos = this.project?.totalMembers.photos.filter(photo => photo !== 'https://dev.timerilo.com/media/');
    });
  }

  public async loadUser(): Promise<void> {
    this.$users.subscribe(users => {
      this.user = users.filter(user => user.id === this.instanceId)[0];
    });
  }

  public setProject(): void {
    this.project = this.instance as Project;
    this.projectMembersPhotos = this.project.totalMembers.photos.filter(photo => photo !== 'https://dev.timerilo.com/media/');
  }

  public addUsersModal(): void {
    this.addUsersEvent.emit(this.project.slug);
  }

  public starProject(): void {
    this.starProjectEvent.emit(this.project);
  }

  public unStarProject(): void {
    this.unStarProjectEvent.emit(this.project);
  }

  public get isProject(): boolean {
    return this.type === 'project';
  }

  public get isUser(): boolean {
    return this.type === 'user';
  }
}
