import { Pipe, PipeTransform } from '@angular/core';
import { Profile } from '../models/profile.model';

@Pipe({
  name: 'fullname'
})
export class FullnamePipe implements PipeTransform {

  transform(value: Array<Profile | any>, ...args: unknown[]): unknown {
    value?.forEach((profile: Profile | any) => {
      profile.fullName = profile.firstName + ' ' + profile.lastName;
    });

    return value;
  }
}
