<ffcrm-dialog visible="true" title="Groups/Projects" (visibleChange)="onStateChange()">
  <div class="dialog-body groups-modal">
    <div class="groups">
      <h1>Groups</h1>
      <div class="items">
        <div class="group selected" *ngFor="let group of groups" (click)="selectGroup(group)" [class.selected]="this.selectedGroup == group?.slug">
          <div class="image">
            <ffcrm-image [object]="group" border="5px"></ffcrm-image>
          </div>
          <h3>{{ group.name }}</h3>
        </div>
      </div>
    </div>
    <div class="projects">
      <h1>Projects</h1>
      <div class="items">
        <div class="project-cards" *ngIf="projects?.length">
          <ffcrm-card *ngFor="let project of projects | other" [instance]="project" type="project"></ffcrm-card>
        </div>

        <p class="project-type-header" *ngIf="(projects | readonly)?.length > 0">READONLY</p>
        <div class="project-cards" *ngIf="(projects | readonly)?.length">
          <ffcrm-card *ngFor="let project of projects | readonly" [instance]="project" type="project"></ffcrm-card>
        </div>

        <p class="project-type-header" *ngIf="(projects | inactive)?.length > 0">INACTIVE</p>
        <div class="project-cards" *ngIf="(projects | inactive)?.length">
          <ffcrm-card *ngFor="let project of projects | inactive" [instance]="project" type="project"></ffcrm-card>
        </div>
      </div>
    </div>
  </div>
</ffcrm-dialog>
