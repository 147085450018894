import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ffcrm-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {

  constructor() { }

  @Input() public confirmationButtonText: string;
  @Input() public dialogTitle: string;
  @Input() public dialogText: string;
  @Input() public confirmDialog: boolean;
  @Output() public confirmDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public confirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public confirm(): void {
    this.confirmed.emit(true);
    this.confirmDialogChange.emit(false);
  }

  public decline(): void {
    this.confirmed.emit(false);
    this.confirmDialogChange.emit(false);
  }

  public close(): void {
    this.confirmDialogChange.emit(false);
  }
}
