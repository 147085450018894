import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// Models
import { UserTasksList } from '../../interfaces/user-tasks-list.model';
import { ApiClient } from '../../../shared/services/api-client.model';
import { UserTask } from '../../interfaces/user-task.model';
import { Paginate } from '../../interfaces/paginate.model';
import { MonthYear } from '../../models/month-year.model';

// Services
import { HttpClientFactoryService } from '../../../shared/services/http-client-factory.service';
import { IMonthYearFilterService } from '../injection-tokens/month-year-filter-token';
import { UserService } from '../../../shared/services/user/user.service';


@Injectable({
  providedIn: 'root'
})
export class UserTaskService implements IMonthYearFilterService {

  constructor(
    private userService: UserService,
    private httpFactory: HttpClientFactoryService,
    private activatedRouter: ActivatedRoute,
    private router: Router) {
    this.http = httpFactory.getHttpClient('/');
  }
  private readonly http: ApiClient;
  private userTaskListSubject: BehaviorSubject<UserTasksList> = new BehaviorSubject<UserTasksList>(null);
  private userTaskMonthYearList: BehaviorSubject<Array<MonthYear>> = new BehaviorSubject<Array<MonthYear>>([]);
  private monthYearUser = 0;

  get $tasksList(): Observable<UserTasksList> {
    return this.userTaskListSubject.asObservable();
  }

  get tasksList(): UserTasksList {
    return this.userTaskListSubject.value;
  }

  get $tasks(): Observable<Array<UserTask>> {
    return this.userTaskListSubject.asObservable().pipe(map((userTasks: UserTasksList) => userTasks?.tasks));
  }

  get tasks(): Array<UserTask> {
    return this.userTaskListSubject.value?.tasks;
  }

  get currentUser(): number {
    return this.monthYearUser;
  }

  get monthes(): Array<MonthYear> {
    return this.userTaskMonthYearList.value;
  }

  get $monthes(): Observable<Array<MonthYear>> {
    return this.userTaskMonthYearList.asObservable();
  }

  private getQueriesObject(queries: Params): HttpParams {
    let params = new HttpParams();

    if (queries?.year) {
      params = params.set('year', queries?.year);
    }

    if (queries?.month) {
      params = params.set('month', queries?.month);
    }

    if (queries?.project && queries?.project !== 'all') {
      params = params.set('project', queries?.project);
    }

    return params;
  }

  public getUserTasksAsync(userId: number, queries?: Params): Observable<UserTasksList> {
    const params = this.getQueriesObject(queries || this.activatedRouter.snapshot.queryParams);

    return this.http.get<UserTasksList>(`users/${userId}/tasks/`, params)
      .pipe(tap((tasksList: UserTasksList) => this.userTaskListSubject.next(tasksList)));
  }

  public async getUserTasks(userId: null, queries?: Params): Promise<UserTasksList> {
    return await this.getUserTasksAsync(userId, queries).toPromise();
  }

  public async getUserMonthYearList(userid: number): Promise<Array<MonthYear>> {
    this.monthYearUser = userid;
    return await this.http.get<Paginate<MonthYear>>(`users/${userid}/month/`)
      .pipe(
        map((paginatedMonthYear: Paginate<MonthYear>) => paginatedMonthYear?.results),
        tap((monthYearList: Array<MonthYear>) => this.userTaskMonthYearList.next(monthYearList))
      ).toPromise();
  }
}
