<div class="card {{additionalClasses}}">
  <div class="card-mark" *ngIf="marked">
    <p class="card-mark-text">{{ markText }}</p>
  </div>
  <div class="card-head">
    <img class="role-img" *ngIf="isActive !== undefined" [src]="isAdmin ? '../../../../assets/icons/crown.svg'
      : isActive ? '../../../../assets/icons/people.svg'
      : '../../../../assets/icons/block-red.svg'"
      [alt]="isAdmin ? 'admin'
      : isActive ? 'active'
      : 'blocked'"
    />
    <div class="card-head-image">
      <ffcrm-image *ngIf="isProject" [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
      <ffcrm-image *ngIf="isUser" [object]="user" srcKey="photo" [labelKeys]="['firstName', 'lastName']" [border]="'5px'"></ffcrm-image>
    </div>
    <div class="card-head-content">
      <p class="name" *ngIf="isProject">{{ project?.name }}</p>

      <ng-container *ngIf="isUser">
        <div class="info-wrapper">
          <p class="name">{{ user?.firstName }} {{user?.lastName}}</p>
          <p class="user-title text-crop" [title]="user?.jobTitle">{{ user?.jobTitle }}</p>
        </div>
        <a class="user-email" (click)="$event.stopPropagation()" href="mailto:{{ user?.email }}">{{ user?.email }}</a>
      </ng-container>

      <div class="icons" *ngIf="isProject">
        <div class="users-members" (click)="$event.stopPropagation(); addUsersModal();">
          <div class="users-members-circle" *ngFor="let item of projectMembersPhotos; index as i"
               [style.transform]="'translate(' + i * 50 + '%, -50%)'">
            <img src="{{ item }}" alt="">
          </div>
          <div class="users-members-circle users-members-total"
               [style.transform]="'translate(' + projectMembersPhotos?.length * 50 + '%, -50%)'">
            {{ project?.totalMembers?.total }}
          </div>
        </div>
        <div class="info" *ngIf="actions" (click)="$event.stopPropagation()">
          <div class="billable">
            <div class="icon icon-money" *ngIf="project?.isBillable"></div>
            <div class="icon icon-money-off" *ngIf="!project?.isBillable"></div>
          </div>
          <div class="star" *ngIf="!project?.isStarred && !project?.isReadonly && project?.isActive"
               (click)="starProject()">
            <i class="icon icon-star-gray"></i>
          </div>
          <div class="star" *ngIf="project?.isStarred && !project?.isReadonly && project?.isActive"
               (click)="unStarProject()">
            <i class="icon icon-star"></i>
          </div>
        </div>

      </div>
    </div>

  </div>

  <div class="card-statistic">
    <ng-container *ngIf="isProject">
      <p><span class="left">Current month</span> <span class="right">{{ project?.currentMonthTime | hhmm }}</span></p>
      <p><span class="left">Total time</span> <span class="right">{{ project?.totalTime | hhmm }}</span></p>
      <p><span class="left">Estimation</span> <span class="right">{{ project?.estimation | hhmm }}</span></p>
    </ng-container>
    <ng-container *ngIf="isUser">
      <p><span class="left">Current month</span> <span class="right">{{ user?.currentMonthMinutes | hhmm }}</span></p>
      <p><span class="left">Previous month</span> <span class="right">{{ user?.previousMonthMinutes | hhmm }}</span></p>
      <p><span class="left">Overtime</span> <span class="right">{{ user?.currentMonthOvertimes | hhmm }}</span></p>
      <p><span class="left">Projects</span> <span class="right">{{ user?.activeProjects }}</span></p>
    </ng-container>
  </div>
</div>
