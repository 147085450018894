<div *ngIf="isVisible" [@dialog] class="overlay" (click)="close('overlay')"></div>

<div [@dialog]="isVisible"
     (@dialog.start)="$event.element.style.display = 'block'"
     (@dialog.done)="$event.element.style.display = ($event.toState ? 'block' : 'none')"
     *ngIf="isVisible" class="dialog" [class.create-photo]="isCreatePhoto">
  <div class="head" *ngIf="type !== 'info'">
    <button *ngIf="backButton"
            [class.inactive]="!backButtonEnabled"
            [disabled]="!backButtonEnabled"
            class="dialog-back-btn"
            (click)="backButtonClick()"></button>
    <h1 *ngIf="!dropdownChoices" [class.padded]="backButton">{{ title }}<span *ngIf="userTitle"> ({{ userTitle }})</span></h1>
    <ffcrm-select
      classList="select dialog-select"
      *ngIf="dropdownChoices"
      [idKey]="dropdownIdKey"
      [labelKey]="dropdownLabelKey"
      [value]="title"
      [options]="dropdownChoices"
      (selected)="itemSelected($event)">
    </ffcrm-select>
    <button
      (click)="close()"
      aria-label="Close"
      class="btn-close">
    </button>
  </div>
  <ng-content></ng-content>
</div>
