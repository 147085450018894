import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';

// Interfaces
import { Paginate } from '../interfaces/paginate.model';
import { OvertimeRecord } from '../interfaces/overtime.model';

// Services
import { OvertimeService } from '../../shared/services/overtime.service';

@Injectable({
  providedIn: 'root'
})
export class OvertimeResolver implements Resolve<Observable<Paginate<OvertimeRecord> | boolean>> {

  constructor(
    private overtimeService: OvertimeService,
    private router: Router,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Paginate<OvertimeRecord> | boolean> {
    return this.overtimeService.getOvertimes(route.queryParams).pipe(catchError((err) => this.router.navigateByUrl('/')));
  }
}
