import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// Models
import { ManageProject } from '../../../shared/models/manage-project.model';
import { UserProfile } from '../../../shared/models/user-profile.model';
import { ApiClient } from 'src/app/shared/services/api-client.model';
import { Project } from '../../../shared/models/project.model';
import { Profile } from '../../../shared/models/profile.model';
import { Paginate } from '../../interfaces/paginate.model';
import { MonthYear } from '../../models/month-year.model';

// Services
import { HttpClientFactoryService } from '../../../shared/services/http-client-factory.service';
import { IMonthYearFilterService } from '../injection-tokens/month-year-filter-token';
import { IDragNDropService } from '../injection-tokens/drag-n-drop-token';
import { GroupService } from '../group/group.service';
import { UserService } from '../../../shared/services/user/user.service';
import { getDate } from '../../../shared/utils/getDate';


@Injectable({
  providedIn: 'root'
})
export class ProjectService implements IDragNDropService, IMonthYearFilterService {

  constructor(
    private httpFactory: HttpClientFactoryService,
    private userService: UserService,
    private groupService: GroupService,
    private router: Router,
  ) {
    this.userHttp = this.httpFactory.getHttpClient('/projects/');
    this.manageHttp = this.httpFactory.getHttpClient('/projects/manage/');
  }

  private readonly userHttp: ApiClient;
  private readonly manageHttp: ApiClient;
  public projectsSubject: BehaviorSubject<Array<Project>> = new BehaviorSubject<Array<Project>>([]);
  public currentManageProjectSubject: BehaviorSubject<ManageProject> = new BehaviorSubject<ManageProject>(null);
  public currentProjectSubject: BehaviorSubject<Project> = new BehaviorSubject<Project>(null);
  public currentProjectMonthesSubject: BehaviorSubject<Array<MonthYear>> = new BehaviorSubject<Array<MonthYear>>([]);

  get http(): ApiClient {
    return this.userService?.profile?.isStaff ? this.manageHttp : this.userHttp;
  }

  get $manageProjects(): Observable<Array<ManageProject | Project>> {
    return this.projectsSubject.asObservable();
  }

  get manageProjects(): Array<ManageProject | Project> {
    return this.projectsSubject.value;
  }

  get $currentProject(): Observable<Project> {
    return this.currentProjectSubject.asObservable();
  }

  get $currentManageProject(): Observable<ManageProject> {
    return this.currentManageProjectSubject.asObservable();
  }

  get $project(): Observable<ManageProject | Project> {
    return this.userService.profile?.isStaff ? this.currentManageProjectSubject.asObservable() : this.currentProjectSubject.asObservable();
  }

  get project(): ManageProject | Project {
    return this.userService.profileSubj.value?.isStaff ? this.currentManageProjectSubject.value : this.currentProjectSubject.value;
  }

  get $monthes(): Observable<Array<MonthYear>> {
    return this.currentProjectMonthesSubject.asObservable();
  }

  get monthes(): Array<MonthYear> {
    return this.currentProjectMonthesSubject.value;
  }

  get items(): Array<Project> {
    return this.projectsSubject.value;
  }

  get $items(): Observable<Array<Project>> {
    return this.projectsSubject.asObservable();
  }

  get currentItem(): ManageProject {
    return this.currentManageProjectSubject.value;
  }

  get $currentItem(): Observable<ManageProject> {
    return this.currentManageProjectSubject.asObservable();
  }

  public getProjectAsync(slug: string): Observable<Project> {
    return this.http.get<Project>(`${slug}/`).pipe(tap((project: Project) => {
      this.currentProjectSubject.next(project);
    }));
  }

  public getProjectsAsync(): Observable<Array<Project>> {
    return this.http.get<Paginate<Project>>('').pipe(
      map((projects: Paginate<Project>) => {
        return projects?.results;
      }),
      tap((projects: Array<Project>) => {
        this.projectsSubject.next(projects);
      })
    );
  }

  public getManageProjectAsync(slug: string): Observable<ManageProject> {
    return this.http.get<ManageProject>(`${slug}/`).pipe(tap((project: ManageProject) => {
      this.currentManageProjectSubject.next(project);
    }));
  }

  public async getMonthes(slug: string, userMonther?: boolean): Promise<Array<MonthYear>> {
    let query = new HttpParams();

    if (userMonther) {
      query = query.set('user-id', this.userService.profile.id.toString());
    }

    const paginatedResult = await this.http.get<Paginate<MonthYear>>(`${slug}/tasks/monthes/`, query).toPromise();
    this.currentProjectMonthesSubject.next(paginatedResult.results);
    return paginatedResult.results;
  }

  public closeCurrentProject(): void {
    this.currentProjectSubject.next(null);
    this.currentManageProjectSubject.next(null);
  }

  public async createProject(data): Promise<void> {
    await this.http.post('', data).toPromise();
    await this.groupService.refreshGroup();
  }

  public updateMembers(slug: string, members: { members: Array<UserProfile> }): Observable<void> {
    return this.http.post(`${slug}/members/`, members);
  }

  public async updateProject(slug: string, project: ManageProject): Promise<void> {
    try {
      const newProject = await this.http.put<ManageProject>(`${slug}/`, project).toPromise();
      await this.groupService.refreshGroup();
      this.currentManageProjectSubject.next(newProject);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async getUsersProjects(): Promise<Array<Project>> {
    const projects = await this.http.get<Paginate<Project>>('').toPromise();
    this.projectsSubject.next(projects?.results);
    return projects?.results;
  }

  public async starProject(projectSlug): Promise<void> {
    try {
      await this.http.post(`${projectSlug}/star/`, null).toPromise();
      await this.getUsersProjects();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async unStarProject(projectSlug): Promise<void> {
    try {
      await this.http.delete(`${projectSlug}/star/`).toPromise();
      await this.getUsersProjects();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async adminStarProject(projectSlug): Promise<void> {
    await this.http.post(`${projectSlug}/star/`, null).toPromise();
  }

  public async adminUnStarProject(projectSlug): Promise<void> {
    await this.http.delete(`${projectSlug}/star/`).toPromise();
  }

  public async getManageItemInstance(slug: string): Promise<ManageProject> {
    return await this.http.get<ManageProject>(`${slug}/`).toPromise();
  }

  public async updateMonthes(stringDate: string): Promise<void> {
    let slug = this.project?.slug;

    if (this.userService.profile?.isStaff) {
      slug = this.currentManageProjectSubject.value?.slug;
    }

    if (stringDate) {
      const date = getDate(stringDate);
      const monthList: Array<MonthYear> = this.currentProjectMonthesSubject.value;
      const strikes = monthList.find((monthYear: MonthYear) => {
        return +monthYear.year === date.getFullYear() && +monthYear.month === date.getMonth() + 1;
      });

      if (!strikes) {
        await this.getMonthes(slug, this.router.url.includes('task-filter=my'));
        // await this.router.navigate([], {
        //   queryParamsHandling: 'merge',
        //   queryParams: {
        //     month: date.getMonth() + 1,
        //     year: date.getFullYear(),
        //   }
        // });
      }
    }
  }
}
