<ffcrm-dialog
  [(visible)]="confirmDialog"
  (visibleChange)="close()"
  [closable]="true"
  [title]=""
>
  <div class="confirmation-dialog-wrapper">
    <h2 class="confirmation-dialog-title">{{ dialogTitle }}</h2>
    <p class="confirmation-dialog-info">{{ dialogText }}</p>
    <div class="confirmation-dialog-buttons-wrapper">
      <button class="btn btn-light btn-block" (click)="decline()">Cancel</button>
      <button class="btn btn-block" (click)="confirm()">{{ confirmationButtonText }}</button>
    </div>
  </div>
</ffcrm-dialog>
