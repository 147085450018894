import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { Title } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// Components
import { MonthFilterComponent } from '../../../shared/super/month-filter-component';

// Models
import { OvertimeTask } from '../../interfaces/overtime.model';
import { DragNDropModalState } from '../../models/drag-n-drop-modal-state.model';
import { ManageProject } from '../../../shared/models/manage-project.model';
import { ProjectTasks } from '../../../shared/models/project-tasks.model';
import { TableRowSettings } from '../../models/table-row-settings';
import { Project } from '../../../shared/models/project.model';
import { Profile } from '../../../shared/models/profile.model';
import { MonthYear } from '../../models/month-year.model';
import { Task } from '../../../shared/models/task.model';

// Services
import { TooltipService } from '../../../shared/services/tooltip.service';
import { IMonthYearFilterService, YEAR_MONTH_FILTER_SERVICE } from '../../services/injection-tokens/month-year-filter-token';
import { DRAG_N_DROP_SERVICE } from '../../services/injection-tokens/drag-n-drop-token';
import { GoogleExportService } from '../../services/google-export/google-export.service';
import { ProjectService } from '../../services/project/project.service';
import { UserService } from '../../../shared/services/user/user.service';
import { TaskService } from '../../services/task/task.service';
import { TaskHighlightService } from '../../../shared/services/task-highlight.service';

// Utils
import findDifferents from '../../../shared/utils/find-difference-in-objects';

@Component({
  selector: 'ffcrm-current-project',
  templateUrl: './current-project.component.html',
  styleUrls: ['./current-project.component.scss'],
  providers: [
    {
      provide: DRAG_N_DROP_SERVICE,
      useExisting: TaskService,
    },
    {
      provide: YEAR_MONTH_FILTER_SERVICE,
      useExisting: ProjectService,
    },
  ],
})
export class CurrentProjectComponent extends MonthFilterComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    @Inject(YEAR_MONTH_FILTER_SERVICE) filterService: IMonthYearFilterService,
    private projectService: ProjectService,
    private taskService: TaskService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private clipboard: Clipboard,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private taskHighlightService: TaskHighlightService,
    private tooltipService: TooltipService,
    private googleExportService: GoogleExportService,
  ) {
    super(filterService);

    this.subscriptions.push(
      this.tasksRequestSubject.pipe(debounceTime(10)).subscribe(async () => {
        await this.taskService.getTasks(
          this.projectSlug,
          this.currentMonth?.month,
          this.currentMonth?.year,
          this.userInitOption.id === 'my',
        );
      }),
      this.activatedRoute?.params.subscribe(async (params: Params) => {
        this.activatedRoute.queryParams?.subscribe(async (queries: Params) => {
          this.currentMonth = {
            year: queries?.year,
            month: queries?.month,
          };

          this.setMonthes(this.currentMonth);
          if (queries['task-filter']) {
            this.userInitOption = this.userSelectOptions.find((option) => option.id === queries['task-filter']);
          }

          if (this.projectSlug !== params?.projectSlug) {
            if (this.projectSlug !== params?.projectSlug || !this.projectSlug) {
              this.projectSlug = params?.projectSlug;
              await this.filterTasks();
            }
          }

          this.groupSlug = params?.groupSlug;
        }).unsubscribe();
      }),

      this.projectService.$monthes.subscribe(() => {
        this.setMonthes(this.currentMonth);
      }),

      this.projectService.$project.subscribe((currentProject: Project) => {
        if (currentProject) {
          this.groupName = currentProject?.group?.name;
          this.titleService.setTitle(`Timerilo - ${currentProject.name}`);
        } else {
          this.titleService.setTitle(`Timerilo`);
        }
      }),

      this.activatedRoute.queryParams?.subscribe(async (queries: Params) => {
        if (queries.user && this.prevParams && queries.user !== this.prevParams?.user && this.userService.profile.isStaff) {
          await this.taskService.getTasks(this.projectSlug, queries.month, queries.year);
        }

        const prevMonth = this.currentMonth;
        this.currentMonth = {
          year: queries?.year,
          month: queries?.month,
        };

        if (queries['task-filter']) {
          this.userInitOption = this.userSelectOptions.find((option) => option.id === queries['task-filter']);
        }

        this.setMonthes(this.currentMonth);

        if (prevMonth && this.prevParams?.user === queries?.user) {
          this.activatedRoute?.params.subscribe(async (params: Params) => {
            if (this.projectService.project?.slug === params?.projectSlug) {
              this.projectSlug = params?.projectSlug;
              await this.filterTasks();
            }

          }).unsubscribe();
        }

        this.prevParams = { task_user: queries.task_user, month: queries.month, year: queries.year };
      }),

      this.activatedRoute.data.subscribe((routeData: Data) => {
        this.isNoProject = routeData?.noProjects;
      }),
    );

    this.project = this.projectService.project;
    this.$profile = this.userService.$profile;
    this.$project = this.projectService.$project;
    this.$currentTask = this.taskService.$currentTask;
    this.$tasks = this.taskService.$tasks;
    this.tasks = this.taskService.tasks;
    this.$taskId = this.taskHighlightService.$taskId;
  }

  @ViewChild('taskRowTemplate') public taskRowTemplate: TemplateRef<any>;
  @ViewChild('taskExtraTimeValueTemplate') public taskExtraTimeValueTemplate: TemplateRef<any>;
  @ViewChild('tasksAddTimeValueTemplate') public tasksAddTimeValueTemplate: TemplateRef<any>;
  @ViewChild('tasksHoursValueTemplate') public tasksHoursValueTemplate: TemplateRef<any>;
  @ViewChild('tasksTitleValueTemplate') public tasksTitleValueTemplate: TemplateRef<any>;
  @ViewChild('tasksDataValueTemplate') public tasksDataValueTemplate: TemplateRef<any>;
  @ViewChild('tasksEditValueTemplate') public tasksEditValueTemplate: TemplateRef<any>;
  @ViewChild('tasksLinkTemplate') public tasksLinkTemplate: TemplateRef<any>;
  @ViewChild('addTaskButton') addTaskButton;
  @ViewChild('search') search;

  private tasksRequestSubject: Subject<void> = new Subject<void>();
  private projectSlug: string;
  private groupSlug: string;
  public closeMonthDialog = false;
  public usersModalState: DragNDropModalState = {
    refresh: false,
    mode: 'task',
    open: false,
    slug: '',
  };

  public $tasks: Observable<ProjectTasks>;
  public $project: Observable<ManageProject | Project>;
  public $profile: Observable<Profile>;
  public $currentTask: Observable<Task>;
  public project: ManageProject | Project;
  public tasks: Array<Task | OvertimeTask>;
  public usersList: Array<Profile>;
  private coldUsersList: Array<Profile>;
  public currentMonth: MonthYear;
  public monthes: Array<MonthYear>;
  public coldData: Array<Task> = [];
  public tableData: Array<Task> = [];
  public differenceTableData: Array<Task> = [];
  public monthIsClosed = false;
  public isNoProject = false;
  public readonly $taskId: Observable<string>;
  public groupName = '';
  public dropdownShow = true;
  public isConfirmGoogleSheetOpening = false;
  public spreadSheetLink: string;

  public userSelectOptions = [
    {
      id: 'all',
      label: 'All tasks',
    },
    {
      id: 'my',
      label: 'My tasks',
    },
  ];

  public userInitOption = this.userSelectOptions[0];
  public tableSettings: Array<TableRowSettings> = [];
  public skipCols: Array<TableRowSettings> = [];

  private prevParams;

  get taskUsersModalState(): DragNDropModalState {
    return this.usersModalState;
  }

  get usernameFromQueries(): string {
    const id = +this.activatedRoute.snapshot.queryParams?.task_user;

    if (!id) {
      return '';
    }

    const profile = this.usersList.find(user => user?.id === id);

    if (!profile?.firstName || !profile?.lastName) {
      return '';
    }

    return profile.firstName + ' ' + profile.lastName;
  }

  get defaultTableSettings(): Array<TableRowSettings> {
    return [
      {
        key: 'id', title: '', template: this.tasksEditValueTemplate, sort: false,
        classes: `table-task-id ${this.userService.profile?.isStaff ? '' : 'wider'}`,
      },
      {
        key: 'taskTitle', title: 'Title', template: this.tasksTitleValueTemplate, sort: true,
        classes: 'table-title',
        sortCallbacks: { asc: this.sortTaskByTitleAsc, desc: this.sortTaskByTitleDesc }
      },
      {
        key: 'minutes', title: 'Spent time', template: this.tasksHoursValueTemplate, sort: true,
        classes: 'table-hours',
        sortCallbacks: { asc: this.sortTasksBySpentTimeAsc, desc: this.sortTasksBySpentTimeDesc }
      },
      {
        key: 'isExtraTime', title: 'Extra time', template: this.taskExtraTimeValueTemplate, sort: false,
        classes: 'table-extra-time',
      },
      {
        key: 'overtimes', title: 'Overtime', template: this.tasksHoursValueTemplate, sort: true,
        classes: 'table-overtime',
        sortCallbacks: { asc: this.sortTasksByOvertimeAsc, desc: this.sortTasksByOvertimeDesc }
      },
      {
        key: 'dates', title: 'Dates', template: this.tasksDataValueTemplate, sort: true,
        classes: 'table-dates',
        sortCallbacks: { asc: this.sortTaskByDateAsc, desc: this.sortTaskByDateDesc }
      },
      {
        key: 'link', title: 'External link', template: this.tasksLinkTemplate, sort: true,
        classes: 'table-link',
        sortCallbacks: { asc: this.sortTaskByLinkAsc, desc: this.sortTaskByLinkDesc }
      },
      {
        key: 'id', title: '', template: this.taskRowTemplate, sort: false,
        classes: 'row-table',
      },
    ];
  }

  public showTooltip(event): void {
    this.tooltipService.showTooltip();
    this.tooltipService.setCords({x: event.clientX - 220, y: event.clientY + 5});
  }

  public async ngOnInit(): Promise<void> {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const confirmUrl = queryParams.confirm_url;
    const redirectUrl = queryParams.redirect_url;

    if (confirmUrl && redirectUrl) {
      const popUpSubscription = this.googleExportService.$popUpSubject.subscribe(link => {
        this.spreadSheetLink = link;
        this.isConfirmGoogleSheetOpening = true;
        popUpSubscription.unsubscribe();
      });
      const year: number = +queryParams?.year;
      const month: number = +queryParams?.month;
      const groupSlug: string = this.activatedRoute.snapshot.paramMap.get('groupSlug');
      const projectSlug: string = this.activatedRoute.snapshot.paramMap.get('projectSlug');

      await this.googleExportService.exportUtil(confirmUrl, redirectUrl, 'startTasksWS', [groupSlug, projectSlug, year, month]);
    }

    this.subscriptions.push(
      this.userService.$users.subscribe((users) => {
        this.usersList = users;
        this.coldUsersList = users;
      }),
      this.$tasks.subscribe((projectTasks: ProjectTasks) => {
        const result = [];
        this.monthIsClosed = projectTasks?.monthIsClosed;

        if (!projectTasks || this.monthIsClosed || this.project?.isReadonly) {
          if (!this.userService.profile?.isStaff) {
            this.skipCols = this.defaultTableSettings.filter((col: TableRowSettings) => col?.key === 'id');
          }
        } else {
          this.skipCols = [];
        }

        projectTasks?.tasks.forEach((task: Task) => {
          const newTable = {
            taskTitle: {
              id: task?.id,
              name: task?.title,
              task_user: task?.user?.firstName,
              difficulty: task?.difficulty,
              users: task?.users,
            },
            ...task,
          };

          result.push(newTable);
        });

        if (JSON.stringify(this.tableData) !== JSON.stringify(result)) {
          if (this.tableData.length) {
            this.differenceTableData = findDifferents(this.tableData, result);
          }

          this.coldData = result;
          this.tableData = result;
        }
      }),

      this.userService.$profile.subscribe((profile: Profile) => {
        if (!profile?.isStaff) {
          const result = [];
          this.tableSettings?.forEach((column: TableRowSettings) => {
            if (column?.key === 'id') {
              result.push({
                ...column,
                classes: 'table-task-add-time',
              });
            } else {
              result.push(column);
            }
          });
          this.tableSettings = result;
        }
        this.cd.detectChanges();
      }),
    );
  }

  public ngAfterViewInit(): void {
    this.tableSettings = this.defaultTableSettings;

    /**
     * called to avoid "ExpressionChangedAfterItHasBeenCheckedError"
     * get Templates available only after ViewInit, otherwise it return undefined
     */
    this.cd.detectChanges();
  }

  @HostListener('window:keydown', ['$event'])
  public async clickAddTask(e: KeyboardEvent): Promise<void> {
    if (!this.userService.profile?.isStaff) {
      if (e.code === 'KeyD' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        if (!this.taskService?.taskListObject?.monthIsClosed) {
          if (!this.activatedRoute.children.length) {
            await this.router.navigate(['./task/add'], {
              queryParamsHandling: 'merge',
              relativeTo: this.activatedRoute,
            });
          }
        }
      }
    }
  }

  get isAdmin(): boolean {
    return this.userService.profile?.isStaff;
  }

  private filterTasks(): void { // TODO: need optimization to prevent duplicate request
    if (this.projectSlug && this.userService.profile && this.currentMonth) {
      this.tasksRequestSubject.next();
    }
  }

  private sortTasksBySpentTimeAsc(a, b): number {
    return a?.minutes - b?.minutes;
  }

  private sortTasksBySpentTimeDesc(a, b): number {
    return b?.minutes - a?.minutes;
  }

  private sortTasksByOvertimeAsc(a, b): number {
    return a?.overtimes - b?.overtimes;
  }

  private sortTasksByOvertimeDesc(a, b): number {
    return b?.overtimes - a?.overtimes;
  }

  private sortTaskByTitleAsc(a, b): number {
    return a?.title?.localeCompare(b?.title);
  }

  private sortTaskByTitleDesc(a, b): number {
    return b?.title?.localeCompare(a?.title);
  }

  private sortTaskByLinkAsc(a, b): number {
    return a?.link?.localeCompare(b?.link);
  }

  private sortTaskByLinkDesc(a, b): number {
    return b?.link?.localeCompare(a?.link);
  }

  private sortTaskByDifficultyAsc(a, b): number {
    return a?.difficulty?.level - b?.difficulty?.level;
  }

  private sortTaskByDifficultyDesc(a, b): number {
    return b?.difficulty?.level - a?.difficulty?.level;
  }


  private sortTaskByDateAsc(a, b): number {
    const aDate = a.dates.sort((d1, d2) => Date.parse(d1) - Date.parse(d2))[0];
    const bDate = b.dates.sort((d1, d2) => Date.parse(d1) - Date.parse(d2))[0];
    if (aDate > bDate) {
      return 1;
    } else if (aDate < bDate) {
      return -1;
    }
    return 0;
  }

  private sortTaskByDateDesc(a, b): number {
    const aDate = a.dates.sort((d1, d2) => Date.parse(d1) - Date.parse(d2))[0];
    const bDate = b.dates.sort((d1, d2) => Date.parse(d1) - Date.parse(d2))[0];

    if (aDate < bDate) {
      return 1;
    } else if (aDate > bDate) {
      return -1;
    }
    return 0;
  }

  public clickTableRowByFocus(e: KeyboardEvent): void {
    if (e.code === 'Enter') {
      (document.activeElement as HTMLElement).click();
    }
  }

  public async editProject(): Promise<void> {
    await this.router.navigate(['./edit'], {
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
    });
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
    this.tooltipService.setCords(null);
  }

  public async closeProject(): Promise<void> {
    this.projectService.closeCurrentProject();
    await this.taskService.closeAllTasks();
  }

  public searchTasks(): void {
    const keyword = this.search.nativeElement?.value.toLowerCase();
    this.tableData = this.coldData?.filter((task: Task) => (
      task?.user?.firstName?.toLowerCase()?.includes(keyword)
        || task?.user?.lastName?.toLowerCase()?.includes(keyword)
        || task?.title?.toLowerCase()?.includes(keyword)
        || task?.link?.toLowerCase()?.includes(keyword)
        || task?.description?.toLowerCase()?.includes(keyword)
    ));
  }

  public async selectMonthFilter(month: MonthYear): Promise<void> {
    await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams: {
        year: month?.year,
        month: month?.month,
      },
    });
  }

  public async selectUserFilter(taskFilter: string): Promise<void> {
    if (taskFilter) {
      const currentDate = new Date(Date.now());
      const prevMonthes = this.projectService.monthes;
      const monthes = await this.projectService.getMonthes(this.projectSlug, taskFilter === 'my');
      let queryParams: { year?: number; month?: number; 'task-filter': string };

      if (monthes !== prevMonthes) { // if we have month changes
        if (monthes.length) { // if monthes from request is not empty
          if (monthes.find((month: MonthYear) => { // then we try find existing month in it
            return +month.month === +this.currentMonth.month && +month.year === +this.currentMonth.year;
          })) {
            queryParams = {
              'task-filter': taskFilter,
            };
          } else { // else we grab first month in array
            queryParams = {
              year: monthes[0]?.year,
              month: monthes[0]?.month,
              'task-filter': taskFilter,
            };
          }
        } else {
          queryParams = {
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            'task-filter': taskFilter,
          };
        }
      } else {
        queryParams = {
          'task-filter': taskFilter,
        };
      }

      await this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
        queryParams,
      });
    }
  }

  public openCloseMonthDialog(): void {
    this.closeMonthDialog = true;
  }

  public async copyLink(link: string): Promise<void> {
    this.clipboard.copy(link || '');
  }

  public async closeMonth(close: boolean): Promise<void> {
    if (close && this.projectSlug) {
      await this.taskService.closeMonth(this.projectSlug, this.currentMonth.year, this.currentMonth.month, this.monthIsClosed);
    }
  }

  public async google(): Promise<void> {
    const response = await this.googleExportService.authGoogle(window.location.origin + '/home/google-auth/');
    localStorage.setItem('redirectUrl', window.location.pathname);
    localStorage.setItem('redirectQueryParams', window.location.search);
    if (response?.authUrl) {
      window.location.href = response?.authUrl;
    } else {
      alert('Error');
    }
  }

  public async print(): Promise<void> {
    console.log('todo: print function add');
  }

  public openTaskMembers(task): void {
    this.usersModalState = {
      ...this.taskUsersModalState,
      open: true,
      slug: task?.id,
    };
  }

  public async handleUsers(value: string): Promise<void> {
    if (!value) {
      await this.router.navigate(['.'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          task_user: 'all'
        },
        queryParamsHandling: 'merge',
      });
    }

    this.usersList = this.coldUsersList?.filter(({ firstName, lastName }: Profile) => {
      return (firstName + ' ' + lastName)?.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    });

    if (
      this.usersList?.length &&
      value.toLowerCase() === (this.usersList[0]?.firstName + ' ' + this.usersList[0]?.lastName).toLowerCase() &&
      this.usersList?.length === 1
    ) {
      await this.router.navigate(['.'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          task_user: this.usersList[0].id,
        },
        queryParamsHandling: 'merge',
      });
      this.dropdownShow = false;
    } else {
      this.dropdownShow = true;
    }
  }

  public async openTimeEntriesPage(task: Task): Promise<void> {
    if (this.groupSlug && this.userService.profile?.isStaff) {
      await this.router.navigate([`home/groups/${this.groupSlug}/projects/${this.projectSlug}/tasks/${task?.id}`], {
        queryParamsHandling: 'merge',
      });
    } else {
      await this.router.navigate([`home/projects/${this.projectSlug}/tasks/${task?.id}`], {
        queryParamsHandling: 'merge',
      });
    }
  }
}
