import { animate, state, style, transition, trigger } from '@angular/animations';

export const cellsAnimation = [
  trigger('changeHours', [
    state('true', style({
      transform: 'translateX(0%)',
    })),
    state('false', style({
      transform: 'translateX(-100%)'
    })),
    transition('* => *', [
      animate('150ms linear')
    ]),
  ]),
  trigger('changeMinutes', [
    state('true', style({
      transform: 'translateX(100%)',
    })),
    state('false', style({
      transform: 'translateX(-100%)'
    })),
    transition('* => *', [
      animate('150ms linear')
    ]),
  ]),
];
