import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';

// Components
import { FormComponent } from '../../super/form-component';

// Models
import { EditProfileError } from '../../../main/models/edit-profile-error.model';
import { ProfileCore } from '../../models/profile-core.model';
import { Profile } from '../../models/profile.model';

// Services
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserService } from '../../services/user/user.service';

// Utils
import imageResolving from '../../utils/resolve-upload-file';


@Component({
  selector: 'ffcrm-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent extends FormComponent implements OnInit, OnDestroy {

  @ViewChild('photoInput') photoInput;

  public title: string;
  public modalMode: string;

  public successDialog: boolean;
  public errorDialog: boolean;

  // tslint:disable-next-line:variable-name
  private _user?: Profile;
  @Input()
  set user(user: Profile) {
    this._user = user;

    if (this._user) {
      this.form?.patchValue(user);
    } else {
      this.form?.patchValue({});
    }
  }

  get user(): Profile {
    if (this.modalMode) {
      return this._user;
    } else {
      return this.userService.profile;
    }
  }

  @Input() editProfileDialog;
  @Output() editProfileDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updatedUser: EventEmitter<Profile> = new EventEmitter<Profile>();
  public form: FormGroup;

  public selectedImage: string;
  public uploadImage = false;
  public imageCropper: boolean;
  public croppedImage: string | ArrayBuffer;
  public tempUser: ProfileCore;

  public editProfileBackErrors: EditProfileError;

  readonly resolvedFileTypes = [
    'image/gif',
    'image/jpeg',
    'image/png',
  ];

  constructor(
    private alertService: AlertService,
    private userService: UserService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.title = this.user ? 'Edit profile' : 'Add user';
    this.form = this.getForm(this.userService.profile);

    this.subscriptions.push(
      this.activatedRoute.data.subscribe((routeData: Data) => {
        this.user = routeData?.user;
        this.modalMode = routeData?.mode;

        if (this.modalMode === 'edit') {
          this.selectedImage = this.user?.photo;
        } else if (this.modalMode === 'add') {
          this.selectedImage = null;
          this.form = this.getForm();
        } else {
          this.form = this.getForm(this.userService.profile);
          this.selectedImage = this.userService.profile?.photo;
        }
      })
    );

    if (this.user) {
      this.tempUser = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        photo: this.user.photo,
      };
    }
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  get firstName(): AbstractControl { return this.form.get('firstName'); }
  get lastName(): AbstractControl { return this.form.get('lastName'); }
  get email(): AbstractControl { return this.form.get('email'); }
  get jobTitle(): AbstractControl { return this.form.get('jobTitle'); }
  get phoneNumber(): AbstractControl { return this.form.get('phoneNumber'); }

  private getForm(user?: Profile): FormGroup {
    return this.fb.group({
      firstName: [user?.firstName || '', [Validators.required]],
      lastName: [user?.lastName || '', [Validators.required]],
      email: [user?.email || '', [Validators.required, Validators.email]],
      jobTitle: [user?.jobTitle || '', [Validators.required]],
      phoneNumber: [user?.phoneNumber || '', [Validators.required]],
    });
  }

  public async onStateChange(): Promise<void> {
    if (this.modalMode) {
      await this.router.navigate(['../'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'preserve',
      });
      this.modalMode = '';
    } else {
      this.form.reset(this.userService.profile);
      this.tempUser.photo = this.user.photo;

      this.selectedImage = this.user?.photo;
      this.editProfileDialogChange.emit();
    }
  }

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      const data = {...this.form.value, photo: this.tempUser?.photo};

      if (this.user) {
        this.user.photo = this.tempUser?.photo;
      }

      if (!this.uploadImage) {
        // @ts-ignore
        delete data.photo;
      }

      if (this.user?.id) {
        this.user.id === this.userService.profile.id
          ? await this.updateProfile(data)
          : await this.updateUser(data);
      } else {
        await this.createUser(data);
      }
    }
  }

  public async updateProfile(data): Promise<void> {
    try {
      const profile = await this.userService.updateProfile(data);
      this.editProfileDialog = false;
      this.successDialog = true;
      this.updatedUser.emit(profile);

      if (profile?.isStaff) {
        await this.userService.getAllUsers();
      }

      await this.onStateChange();
      await this.alertService.showAlert({
        success: true,
        message: 'Your profile has been successfully updated',
      });
    } catch (e) {
      await this.getErrorsFromResponse(e.error);
    }
  }

  public async createUser(data): Promise<void> {
    try {
      await this.userService.createUser(data);
      this.editProfileDialog = false;
      this.successDialog = true;
      await this.onStateChange();
      await this.userService.getAllUsers();
      await this.alertService.showAlert({
        success: true,
        message: 'User has been successfully created',
      });
    } catch (e) {
      await this.getErrorsFromResponse(e.error);
    }
  }

  public async updateUser(data): Promise<void> {
    try {
      const profile = await this.userService.updateUser(this.user.id, data);
      this.editProfileDialog = false;
      this.successDialog = true;
      await this.userService.getAllUsers();
      await this.onStateChange();
      this.updatedUser.emit(profile);
      await this.alertService.showAlert({
        success: true,
        message: 'User has been successfully updated',
      });
    } catch (e) {
      await this.getErrorsFromResponse(e.error);
    }
  }

  async processPhoto(event: any): Promise<void> {
    const file: File = event.target.files[0];
    const reader = new FileReader();

    imageResolving(file, () => {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.croppedImage = reader.result;
        this.editProfileDialog = false;
        this.imageCropper = true;
      };
    }, async (message) => {
      await this.alertService.showAlert({
        success: false,
        message: message as string,
      });
    });

    this.photoInput.nativeElement.value = '';
  }

  public getCroppedImage(image: string): void {
    if (this.user) {
      this.tempUser.photo = image;
    } else {
      this.tempUser = {
        ...this.user,
        photo: image,
      };
    }

    this.selectedImage = image;
    this.editProfileDialog = true;
    this.uploadImage = true;
  }

  public croppedImageDialogChange(val: boolean): void {
    this.editProfileDialog = !val;
  }

}
