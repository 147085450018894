export class GroupErrors {
  name: string[];
  slug: string[];

  constructor(errors: any) {
    errors.hasOwnProperty('name') ? this.name = errors.name : this.name = null;
    errors.hasOwnProperty('slug') ? this.slug = errors.slug : this.slug = null;
  }

  public haveName(): boolean { return !!this.name; }
  public haveSlug(): boolean { return !!this.slug; }
}
