<div class="grid projects-list" *ngIf="!isNoGroups; else emptyList">
  <div class="head with-margin-pillow">
    <div class="summary">
      <h1>{{group?.name}}</h1>

      <div class="statistic">
        <div class="stat-item">
          <p class="text">Total time</p>
          <p class="number">{{ group?.totalTime | hhmm }}</p>
        </div>
        <div class="stat-item">
          <p class="text">Members</p>
          <p class="number">{{ group?.totalMembers}}</p>
        </div>
        <div class="stat-item">
          <p class="text">Total projects</p>
          <p class="number">{{ group?.totalProjects }}</p>
        </div>
        <div class="stat-item">
          <p class="text">Estimation</p>
          <p class="number">{{ group?.estimation | hhmm }}</p>
        </div>
      </div>
    </div>

    <div class="right">
      <div class="block">
        <a class="edit-button" routerLink="./edit">
          <i class="icon icon-edit icon-18px"></i>
        </a>
        <div class="search">
          <div class="input-block">
            <i class="icon icon-search search-icon"></i>
            <input #search class="input-field input-search" (input)="filterProjects()" type="text" id="search" placeholder="Search">
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="filter with-margin-pillow">
    <h2>Projects</h2>
    <ffcrm-select class="select" [options]='selectOptions' (selected)="selectFilter($event)" [value]="'all'"></ffcrm-select>
  </div>

  <div class="projects-block" #projectsBlock>

    <div class="empty" *ngIf="!filteredProjects?.length">
      <div class="empty-content">
        It's time for a new startup, isn't it?
      </div>
    </div>

    <div class="starred" *ngIf="(filteredProjects | starred)?.length">
      <p class="block-name">STARRED PROJECTS</p>
      <div class="project-list">
        <ng-container *ngFor="let project of filteredProjects | starred">
          <ffcrm-card [instance]="project"
                      [type]="'project'"
                      (starProjectEvent)="starProject(project)"
                      (unStarProjectEvent)="unStarProject(project)"
                      (addUsersEvent)="newAddUsersModal(project?.slug)"
                      [actions]="true"
                      (click)="openProject(project?.slug)">
          </ffcrm-card>
        </ng-container>
      </div>
    </div>

    <div class="other" *ngIf="(filteredProjects | other)?.length">
      <p class="block-name">OTHER PROJECTS</p>
      <div class="project-list">
        <ng-container *ngFor="let project of filteredProjects | other">
          <ffcrm-card [instance]="project"
                      [type]="'project'"
                      (starProjectEvent)="starProject(project)"
                      (unStarProjectEvent)="unStarProject(project)"
                      (addUsersEvent)="newAddUsersModal(project.slug)"
                      [actions]="true"
                      (click)="openProject(project?.slug)">
          </ffcrm-card>
        </ng-container>
      </div>
    </div>

    <div class="readonly" *ngIf="(filteredProjects | readonly )?.length">
      <p class="block-name">READONLY PROJECTS</p>
      <div class="project-list">
        <ng-container *ngFor="let project of filteredProjects | readonly">
          <ffcrm-card [instance]="project"
                      [type]="'project'"
                      (addUsersEvent)="newAddUsersModal(project.slug)"
                      [actions]="true"
                      (click)="openProject(project?.slug)">
          </ffcrm-card>
        </ng-container>
      </div>
    </div>

    <div class="inactive" *ngIf="(filteredProjects | inactive )?.length">
      <p class="block-name">INACTIVE PROJECTS</p>
      <div class="project-list">
        <ng-container *ngFor="let project of filteredProjects | inactive">
          <ffcrm-card [instance]="project"
                      [type]="'project'"
                      (addUsersEvent)="newAddUsersModal(project.slug)"
                      [actions]="true"
                      (click)="openProject(project?.slug)">
          </ffcrm-card>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="add-button with-margin-pillow">
    <a class="btn btn-block btn-add" routerLink="./projects/create">
      <i class="icon icon-add"></i>
      Add project
    </a>
  </div>
</div>
<router-outlet></router-outlet>

<div *ngIf="group">
  <ffcrm-add-users-drug-and-drop
    [(state)]="userModalState"
  ></ffcrm-add-users-drug-and-drop>
</div>

<ng-template #emptyList>
  <ffcrm-empty-list></ffcrm-empty-list>
</ng-template>
