<ffcrm-dialog
 [visible]="isVisible"
 [type]="type === 'accept' ? '' : 'info'"
 [timeoutClose]="false"
 (visibleChange)="cancelClick($event)"
 >
  <div class="dialog-body" [class.accept]="type === 'accept'">
    <img *ngIf="type === 'delete'" class="delete-image" src="../../../../assets/icons/trash-bin.svg" alt="Trash">
    <img *ngIf="type === 'sheet'" class="delete-image" src="../../../../assets/icons/sheets.svg" alt="Sheet">
    <h2 *ngIf="type === 'accept'" class="title">{{ title }}</h2>
    <p class="description" [class.accept]="type === 'accept'">{{ description }}</p>
    <div [class.actions]="type === 'accept'">
      <button class="btn btn-light cancel-btn" (click)="cancelClick($event)">Cancel</button>
      <button *ngIf="type !== 'sheet'" class="btn btn-delete" (click)="acceptClick($event)">{{ acceptBtnText }}</button>
      <a *ngIf="type === 'sheet'" class="btn" target="_blank" [href]="link" (click)="acceptClick($event)">Open</a>
    </div>
  </div>
</ffcrm-dialog>
