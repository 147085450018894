<div class="wrapper">
  <ngb-datepicker #d
                  navigation="none"
                  [displayMonths]="1"
                  (dateSelect)="value = $event"
  >
    <ng-template ngbDatepickerContent>
      <div class="dp-head">
        <button class="icon icon-left icon-24px" (click)="swipeMonth(-1)"></button>
        <p class="dp-heading">{{i18n.getMonthFullName(d.state.months[0].month)}}, {{d.state.months[0].year}}</p>
        <button class="icon icon-right icon-24px" (click)="swipeMonth(1)"></button>
      </div>
      <ngb-datepicker-month class="border rounded" [month]="d.state.months[0]"></ngb-datepicker-month>
    </ng-template>
  </ngb-datepicker>
</div>
