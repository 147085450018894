import { Injectable } from '@angular/core';
import { Project } from '../../shared/models/project.model';
import { ProjectService } from '../services/project/project.service';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectResolver implements Resolve<Project> {

  constructor(
    private projectService: ProjectService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project | any> {
    return this.projectService.getProjectAsync(route.params?.projectSlug).pipe(catchError((err) => this.router.navigateByUrl('/')));
  }
}
