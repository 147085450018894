// Utils
import deleteProperty from '../utils/delete-property';

const findDifferents = (arr1: Array<any>, arr2: Array<any>): Array<any> => {

  const stringifyArray1: Array<string> = arr1?.map((deleteProperty('isNew') as any))
                                            ?.map(item => JSON.stringify(item));

  const stringifyArray2: Array<string> = arr2?.map((deleteProperty('isNew') as any))
                                            ?.map(item => JSON.stringify(item));

  const result = [];

  stringifyArray2?.forEach((item) => {
    if (!stringifyArray1.includes(item)) {
      result.push(item);
    }
  });

  return result.map(item => JSON.parse(item));
};

export default findDifferents;
