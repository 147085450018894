<div class="wrapper">
  <div class="grid">
    <div class="logo-wrapper">
      <ffcrm-logo></ffcrm-logo>
    </div>
    <div class="summary" *ngIf="$summary | async">
      <h1>Dashboard</h1>
      <div class="statistic" *ngIf="profile?.isStaff">
        <div class="projects-stat">
          <p class="text">Active Projects</p>
          <p class="number">{{ ($summary |async)?.activeProjects }}</p>
        </div>
        <div class="groups-stat">
          <p class="text">Active groups</p>
          <p class="number">{{ ($summary | async)?.activeGroups }}</p>
        </div>
        <div class="users-stat">
          <p class="text">Active users</p>
          <p class="number">{{ ($summary | async)?.activeUsers }}</p>
        </div>
      </div>
      <div class="statistic" *ngIf="!profile?.isStaff">
        <div class="projects-stat">
          <p class="text">Active Projects</p>
          <p class="number">{{ ($summary | async)?.activeProjects }}</p>
        </div>
        <div class="groups-stat">
          <p class="text">Overtime</p>
          <p class="number">{{ (($summary | async)?.overtime | hhmm) }}</p>
        </div>
        <div class="users-stat">
          <p class="text">Total time</p>
          <p class="number">{{ (($summary | async)?.totalTime | hhmm) }}</p>
        </div>
      </div>
    </div>

    <div class="groups-search" *ngIf="$summary | async">
      <div class="groups">
        <div class="heading">
          <h2 *ngIf="profile?.isStaff">Groups</h2>
          <h2 *ngIf="!profile?.isStaff">Projects</h2>
          <div class="add-user" [class.add-user-disabled]="!currentGroup" *ngIf="profile?.isStaff">
            <button (click)="newAddUserModal()">
              <i class="icon icon-add-person-blue icon-18px"></i></button>
          </div>
        </div>
        <div class="search">
          <div class="input-block">
            <i class="icon icon-search search-icon"></i>
            <input #search class="input-field input-search" type="text" (input)="filterItems()" placeholder="Search">
          </div>
        </div>
      </div>
    </div>

    <div class="items-list" [class.no-items]="!projects.length && !groups.length">
      <div class="items-list-no-groups" *ngIf="!projects.length && !groups.length">
        <img class="no-groups-folder" src="../../../../assets/images/empty-folder.svg"
             [alt]="profile?.isStaff ? 'No groups' : 'No projects'"/>
        <p class="no-groups-text">{{ profile?.isStaff ? "No groups" : "No projects" }}</p>
      </div>
      <div class="groups-list" *ngIf="profile?.isStaff">
        <div class="starred" *ngIf="($userSidebar | async) === false">
        <p *ngIf="(filteredGroups | starred)?.length">STARRED</p>
          <ul>
            <li *ngFor="let group of filteredGroups | starred" (click)="selectGroup(group?.slug)"
                [class.selected-item]="isSelected(group?.slug)">

              <div class="image-container" (mouseenter)="showHelp(group?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="group" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ group?.name }}
              </div>
              <div class="star" (click)="$event.stopPropagation(); toggleGroupStar(group)">
                <i class="icon icon-star icon-18px"></i>
              </div>
            </li>
            <p *ngIf="(filteredGroups | other)?.length">OTHER</p>
            <li *ngFor="let group of filteredGroups|other" (click)="selectGroup(group?.slug)"
                [class.selected-item]="isSelected(group?.slug)" class="item.other">

              <div class="image-container" (mouseenter)="showHelp(group?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="group" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ group?.name }}
              </div>
              <div class="star" (click)="$event.stopPropagation(); toggleGroupStar(group)">
                <i class="icon icon-star-gray icon-18px"></i>
              </div>
            </li>
            <p *ngIf="(filteredGroups | readonly)?.length">READONLY</p>
            <li *ngFor="let group of filteredGroups | readonly" (click)="selectGroup(group?.slug)"
                [class.selected-item]="isSelected(group?.slug)" class="item-leftsided item-readonly">

              <div class="image-container" (mouseenter)="showHelp(group?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="group" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ group?.name }}
              </div>
            </li>
            <p *ngIf="(filteredGroups | inactive)?.length">INACTIVE</p>
            <li *ngFor="let group of filteredGroups | inactive"
                (click)="selectGroup(group?.slug)" class="item-leftsided item-inactive"
                [class.selected-item]="isSelected(group?.slug)">
              <div class="image-container" (mouseenter)="showHelp(group?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="group" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>
              <div class="name">
                {{ group?.name }}
              </div>
            </li>
          </ul>
        </div>

        <div class="starred" *ngIf="(filteredGroups | starred)?.length && ($userSidebar | async) === true">
          <p class="group-title">STARRED GROUPS</p>

          <ul>
            <li *ngFor="let group of filteredGroups | starred" (click)="selectGroup(group?.slug)"
                [class.selected-item]="isSelected(group?.slug)">

              <div class="image-container" (mouseenter)="showHelp(group?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="group" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ group?.name }}
              </div>
              <div class="star" (click)="$event.stopPropagation(); toggleGroupStar(group)">
                <i class="icon icon-star icon-18px"></i>
              </div>
            </li>
          </ul>

        </div>

        <div class="other" *ngIf="(filteredGroups|other)?.length && ($userSidebar | async) === true">
          <p class="group-title">OTHER GROUPS</p>
          <ul>
            <li *ngFor="let group of filteredGroups|other" (click)="selectGroup(group?.slug)"
                [class.selected-item]="isSelected(group?.slug)">

              <div class="image-container" (mouseenter)="showHelp(group?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="group" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ group?.name }}
              </div>
              <div class="star" (click)="$event.stopPropagation(); toggleGroupStar(group)">
                <i class="icon icon-star-gray icon-18px"></i>
              </div>
            </li>
          </ul>
        </div>

        <div class="other" *ngIf="(filteredGroups | readonly)?.length && ($userSidebar | async) === true">
          <p class="group-title">READONLY GROUPS</p>
          <ul>
            <li *ngFor="let group of filteredGroups | readonly" (click)="selectGroup(group?.slug)"
                [class.selected-item]="isSelected(group?.slug)" class="item-leftsided">

              <div class="image-container" (mouseenter)="showHelp(group?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="group" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ group?.name }}
              </div>
            </li>
          </ul>
        </div>

        <div class="inactive" *ngIf="(filteredGroups | inactive)?.length && ($userSidebar | async) === true">
          <p class="group-title">INACTIVE GROUPS</p>
          <ul>
            <li *ngFor="let group of filteredGroups | inactive"
                (click)="selectGroup(group?.slug)" class="item-leftsided"
                [class.selected-item]="isSelected(group?.slug)">
              <div class="image-container" (mouseenter)="showHelp(group?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="group" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>
              <div class="name">
                {{ group?.name }}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="projects-list" *ngIf="!profile?.isStaff">
        <div class="starred" *ngIf="($userSidebar | async) === false">
          <p *ngIf="(filteredProjects | starred)?.length">STARRED</p>
          <ul>
            <li *ngFor="let project of filteredProjects | starred"
                (click)="selectProject(project?.slug)"
                [class.selected-item]="isSelected(project.slug)"
            >
              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
              <div class="star" (click)="$event.stopPropagation(); toggleProjectStar(project)">
                <i class="icon icon-star icon-18px"></i>
              </div>
            </li>
          </ul>
          <p *ngIf="(filteredProjects | other)?.length">OTHER</p>
          <ul>
            <li *ngFor="let project of filteredProjects | other" (click)="selectProject(project?.slug)"
                [class.selected-item]="isSelected(project.slug)">
              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
              <div class="star" (click)="$event.stopPropagation(); toggleProjectStar(project)">
                <i class="icon icon-star-gray icon-18px"></i>
              </div>
            </li>
          </ul>
        <p *ngIf="(filteredProjects | readonly)?.length">READONLY</p>
          <ul>
            <li *ngFor="let project of filteredProjects | readonly" (click)="selectProject(project?.slug)"
                [class.selected-item]="isSelected(project.slug)" class="item-leftsided">
              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
            </li>
          </ul>
        </div>

        <div class="starred" *ngIf="(filteredProjects | starred)?.length && ($userSidebar | async) === true">
          <p class="group-title">STARRED PROJECTS</p>

          <ul>
            <li *ngFor="let project of filteredProjects | starred"
                (click)="selectProject(project?.slug)"
                [class.selected-item]="isSelected(project.slug)">

              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
              <div class="star" (click)="$event.stopPropagation(); toggleProjectStar(project)">
                <i class="icon icon-star icon-18px"></i>
              </div>
            </li>
          </ul>
        </div>

        <div class="other" *ngIf="(filteredProjects | other)?.length && ($userSidebar | async) === true">
          <p class="group-title">OTHER PROJECTS</p>
          <ul>
            <li *ngFor="let project of filteredProjects | other"
                (click)="selectProject(project?.slug)"
                [class.selected-item]="isSelected(project.slug)"
            >
              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
              <div class="star" (click)="$event.stopPropagation(); toggleProjectStar(project)">
                <i class="icon icon-star-gray icon-18px"></i>
              </div>
            </li>
          </ul>
        </div>

        <div class="readonly" *ngIf="(filteredProjects | readonly )?.length && ($userSidebar | async) === true">
          <p class="group-title">READONLY PROJECTS</p>
          <ul>
            <li *ngFor="let project of filteredProjects | readonly" (click)="selectProject(project?.slug)"
                [class.selected-item]="isSelected(project.slug)" class="item-leftsided">
              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div *ngIf="summary && profile?.isStaff" class="add-button">
      <a class="btn btn-block btn-add add-button"
         [routerLink]="filteredGroups?.length ? './groups/' + currentSlug + '/add' : './groups/add'"
         queryParamsHandling="preserve"
      >
        <i class="icon icon-add"></i>
        {{ ($userSidebar | async) ? 'Add group' : '' }}
      </a>
    </div>
  </div>
</div>
