import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClientFactoryService } from '../../../shared/services/http-client-factory.service';
import { ApiClient } from 'src/app/shared/services/api-client.model';
import { Injectable } from '@angular/core';
import { SummaryAdmin } from '../../interfaces/summary-admin.model';
import { Summary } from '../../interfaces/summary.model';
import { UserService } from '../../../shared/services/user/user.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {
  private http: ApiClient;
  public summarySubject: BehaviorSubject<Summary> = new BehaviorSubject<Summary>(null);
  public summaryAdminSubject: BehaviorSubject<SummaryAdmin> = new BehaviorSubject<SummaryAdmin>(null);

  get $summary(): Observable<SummaryAdmin | Summary> {
    return this.userService.profile?.isStaff ? this.summaryAdminSubject.asObservable() : this.summarySubject.asObservable();
  }

  get summary(): SummaryAdmin | Summary {
    return this.userService.profile?.isStaff ? this.summaryAdminSubject.value : this.summarySubject.value;
  }

  constructor(private httpFactory: HttpClientFactoryService,
              private userService: UserService) {
    this.http = this.httpFactory.getHttpClient('/summary/');
  }

  public async refreshSummaryAsync(): Promise<void> {
    if (this.userService.profile?.isStaff) {
      await this.getAdminSummary();
    } else {
      await this.getUserSummary();
    }
  }

  public getSummaryAsync(): Observable<Summary | SummaryAdmin> {
    return this.userService.profile.isStaff
      ? this.http.get<SummaryAdmin>('admin/').pipe(
        tap((summary: SummaryAdmin) => {
          this.summaryAdminSubject.next(summary);
        }))
      : this.http.get<Summary>('').pipe(tap((summary: Summary) => {
        this.summarySubject.next(summary);
      }));
  }

  private async getUserSummary(): Promise<void> {
    const userSummary = await this.http.get<Summary>('').toPromise();
    this.summarySubject.next(userSummary);
  }

  private async getAdminSummary(): Promise<void> {
    const summaryAdmin = await this.http.get<SummaryAdmin>('admin/').toPromise();
    this.summaryAdminSubject.next(summaryAdmin);
  }
}
