import { Observable, BehaviorSubject } from 'rxjs';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TableRowSettings } from '../../../main/models/table-row-settings';

import findDifferents from '../../utils/find-difference-in-objects';

@Component({
  selector: 'ffcrm-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('rotateArrow', [
      state('asc', style({
        transform: 'rotate(-180deg)'
      })),
      state('desc', style({
        transform: 'rotate(0)'
      })),
      state('initial', style({
        opacity: '0'
      })),
      transition('* => *', [animate('150ms linear')])
    ])
  ],
})
export class TableComponent implements OnInit, OnChanges {

  constructor() { }

  private tableData: Array<any>;
  private prevTableData: Array<any>;

  @ViewChild('tableContent') tableContent;

  @Input()
  public set data(data: Array<any>) {
    this.prevTableData = this.tableData;
    this.tableData = data;
    this.sortedData = data;
  }

  public get data(): Array<any> {
    return this.tableData;
  }

  private dataSubject = new BehaviorSubject(this.data);

  public get $data(): Observable<Array<any>> {
    return this.dataSubject.asObservable();
  }

  @Input() cellTemplates: Array<TableRowSettings>;
  @Input() skipCols: Array<TableRowSettings>;
  @Input() selectableRows = false;
  @Output() rowClicked: EventEmitter<any> = new EventEmitter<any>();

  sortedData: Array<any>;

  public ngOnInit(): void {
    if (this.data) {
      this.sortedData = [...this.data];
    }

    this.$data.subscribe(() => {
      if (this.tableData) {
        const cloneTableData = JSON.parse(JSON.stringify(this.tableData));
        if (this.tableContent && findDifferents(this.prevTableData, cloneTableData)?.length !== 1) {
          this.tableContent.nativeElement.scrollTop = 0;
        }
      }
    });
  }

  public ngOnChanges(): void {
    if (this.cellTemplates) {
      this.cellTemplates?.map((item: any) => {
        item.sortDirection = 'initial';
      });
    }
    if (this.data) {
      this.sortedData = [...this.data];
      if (JSON.stringify(this.dataSubject.value) !== JSON.stringify(this.data)) {
        this.dataSubject.next(this.data);
      }
    }
  }

  public rowClick(event): void {
    this.rowClicked.emit(event);
  }

  public sort(column): void {
    if (column.sort) {
      this.cellTemplates?.map((item: any) => {
        if (item.key !== column.key) {
          item.sortDirection = 'initial';
        }
      });
      if (column.sortDirection === 'initial') {
        this.sortedData.sort(column.sortCallbacks.asc);
        this.setSortDirection(column, 'asc');
      } else if (column.sortDirection === 'asc') {
        this.sortedData.sort(column.sortCallbacks.desc);
        this.setSortDirection(column, 'desc');
      } else {
        this.sortedData = [...this.data];
        this.setSortDirection(column, 'initial');
      }
    }
  }

  public setSortDirection(column, value): void {
    this.cellTemplates.map((item: any) => {
      if (item.key === column.key) {
        item.sortDirection = value;
      }
      return item;
    });
  }

  public colIncludes(column: TableRowSettings): boolean {
    return !!this.skipCols?.find((data: TableRowSettings) => data?.key === column?.key && data?.title === column?.title);
  }
}
