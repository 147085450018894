import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// Models
import { ApiClient } from 'src/app/shared/services/api-client.model';
import { Group } from '../../../shared/models/group.model';

// Services
import { HttpClientFactoryService } from '../../../shared/services/http-client-factory.service';
import { Paginate } from '../../interfaces/paginate.model';
import { UserProfile } from '../../../shared/models/user-profile.model';
import { IDragNDropService } from '../injection-tokens/drag-n-drop-token';


@Injectable({
  providedIn: 'root'
})
export class GroupService implements IDragNDropService {
  private http: ApiClient;
  public groupsSubject: BehaviorSubject<Array<Group>> = new BehaviorSubject<Array<Group>>([]);
  public currentGroupSubject: BehaviorSubject<Group> = new BehaviorSubject<Group>(null);

  get $groups(): Observable<Array<Group>> {
    return this.groupsSubject.asObservable();
  }

  get groups(): Array<Group> {
    return this.groupsSubject.value;
  }

  get items(): Array<Group> {
    return this.groupsSubject.value;
  }

  get $items(): Observable<Array<Group>> {
    return this.groupsSubject.asObservable();
  }

  get currentItem(): Group {
    return this.currentGroupSubject.value;
  }

  get $currentItem(): Observable<Group> {
    return this.currentGroupSubject.asObservable();
  }

  get $currentGroup(): Observable<Group> {
    return this.currentGroupSubject.asObservable();
  }

  get currentGroup(): Group {
    return this.currentGroupSubject.value;
  }

  constructor(private httpFactory: HttpClientFactoryService) {
    this.http = this.httpFactory.getHttpClient('/groups/');
  }

  public async getGroups(): Promise<Array<Group>> {
    const groups = await this.http.get<Paginate<Group>>('').toPromise();
    this.groupsSubject.next(groups?.results);
    return groups?.results;
  }

  public async getGroup(slug: string): Promise<void> {
    try {
      const group = await this.http.get<Group>(`${slug}/`).toPromise();
      this.currentGroupSubject.next(group);
    } catch (e) {
      console.error(e);
    }
  }

  public getGroupAsync(slug: string): Observable<Group> {
    return this.http.get<Group>(`${slug}/`).pipe(tap((group: Group) => {
      if (group) {
        this.currentGroupSubject.next(group);
      }
    }));
  }

  public getGroupsAsync(): Observable<Array<Group>> {
    return this.http.get<Paginate<Group>>('').pipe(
      map((groups: Paginate<Group>) => {
        return groups?.results;
      }),
      tap((groups: Array<Group>) => {
        this.groupsSubject.next(groups);
      }),
    );
  }

  public async refreshGroup(): Promise<void> {
    await this.getGroup(this.currentGroupSubject.value?.slug);
  }

  public async starGroup(groupSlug): Promise<void> {
    try {
      await this.http.post(`${groupSlug}/star/`, null).toPromise();
      await this.getGroups();
    } catch (e) {
      console.error(e);
    }
  }

  public async unStarGroup(groupSlug): Promise<void> {
    try {
      await this.http.delete(`${groupSlug}/star/`).toPromise();
      await this.getGroups();
    } catch (e) {
      console.error(e);
    }
  }

  public async createGroup(data): Promise<void> {
    await this.http.post('', data).toPromise();
    await this.getGroups();
  }

  public async updateGroup(slug, data): Promise<void> {
    try {
      const group = await this.http.put<Group>(`${slug}/`, data).toPromise();
      await this.getGroup(group?.slug || slug);
      await this.getGroups();
    } catch (e) {
      console.error(e);
    }
  }

  public updateMembers(slug: string, members: { members: Array<UserProfile> }): Observable<void> {
    return this.http.post(`${slug}/members/`, members);
  }

  public async getManageItemInstance(slug: string): Promise<Group> {
    return await this.http.get<Group>(`${slug}/`).toPromise();
  }
}
