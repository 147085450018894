import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Group } from '../../shared/models/group.model';
import { Observable } from 'rxjs';
import { UserService } from '../../shared/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserGroupsResolver implements Resolve<Array<Group>>{

  constructor(private userService: UserService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Group>> | Promise<Array<Group>> | Array<Group> {
    return this.userService.getUserGroupsAsync(route.params?.userId);
  }
}
