import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from '../../auth/services/auth.service';
import {catchError} from 'rxjs/operators';
import { cases, keysToCase } from '../utils/case-to-case';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        this.authService.logout();
        location.reload();
      }

      if (err instanceof HttpErrorResponse) {
        err = { ...err, error: keysToCase(err?.error, cases.camel) };
      }

      return throwError(err);
    }));
  }
}
