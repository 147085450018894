import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// Models
import { DragNDropModalState } from '../../../main/models/drag-n-drop-modal-state.model';
import { Paginate } from '../../../main/interfaces/paginate.model';
import { UserProfile } from '../../models/user-profile.model';
import { Profile } from '../../models/profile.model';
import { Group } from '../../models/group.model';
import { ApiClient } from '../api-client.model';

// Services
import { HttpClientFactoryService } from '../http-client-factory.service';

const SIDEBAR = 'sidebar';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private httpFactory: HttpClientFactoryService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.http = this.httpFactory.getHttpClient('/users/');

    if (!localStorage.getItem(SIDEBAR)) {
      localStorage.setItem(SIDEBAR, 'true');
    }
  }

  private http: ApiClient;
  private userGroupsSubj: BehaviorSubject<Group[]> = new BehaviorSubject<Group[]>([]);
  private userSidebarSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(localStorage.getItem(SIDEBAR) === 'true');
  public usersSubj: BehaviorSubject<Array<UserProfile>> = new BehaviorSubject<Array<UserProfile>>([]);
  public userProfileSubj: BehaviorSubject<UserProfile | Profile> = new BehaviorSubject<UserProfile | Profile>(null);
  public profileSubj: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(null);

  public dragNDropModalState: DragNDropModalState = {
    refresh: false,
    slug: '',
    open: false,
    mode: 'group',
  };

  get $profile(): Observable<Profile> {
    return this.profileSubj.asObservable();
  }

  get profile(): Profile {
    return this.profileSubj.value;
  }

  get $users(): Observable<Array<UserProfile>> {
    return this.usersSubj.asObservable();
  }

  get users(): Array<UserProfile> {
    return this.usersSubj.value;
  }

  get $userGroups(): Observable<Group[]> {
    return this.userGroupsSubj.asObservable();
  }

  get $userSidebar(): Observable<boolean> {
    return this.userSidebarSubj.asObservable();
  }

  get userSidebar(): boolean {
    return this.userSidebarSubj.value;
  }

  get $userProfile(): Observable<Profile | UserProfile> {
    return this.userProfileSubj.asObservable();
  }

  get userProfile(): Profile | UserProfile {
    return this.userProfileSubj.value;
  }

  public async getProfile(): Promise<Profile> {
    try {
      if (!this.profileSubj.value) {
        const profile = await this.http.get<Profile>('profile/').toPromise();
        this.profileSubj.next(profile);
        return profile;
      } else {
        return this.profileSubj.value;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  public toggleSidebar(): void {
    this.userSidebarSubj.next(!this.userSidebarSubj.value);
    localStorage.setItem(SIDEBAR, this.userSidebarSubj.value ? 'true' : 'false');
  }

  public async appInit(): Promise<boolean> {
    if (localStorage.getItem('token')) {
      await this.getProfile();
    }

    return true;
  }

  public getProfileAsync(): Observable<Profile> {
    return this.http.get<Profile>('profile/').pipe(tap((profile: Profile) => {
      this.profileSubj.next(profile);
    }));
  }

  public async updateProfile(data: Profile): Promise<Profile> {
    const newProfile = await this.http.put<Profile>('profile/', data).toPromise();
    this.profileSubj.next(newProfile);

    return newProfile;
  }

  public async getAllUsers(): Promise<Array<UserProfile>> {
    return await this.getAllUsersAsync().toPromise();
  }

  public getAllUsersAsync(userFilter?: { isActive?: boolean, isSuperUser?: boolean, project?: string }): Observable<Array<UserProfile>> {
    let params = new HttpParams();

    if (userFilter?.hasOwnProperty('isActive')) {
      params = params.set('is_active', userFilter?.isActive?.toString());
    } else if (this.activatedRoute.snapshot.queryParams['user-filter']) {
      switch (this.activatedRoute.snapshot.queryParams['user-filter']) {
        case 'all':
          break;
        case 'active': {
          params = params.set('is_active', 'true');
          break;
        }
        case 'blocked': {
          params = params.set('is_active', 'false');
          break;
        }
      }
    }

    if (userFilter?.hasOwnProperty('isSuperUser')) {
      params = params.set('is_superuser', userFilter?.isSuperUser?.toString());
    }

    if (userFilter?.hasOwnProperty('project') && userFilter?.project !== 'all') {
      params = params.set('project', userFilter?.project);
    } else if (
      this.activatedRoute.snapshot.queryParams?.project &&
      this.activatedRoute.snapshot.queryParams?.project !== 'all' &&
      !userFilter?.hasOwnProperty('project')
    ) {
      params = params.set('project', this.activatedRoute.snapshot.queryParams?.project);
    }

    return this.http.get<Paginate<UserProfile>>('', params).pipe(
      map((users: Paginate<UserProfile>) => {
        return users?.results || [];
      }),
      tap((users: Array<UserProfile>) => {
        this.usersSubj.next(users);
      })
    );
  }

  public getUserAsync(userId: number): Observable<UserProfile> {
    return this.http.get<UserProfile>(`${userId}/`)
      .pipe(
        tap((response) => this.userProfileSubj.next(response))
      );
  }

  public getUserGroupsAsync(userId: number): Observable<Array<Group>> {
    return this.http.get<Paginate<Group>>(`${userId}/groups/`).pipe(map((groups: Paginate<Group>) => {
      return groups?.results;
    }));
  }

  public blockUser(id: number, body: object): Observable<object> {
    return this.http.post(`${id}/block/`, body);
  }

  public async createUser(data: Profile): Promise<Profile> {
    return await this.http.post<Profile>('', data).toPromise();
  }

  public async updateUser(id: number, data: Profile): Promise<Profile> {
    return this.http.put<Profile>(`${id}/`, data).pipe(
      tap((response) => {
        this.userProfileSubj.next(response);
      }
    )).toPromise();
  }

  public openAddUserDragAndDropDialog(slug: string): void {
    this.dragNDropModalState = {
      refresh: false,
      mode: 'group',
      open: true,
      slug,
    };
  }

}
