import { Pipe, PipeTransform } from '@angular/core';
import { GroupProject } from '../models/group-project.model';
import { Group } from '../models/group.model';
import { Project } from '../models/project.model';

@Pipe({ name: 'inactive' })
export class InactiveProjectGroupPipe implements PipeTransform {
  transform(value: Array<GroupProject | Group | Project>, ...args: unknown[]): Array<GroupProject | Group | Project> {
    return value?.filter(item => !item?.isActive);
  }
}
