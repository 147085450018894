import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

// Components
import { FormComponent } from '../../../shared/super/form-component';

// Models
import { TaskDifficulty } from '../../../shared/models/task-difficulty.model';
import { ManageProject } from '../../../shared/models/manage-project.model';
import { Project } from '../../../shared/models/project.model';
import { Task } from 'src/app/shared/models/task.model';

// Services
import { AlertService } from '../../../shared/services/alert.service';
import { TaskService } from '../../services/task/task.service';
import { SummaryService } from '../../services/summary/summary.service';


@Component({
  selector: 'ffcrm-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.scss']
})
export class TaskEditComponent extends FormComponent implements OnInit, OnDestroy {

  constructor(
    private taskService: TaskService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private summaryService: SummaryService,
  ) {
    super();

    this.subscriptions.push(
      this.activatedRoute.data.subscribe((routeData: Data) => {
        this.currentTask = routeData?.currentTask?.task;
        this.taskDifficulties = routeData?.difficulties;

        this.loadTask();
      }),

      this.activatedRoute?.parent?.params?.subscribe(async (parentParams: Params) => {
        this.currentProject = parentParams?.currentProject;
        this.projectSlug = parentParams?.projectSlug;
        this.taskId = parentParams?.taskId;

        if (!parentParams?.taskId) {
          this.activatedRoute?.params?.subscribe(async (params: Params) => {
            this.taskId = params?.taskId || this.taskId;
            this.wishRedirect = !!params?.taskId;

            if (!params?.projectSlug && !parentParams?.projectSlug) {
              this.activatedRoute?.parent.parent.params.subscribe((parentParams2: Params) => {
                this.projectSlug = parentParams2?.projectSlug;
              }).unsubscribe();
            }
          }).unsubscribe();
        }
      }),
    );
  }

  private taskId: number;
  private wishRedirect = false;
  private currentTask: Task;
  private currentProject: Project | ManageProject;
  private projectSlug: string;
  public taskDifficulties: Array<TaskDifficulty> = [];
  public isDelete: boolean;

  private loadTask(): void {
    this.form = this.fb.group({
      title: [this.currentTask?.title || '', [Validators.required]],
      isExtraTime: [this.currentTask?.isExtraTime || false, [Validators.required]],
      description: [this.currentTask?.description || '', [Validators.required]],
      hours: [this.currentTask?.minutes || null],
      link: [this.currentTask?.link || ''],
      difficulty: [typeof this.currentTask?.difficulty === 'number' ? this.currentTask?.difficulty : this.currentTask?.difficulty?.id || ''],
    });
  }

  public async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      this.taskService.$currentTask.subscribe((result: Task) => {
        if (result) {
          this.loadTask();
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public async onStateChange(): Promise<void> {
    this.activatedRoute.queryParams.subscribe(async (queries: Params) => {
      if (this.wishRedirect) {
        await this.router?.navigate(['../../../'], {
          relativeTo: this.activatedRoute,
          replaceUrl: true,
          queryParams: queries
        });
      } else {
        await this.router?.navigate(['../'], {
          relativeTo: this.activatedRoute,
          replaceUrl: true,
          queryParams: queries
        });
      }
    }).unsubscribe();
  }

  public openDeleteModal(): void {
    this.isDelete = true;
  }

  public closeDeleteModal(): void {
    this.isDelete = false;
  }

  public async deleteTask(): Promise<void> {
    try {
      await this.taskService.removeTask(this.projectSlug, this.taskId);
      await this.alertService.showAlert({
        message: 'Task was successfully removed.',
        success: true
      });

      this.form.reset();
      await this.router.navigate(['../../../'], { relativeTo: this.activatedRoute, replaceUrl: true, queryParamsHandling: 'merge' });
      this.isDelete = false;
    } catch (e) {
      console.error(e);
      await this.alertService.showAlert({
        message: `${e?.error?.detail}`,
        success: false
      });
    }
    this.summaryService.refreshSummaryAsync();
  }

  public async manageAndClose(): Promise<void> {
    try {
      this.form.markAllAsTouched();

      if (this.form.valid) {
        if (this.taskId) {
          const task = this.form.value;
          if (this.form.value?.title === this.currentTask?.title) {
            delete task.title;
          }
          await this.taskService.editTask(this.currentProject?.slug || this.projectSlug, this.taskId, task);
        } else {
          await this.taskService.addTask(this.currentProject?.slug, this.form.value);
        }

        await this.alertService.showAlert({
          success: true,
          message: 'Task was successfully edited.'
        });
        this.form.reset();
        await this.onStateChange();
      }
    } catch (e) {
      await this.getErrorsFromResponse(e.error);
    }
  }
}
