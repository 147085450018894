import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CropperComponent } from 'angular-cropperjs';

@Component({
  selector: 'ffcrm-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

  constructor() { }

  @ViewChild('cropper') public cropper: CropperComponent;
  @ViewChild('range') public range;

  // tslint:disable-next-line:variable-name
  private _cropperDialog: boolean;

  // tslint:disable-next-line:variable-name
  private _image: string;
  private type;

  @Input()
  set image(val: string) {
    if (this.range && this.range.nativeElement) {
      this.range.nativeElement.value = 50;
    }
    this._image = val;
    this.type = ImageCropperComponent.base64MimeType(val);
  }

  get image(): string {
    return this._image;
  }

  @Input()
  set cropperDialog(val: boolean) {
    this._cropperDialog = val;
  }

  get cropperDialog(): boolean {
    return this._cropperDialog;
  }

  @Output() croppedImage = new EventEmitter();
  @Output() cropperDialogChange = new EventEmitter();

  public config = {
    dragMode: 'move',
    aspectRatio: 1,
    movable: false,
    rotatable: false,
    background: false,
    guides: false,
    center: false,
    autoCropArea: 0.5,
    cropBoxResizable: false,

  };

  private static base64MimeType(encoded): string {
    let result = null;

    if (typeof encoded !== 'string') {
      return result;
    }

    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
      result = mime[1];
    }

    return result;
  }

  ngOnInit(): void {
  }

  public visibleChange(val: boolean): void {
    this.cropperDialogChange.emit(val);
  }

  public cropImage(): void {
    this.cropperDialogChange.emit(false);
    this.croppedImage.emit(this.cropper.cropper.getCroppedCanvas().toDataURL(this.type));
  }

  public changeSize(): void {
    const canvasData = this.cropper.cropper.getCanvasData();
    const minSize = canvasData.height >= canvasData.width ? canvasData.width : canvasData.height;
    const newSize = minSize * this.range?.nativeElement.value / 100;
    this.cropper.cropper.setCropBoxData({
      height: newSize,
      width: newSize
    });
  }

}
