import { UserProfile } from '../../shared/models/user-profile.model';

export class UserCheckbox {
  id: number;
  firstName: string;
  lastName: string;
  checked: boolean;

  constructor(user: UserProfile) {
    this.id = user.id;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.checked = false;
  }

}
