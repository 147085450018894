import { AbstractControl, ValidationErrors } from '@angular/forms';
import { getDate } from '../utils/getDate';

export const taskDateValidator = (control: AbstractControl): ValidationErrors => {
  const today = new Date(Date.now());
  const value = getDate(control?.value);

  if (!control.value) {
    return { error: 'All required fields must be filled' };
  }

  if (today < value) {
    return { error: 'You can not add time in the future' };
  }

  return null;
};

export const taskTimeValidator = (control: AbstractControl): ValidationErrors => {
  if (!control.value) {
    return { error: 'Spent time can not be less or equal to 0m' };
  }

  if (1 > +control?.value) {
    return { error: 'Spent time can not be less or equal to 0m' };
  }

  return null;
};

export const taskLinkValidator = (control: AbstractControl): ValidationErrors => {
  // if (control?.value?.length > 0) { // TODO: if empty is ok. if not empty but not url - ban
  //   return { error: 'All required fields must be filled' };
  // }

  if (!RegExp(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+\-~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig).test(control.value)
    && control?.value?.length > 0) {
    return { error: 'Please, enter a valid URL' };
  }

  return null;
};
