import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { OvertimeModalComponent } from './components/overtime-modal/overtime-modal.component';
import { UserParticipationsModalComponent } from './components/user-participations-modal/user-participations-modal.component';
import { AddUsersDrugAndDropComponent } from './components/add-users-drug-and-drop/add-users-drug-and-drop.component';
import { CurrentProjectComponent } from './components/current-project/current-project.component';
import { UserComponentComponent } from './components/user-component/user-component.component';
import { ProfileModalComponent } from '../shared/components/profile-modal/profile-modal.component';
import { ProjectsListComponent } from './components/projects-list/projects-list.component';
import { ProjectModalComponent } from './components/project-modal/project-modal.component';
import { CurrentTaskComponent } from './components/current-task/current-task.component';
import { GroupModalComponent } from './components/group-modal/group-modal.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { TaskEditComponent } from './components/task-edit/task-edit.component';
import { AddTaskComponent } from './components/add-task/add-task.component';
import { AddTimeComponent } from './components/add-time/add-time.component';
import { AllUsersComponent } from './pages/all-users/all-users.component';
import { OvertimeComponent } from './pages/overtime/overtime.component';
import { HomeComponent } from './pages/home/home.component';
import { RootComponent } from './root/root.component';

// Guards
import { ProjectYearMonthGuard } from './guards/project-year-month.guard';
import { TaskYearMonthGuard } from './guards/task-year-month.guard';
import { LeaveEditGuard } from './guards/leave-edit.guard';
import { GoogleRedirectGuard } from './guards/google-redirect.guard';
import { AuthGuard } from '../shared/helpers/auth.guard';
import { RedirectGuard } from './guards/redirect.guard';
import { AdminGuard } from './guards/admin.guard';
import { UserGuard } from './guards/user.guard';

// Resolvers
import { ProjectsAllResolver } from './resolvers/all-users/projects-all/projects-all-resolver.service';
import { TimeEntriesActivitiesResolver } from './resolvers/task-activities-resolver.service';
import { TaskDifficultiesResolver } from './resolvers/task-difficulties-resolver.service';
import { UserProjectsResolver } from './resolvers/all-users/user-projects/user-projects-resolver.service';
import { ManageProjectResolver } from './resolvers/manage-project-resolver.service';
import { UserGroupsResolver } from './resolvers/user-groups-resolver.service';
import { UserTasksResolver } from './resolvers/user-tasks-resolver.service';
import { GroupsProjectResolver } from './resolvers/groups-resolver.service';
import { SummaryResolver } from './resolvers/summary-resolver.service';
import { ProjectResolver } from './resolvers/project-resolver.service';
import { GroupResolver } from './resolvers/group-resolver.service';
import { UsersResolver } from './resolvers/users-resolver.service';
import { UserResolver } from './resolvers/user-resolver.service';
import { TaskResolver } from './resolvers/task-resolver.service';
import { OvertimeResolver } from './resolvers/overtime-resolver.service';
import { OvertimeYearMonthGuard } from './guards/overtime-year-month.guard';
import { SingleOvertimeResolver } from './resolvers/single-overtime.service';

const projectRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    children: [],
    canActivate: [RedirectGuard],
  },
  {
    path: 'google-auth',
    pathMatch: 'full',
    children: [],
    canActivate: [GoogleRedirectGuard],
  },
  {
    path: 'groups',
    component: ProjectsListComponent,
    canActivate: [AdminGuard],
    data: {
      noGroups: true,
    },
    children: [
      {
        path: 'add',
        component: GroupModalComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
      },
    ]
  },
  {
    path: 'groups/:groupSlug',
    component: ProjectsListComponent,
    canActivate: [AdminGuard],
    resolve: {
      currentGroup: GroupResolver,
    },
    children: [
      {
        path: 'users',
        component: AddUsersDrugAndDropComponent,
        pathMatch: 'full',
      },
      {
        path: 'add',
        component: GroupModalComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
      },
      {
        path: 'edit',
        component: GroupModalComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
      },
      {
        path: 'projects/create',
        component: ProjectModalComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'groups/:groupSlug/projects/:projectSlug',
    component: CurrentProjectComponent,
    canActivate: [AdminGuard, ProjectYearMonthGuard],
    resolve: {
      currentGroup: GroupResolver,
      currentProject: ManageProjectResolver,
    },
    children: [
      {
        path: 'users',
        component: AddUsersDrugAndDropComponent,
        pathMatch: 'full',
      },
      {
        path: 'edit',
        component: ProjectModalComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
      },
      {
        path: 'task/:taskId/edit',
        component: TaskEditComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
        resolve: {
          currentTask: TaskResolver,
        },
      },
    ],
  },
  {
    path: 'groups/:groupSlug/projects/:projectSlug/tasks/:taskId',
    component: CurrentTaskComponent,
    canActivate: [AdminGuard],
    resolve: {
      currentTask: TaskResolver,
      currentGroup: GroupResolver,
      currentProject: ManageProjectResolver,
    },
    children: [
      {
        path: 'edit',
        component: TaskEditComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
      },
      {
        path: 'time-entry/add',
        component: AddTimeComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
      },
      {
        path: 'time-entry/:timeEntryId',
        component: AddTimeComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'projects/:projectSlug/tasks/:taskId',
    component: CurrentTaskComponent,
    canActivate: [UserGuard, ProjectYearMonthGuard],
    resolve: {
      currentTask: TaskResolver,
    },
    children: [
      {
        path: 'edit',
        component: TaskEditComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
      },
      {
        path: 'time-entry/add',
        component: AddTimeComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
      },
      {
        path: 'time-entry/:timeEntryId',
        component: AddTimeComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'projects',
    pathMatch: 'full',
    component: CurrentProjectComponent,
    canActivate: [UserGuard],
    data: {
      noProjects: true,
    },
  },
  {
    path: 'projects/:projectSlug',
    component: CurrentProjectComponent,
    canActivate: [UserGuard, ProjectYearMonthGuard],
    resolve: {
      currentProject: ProjectResolver,
    },
    children: [
      {
        path: 'task/add',
        component: AddTaskComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
      },
      {
        path: 'task/:taskId/add-time',
        component: AddTimeComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
        resolve: {
          currentTask: TaskResolver,
        },
      },
      {
        path: 'task/:taskId/edit-time',
        component: AddTimeComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
        resolve: {
          currentTask: TaskResolver,
        },
      },
      {
        path: 'task/:taskId/edit',
        component: TaskEditComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
        resolve: {
          currentTask: TaskResolver,
        },
      },
      {
        path: 'edit',
        component: ProjectModalComponent,
        canDeactivate: [LeaveEditGuard],
        pathMatch: 'full',
      },
    ],
  },
];

const allUsersRoutes: Routes = [
  {
    path: '',
    component: UsersListComponent,
    children: [
      {
        path: 'add',
        component: ProfileModalComponent,
        data: {
          mode: 'add',
        },
      },
    ],
  },
  {
    path: ':userId',
    component: UserComponentComponent,
    canActivate: [AuthGuard, TaskYearMonthGuard],
    resolve: {
      user: UserResolver,
      groups: UserGroupsResolver,
      tasks: UserTasksResolver,
      userProjects: UserProjectsResolver,
    },
    children: [
      {
        path: 'edit',
        component: ProfileModalComponent,
        data: {
          mode: 'edit',
        },
      },
      {
        path: 'participations',
        component: UserParticipationsModalComponent,
      },
    ],
  },
];

export const routes: Routes = [
  {
    path: '',
    component: RootComponent,
    resolve: {
      sumamry: SummaryResolver,
      items: GroupsProjectResolver,
    },
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
        children: projectRoutes,
        data: {
          usersFilter: {
            isSuperUser: false,
          },
        },
        resolve: {
          users: UsersResolver,
          activities: TimeEntriesActivitiesResolver,
          difficulties: TaskDifficultiesResolver,
        },
      },
      {
        path: 'all-users',
        component: AllUsersComponent,
        canActivate: [AuthGuard],
        resolve: {
          users: UsersResolver,
          allProjects: ProjectsAllResolver,
        },
        children: allUsersRoutes,
      },
      {
        path: 'overtime',
        component: OvertimeComponent,
        canActivate: [AuthGuard, OvertimeYearMonthGuard],
        resolve: {
          overtimes: OvertimeResolver,
          activities: TimeEntriesActivitiesResolver,
          users: UsersResolver,
        },
        children: [
          {
            path: 'add',
            component: OvertimeModalComponent,
            canActivate: [UserGuard],
            pathMatch: 'full',
          },
          {
            path: 'edit/:overtimeId',
            component: OvertimeModalComponent,
            canActivate: [UserGuard],
            resolve: {
              singleOvertime: SingleOvertimeResolver,
            },
            pathMatch: 'full',
          }
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule {
}
