<ffcrm-dialog
  [visible]="true"
  (visibleChange)="onStateChange()"
  [title]="'Add task'"
  [closable]="true"
  backButton="true"
  [backButtonEnabled]="formStep!==1"
  (backButtonClicked)="formStep = 1"
  >

  <div class="dialog-body">
    <form [formGroup]="form">
      <div [class.hidden-input]="formStep!=1" class="fields-first-step">
        <div class="input-block">
          <label for="title">Title</label>
          <ffcrm-autocomplete id="title"
                              (valueChange)="titleAutocomplete($event)"
                              (selected)="selectTask($event)"
                              (focusout)="selectTask()"
                              (blur)="selectTask()"
                              [canWrite]="true"
                              [options]="tasks"
                              labelKey="title"
                              formControlName="title"
                              ngDefaultControl></ffcrm-autocomplete>
          <div class="error-msg" [class.visible]="form.get('title').invalid && (form.get('title')?.touched || form.get('title')?.dirty)">
            <div *ngFor="let error of getErrors(form.get('title'))">{{ error }}</div>
          </div>
        </div>

        <div class="checkbox-block">
          <div class="checkbox-control-wrapper">
            <input type="checkbox" id="isExtraTime" formControlName="isExtraTime">
            <label for="isExtraTime">Extra time</label>
            <div class="tooltip-icon tooltip-trigger">?</div>
            <div class="tooltip-message">
              Time spent on tasks that are out of the estimation scope
            </div>
          </div>
        </div>

        <div class="input-block">
          <label>Task complexity</label>
          <ffcrm-select type="difficulty" classList="form-select select" labelKey="title" [options]="taskDifficulties" formControlName="difficulty" ngDefaultControl></ffcrm-select>
        </div>

        <div class="error-msg" [class.visible]="form.get('difficulty').invalid && (form.get('difficulty')?.touched || form.get('difficulty')?.dirty)">
          <div *ngFor="let error of getErrors(form.get('difficulty'))">{{ error }}</div>
        </div>

        <div class="input-block">
          <label for="description">Comment</label>
          <textarea id="description" class="input-field textarea" formControlName="description" rows="5" style="resize: none"></textarea>
          <div class="error-msg" [class.visible]="form.get('description')?.invalid && (form.get('description')?.touched || form.get('description')?.dirty)">
            <div *ngFor="let error of getErrors(form.get('description'))">{{ error }}</div>
          </div>
        </div>

        <div class="input-block">
          <label for="link">External link</label>
          <input type="text" id="link" class="input-field" placeholder="Add external link (Optional)" formControlName="link" />

          <div class="error-msg" [class.visible]="form.get('link')?.invalid && (form.get('link')?.touched || form.get('link')?.dirty)">
            <div *ngFor="let error of getErrors(form.get('link'))">{{ error }}</div>
          </div>
        </div>
        <div class="actions">
          <button type="submit" class="btn btn-light btn-block" (click)="onStateChange()">Cancel</button>
          <button type="submit" class="btn btn-block" (click)="goToNexStep()" [disabled]="isFirstStepValid">Next</button>
        </div>
      </div>
      <div [class.hidden-input]="formStep!=2" class="fields-second-step">
        <div class="input-block">
          <label>Activity</label>
          <ffcrm-select classList="select form-select" [labelKey]="'activity'" [options]="timeEntryActivities" formControlName="activity" ngDefaultControl></ffcrm-select>
        </div>

        <div class="error-msg" [class.visible]="form.get('activity').invalid && (form.get('activity')?.touched || form.get('activity')?.dirty)">
          <div *ngFor="let error of getErrors(form.get('activity'))">{{ error }}</div>
        </div>

        <div class="checkbox-block">
          <div class="checkbox-control-wrapper">
            <input type="checkbox" id="isOvertime" formControlName="isOvertime">
            <label for="isOvertime">Overtime</label>
            <div class="tooltip-icon tooltip-trigger">?</div>
            <div class="tooltip-message">
              Work that has been done out of the working time
            </div>
          </div>
          <div class="input-block">
            <div class="pickers">
              <div>
                <label>Date</label>
                <ffcrm-datepicker formControlName="date" ngDefaultControl></ffcrm-datepicker>
              </div>
              <div>
                <label>Time</label>
                <ffcrm-timepicker formControlName="minutes" ngDefaultControl></ffcrm-timepicker>
              </div>

              <div class="error-msg" [class.visible]="form.get('date')?.invalid && (form.get('date')?.touched || form.get('date')?.dirty)">
                <div *ngFor="let error of getErrors(form.get('date'))">{{ error }}</div>
              </div>
              <div class="error-msg" [class.visible]="form.get('minutes')?.invalid && (form.get('minutes')?.touched || form.get('minutes')?.dirty)">
                <div *ngFor="let error of getErrors(form.get('minutes'))">{{ error }}</div>
              </div>
            </div>
          </div>

          <div class="actions">
            <button type="submit" class="btn btn-block" [disabled]="form.invalid" (click)="createAndNew()">Creat & New</button>
            <button type="submit" class="btn btn-block" [disabled]="form.invalid" (click)="createAndClose()">Create & Close</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ffcrm-dialog>
