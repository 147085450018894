import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { SummaryAdmin } from '../interfaces/summary-admin.model';
import { Summary } from '../interfaces/summary.model';

import { SummaryService } from '../services/summary/summary.service';

@Injectable({
  providedIn: 'root'
})
export class SummaryResolver implements Resolve<Summary | SummaryAdmin>{

  constructor(private summaryService: SummaryService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Summary | SummaryAdmin> {
    return this.summaryService.getSummaryAsync();
  }
}
