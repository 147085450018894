<ffcrm-dialog
  [visible]="true"
  (visibleChange)="dialogVisibleChange()"
  [closable]="true"
  [title]="project ? 'Edit project' : 'Add new project'"
>
  <div class="dialog-body">
    <form [formGroup]="form">
      <div class="fields">
        <div class="left">

          <div class="input-block">
            <div class="radio-row">
              <label for="title">Title</label>

              <div class="radio">
                <input class="billable" type="radio" name="isBillable" id="billable" formControlName="isBillable"
                       [value]="true">
                <label for="billable"></label>

                <input class="non-billable" type="radio" name="isBillable" id="non-billable"
                       formControlName="isBillable" [value]="false">
                <label for="non-billable"></label>
              </div>

            </div>

            <input type="text" id="title" class="input-field" placeholder="Enter project name" formControlName="name"
                   (input)="createSlug()"
                   [class.error]="form.get('name').invalid && form.get('name').touched" />

            <div class="error-msg" [class.visible]="form.get('name')?.invalid && (form.get('name')?.touched || form.get('name')?.dirty)">
              <div *ngFor="let error of getErrors(form.get('name'))">{{ error }}</div>
            </div>
          </div>

          <div class="input-block">
            <div class="tooltip-block">
              <label for="slug">Slug</label>
              <div class="tooltip-icon tooltip-trigger">?</div>
              <div class="tooltip-message">
                A "slug" is a way of generating a valid URL, generally using data already obtained. For instance, a slug
                uses the title of an article to generate a URL
              </div>
            </div>
            <input type="text" id="slug" class="input-field" formControlName="slug" (input)="clearBackErrors()"
                   [class.error]="form.get('slug').invalid && form.get('slug').touched" />

            <div class="error-msg" [class.visible]="form.get('slug')?.invalid && (form.get('slug')?.touched || form.get('slug')?.dirty)">
              <div *ngFor="let error of getErrors(form.get('slug'))">{{ error }}</div>
            </div>
          </div>

          <div class="input-block">
            <label>Group</label>
            <ffcrm-select classList="form-select select" [options]="activeGroups" idKey="slug" labelKey="name" formControlName="group"></ffcrm-select>

            <div class="error-msg" [class.visible]="form.get('group')?.invalid && (form.get('group')?.touched || form.get('group')?.dirty)">
              <div *ngFor="let error of getErrors(form.get('group'))">{{ error }}</div>
            </div>

          </div>

          <div class="input-block">
            <label for="estimation">Estimation</label>
            <input type="text" id="estimation" class="input-field" placeholder="0h" formControlName="estimation"
                   (input)="clearBackErrors()"
                   [class.error]="form.get('estimation').invalid && form.get('estimation').touched" />

            <div class="error-msg" [class.visible]="form.get('estimation')?.invalid && (form.get('estimation')?.touched || form.get('estimation')?.dirty)">
              <div *ngFor="let error of getErrors(form.get('estimation'))">{{ error }}</div>
            </div>
          </div>

          <div class="input-block">
            <label for="overtimesRate">Overtime rate</label>
            <input type="text" id="overtimesRate" class="input-field" placeholder="1.0" formControlName="overtimesRate"
                   (input)="clearBackErrors()"
                   [class.error]="form.get('overtimesRate').invalid && form.get('overtimesRate').touched">

            <div class="error-msg" [class.visible]="form.get('overtimesRate')?.invalid && (form.get('overtimesRate')?.touched || form.get('overtimesRate')?.dirty)">
              <div *ngFor="let error of getErrors(form.get('overtimesRate'))">{{ error }}</div>
            </div>
          </div>

          <div class="input-block">
            <div class="add-photo">
              <input #photoInput type="file" id="group_image" accept="image/gif, image/jpeg, image/png" (change)="imageSelect($event)">
              <label for="group_image">
                <div class="image">
                  <img src="{{ image ? image : '../assets/images/no-image.svg' }}" [style.padding]="image ? '0': ''"
                       alt="{{ form.get('name')?.value }}">
                </div>
                <span>{{ image ? 'Edit photo' : 'Add photo' }}</span>
              </label>
            </div>

            <div class="checkboxes">
              <div class="checkbox-block">
                <input type="checkbox" id="is_active" formControlName="isActive">
                <label for="is_active">Is active</label>
              </div>
              <div class="checkbox-block">
                <input type="checkbox" id="isReadonly" formControlName="isReadonly">
                <label for="isReadonly">Is readonly</label>
              </div>
            </div>
          </div>

        </div>
        <div class="right">
          <div class="head">
            <h4>Members</h4>
            <span class="users-amount">{{ selectedUsers.length || 0 }} users</span>
          </div>
          <div class="search">
            <div class="input-block">
              <i class="icon icon-search search-icon"></i>
              <input #search class="input-field input-search" type="text" id="search" placeholder="Search"
                     (input)="filterUsers()">
            </div>
          </div>
          <div class="users-list" *ngIf="users">
            <ul>
              <li *ngFor="let user of filteredUsers">
                <div class="checkbox-block">
                  <input type="checkbox" id="user_{{user.id}}" [checked]="user.checked" value="{{user.id}}"
                         (change)="membersCheckboxChange($event)">
                  <label for="user_{{user.id}}">{{user.firstName}} {{user.lastName}}</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="actions">
        <button *ngIf="project" type="submit" class="btn btn-block" (click)="update()" [disabled]="form.invalid">Save changes</button>
        <button *ngIf="!project" type="submit" class="btn btn-block" (click)="createAndNew()" [disabled]="form.invalid">Create & New
        </button>
        <button *ngIf="!project" type="submit" class="btn btn-block" (click)="createAndClose()" [disabled]="form.invalid">Create & Close</button>
      </div>
    </form>
  </div>
</ffcrm-dialog>

<ffcrm-image-cropper [(cropperDialog)]="imageCropper" [image]="croppedImage" (croppedImage)="getCroppedImage($event)">
</ffcrm-image-cropper>
