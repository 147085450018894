<div class="table" *ngIf="cellTemplates" tabindex="0" #tableContent>
  <div class="table-row table-header">
    <ng-container *ngFor="let column of cellTemplates">
      <div class="head" *ngIf="!colIncludes(column)"
           [ngClass]="(column?.sort ? 'sortable': '' ) + ' ' + column?.classes"
           (click)="sort(column)">
        <ng-container *ngIf="column && !colIncludes(column)">
          {{ column?.title }}
          <i [@rotateArrow]="column?.sortDirection" class="fas fa-caret-down"></i>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="table-content">
    <div class="table-row"
        *ngFor="let row of sortedData"
        (click)="rowClick(row)"
        [class.with-pointer]="selectableRows"
        [class.new-row]="row.isNew"
    >
      <ng-container *ngFor="let cellTemplate of cellTemplates">
        <div class="cell" *ngIf="cellTemplate && !colIncludes(cellTemplate)" [ngClass]="cellTemplate?.classes ? (' ' + cellTemplate?.classes) : ''">
          <ng-container *ngTemplateOutlet="cellTemplate?.template, context: {$implicit: row[cellTemplate?.key]}">
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
