import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';

// Components
import { SubscriptionsComponent } from '../../../shared/super/subscriptions-component';

// Models
import { ManageProject } from '../../../shared/models/manage-project.model';
import { SummaryAdmin } from '../../interfaces/summary-admin.model';
import { Project } from '../../../shared/models/project.model';
import { Profile } from '../../../shared/models/profile.model';
import { Group } from '../../../shared/models/group.model';
import { Summary } from '../../interfaces/summary.model';

// Services
import { FloatingHelpService } from '../../../shared/services/floating-help.service';
import { ProjectService } from '../../services/project/project.service';
import { SummaryService } from '../../services/summary/summary.service';
import { GroupService } from '../../services/group/group.service';
import { UserService } from '../../../shared/services/user/user.service';
import { TaskService } from '../../services/task/task.service';


@Component({
  selector: 'ffcrm-dashboard-left',
  templateUrl: './dashboard-left.component.html',
  styleUrls: ['./dashboard-left.component.scss']
})
export class DashboardLeftComponent extends SubscriptionsComponent implements OnInit {

  @ViewChild('search') search;

  @Input()
  set updatedGroup(group: Group) {
    if (group) {
      this.currentSlug = group.slug;
      this.filteredGroups = this.filteredGroups?.map(filteredGroup => {
        if (filteredGroup?.id === group?.id) {
          return group;
        }
        return filteredGroup;
      });
    }
  }

  @Output() itemSelected = new EventEmitter();

  public filteredGroups: Array<Group>;
  public filteredProjects: Array<Project>;

  private groupSlug: string;
  private projectSlug: string;
  public currentSlug: string;
  public firstLoadGroup = true;
  public firstLoadProject = true;

  public readonly $profile: Observable<Profile>;
  public readonly $summary: Observable<SummaryAdmin | Summary>;
  public readonly $groups: Observable<Array<Group>>;
  public readonly $projects: Observable<Array<Project | ManageProject>>;
  public readonly $userSidebar: Observable<boolean>;

  constructor(
    private userService: UserService,
    private groupService: GroupService,
    private projectService: ProjectService,
    private summaryService: SummaryService,
    private taskService: TaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private floatingHelpService: FloatingHelpService,
  ) {
    super();

    this.$profile = this.userService.$profile;
    this.$summary = this.summaryService.$summary;
    this.$groups = this.groupService.$groups;
    this.$projects = this.projectService.$manageProjects;
    this.$userSidebar = this.userService.$userSidebar;
  }

  get profile(): Profile {
    return this.userService.profileSubj.value;
  }

  get project(): Project | ManageProject {
    return this.projectService.project;
  }

  get currentGroup(): Group {
    return this.groupService.currentGroupSubject.value;
  }

  get groups(): Array<Group> {
    return this.groupService.groups;
  }

  get projects(): Array<Project> {
    return this.projectService.manageProjects as Array<Project>;
  }

  get summary(): Summary | SummaryAdmin {
    return this.summaryService.summary;
  }

  public isSelected(slug: string): boolean {
    if (this.profile?.isStaff) {
      return this.currentGroup?.slug === slug;
    } else {
      return this.project?.slug === slug;
    }
  }

  public async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      this.$groups.subscribe((groups: Array<Group>) => {
        if (groups?.length) {
          this.filteredGroups = groups;
          this.filterItems();

          if (groups?.length && this.profile?.isStaff) { // TODO: need ref
            this.currentSlug = this.filteredGroups[0]?.slug;
            this.itemSelected.emit(this.filteredGroups[0]);
          }
        }
      }),

      this.$projects.subscribe((projects: Array<Project>) => {
        this.filteredProjects = projects;
        this.filterItems();

        if (this.firstLoadProject && projects?.length && !this.profile?.isStaff) {
          let firstInList: Project;

          if (this.filteredProjects?.filter((project: Project) => project?.isStarred).length) {
            firstInList = this.filteredProjects?.filter((project: Project) => project?.isStarred)[0];
          } else {
            firstInList = this.filteredProjects[0];
          }

          this.currentSlug = firstInList.slug;
          this.itemSelected.emit(firstInList);
          this.firstLoadGroup = false;
        }
      }),

      this.activatedRoute?.params.subscribe((params: Params) => {
          this.groupSlug = params?.groupSlug;
          this.projectSlug = params?.projectSlug;
        }, () => {
        },
        () => {
          console.log('finally');
        }
      )
    );
  }

  public filterItems(): void {
    let keyword = '';
    if (this.summary && this.search) {
      keyword = this.search?.nativeElement?.value.toLowerCase();
    }

    if (this.projects?.length) {
      this.filteredProjects = this.projects?.filter(project => project.name.toLowerCase().includes(keyword));
    }

    if (this.groups?.length) {
      this.filteredGroups = this.groups?.filter(group => group.name.toLowerCase().includes(keyword));
    }
  }

  public async toggleProjectStar(project): Promise<void> {
    try {
      if (project.isStarred) {
        await this.projectService.unStarProject(project.slug);
      } else {
        await this.projectService.starProject(project.slug);
      }
    } catch (e) {
      console.error(e);
    }
  }

  public async toggleGroupStar(group): Promise<void> {
    try {
      if (group.isStarred) {
        await this.groupService.unStarGroup(group.slug);
      } else {
        await this.groupService.starGroup(group.slug);
      }
    } catch (e) {
      console.error(e);
    }
  }

  public async selectItem(event, item): Promise<void> {
    if (event && !event.target.classList.contains('icon') && !event.target.classList.contains('star')) {
      this.projectService.closeCurrentProject();
      await this.taskService.closeAllTasks();
      this.currentSlug = item?.slug;
      this.itemSelected.emit(item);
    }
  }

  public async selectGroup(groupSlug: string): Promise<void> {
    await this.router.navigate([`home/groups/${groupSlug}`], {
      queryParamsHandling: 'merge',
      queryParams: {
        year: null,
        month: null,
      }
    });
  }

  public async selectProject(projectSlug: string): Promise<void> {
    await this.router.navigate([`home/projects/${projectSlug}`], {
      queryParamsHandling: 'merge',
      queryParams: {
        year: null,
        month: null,
      }
    });
  }

  public newAddUserModal(): void {
    if (this.currentGroup) {
      this.userService.openAddUserDragAndDropDialog(this.groupService.currentGroup.slug);
    }
  }

  public showHelp(newText: string): void {
    if (!this.userService.userSidebar) {
      this.floatingHelpService.setHelpState(true);
      this.floatingHelpService.setHelpText(newText);
    }
  }

  public closeHelp(): void {
    this.floatingHelpService.setHelpState(false);
  }
}
