import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hhmm'
})
export class HhmmPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string | number {
    if (value) {
      const minutes = value > 59 ? Math.floor(value) % 60 : value;
      const hours = value > 59 ? Math.floor((Math.floor(value) - minutes) / 60) : 0;
      const mins = hours ? Math.floor(value % (60 * hours)) : Math.floor(minutes);
      return hours ? `${hours}h ${(mins) < 10 ? `${mins}` : mins}m` : `${(mins) < 10 ? `${mins}` : mins}m`;
    }

    return `${0}m`;
  }

}
