import { Component } from '@angular/core';
import { UserService } from '../../../shared/services/user/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'ffcrm-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss']
})
export class AllUsersComponent {
  constructor(private userService: UserService) {
    this.$userSidebar = this.userService.$userSidebar;
  }

  public $userSidebar: Observable<boolean>;
}
