<ffcrm-dialog
    [visible]="!isDelete"
    (visibleChange)="onStateChange()"
    [title]="'Edit task'"
    [closable]="true">

  <div class="dialog-body">
    <form [formGroup]="form" *ngIf="form" (ngSubmit)="manageAndClose()">
      <div class="fields">
        <div class="input-block">
          <label for="title">Title</label>
          <input type="text" class="input-field" id="title" formControlName="title" />
          <div class="error-msg" [class.visible]="form.get('title').invalid && (form.get('title')?.touched || form.get('title')?.dirty)">
            <div *ngFor="let error of getErrors(form.get('title'))">{{ error }}</div>
          </div>
        </div>

        <div class="checkbox-block">
          <div class="checkbox-control-wrapper">
            <input type="checkbox" id="isExtraTime" formControlName="isExtraTime">
            <label for="isExtraTime">Extra time</label>
            <div class="tooltip-icon tooltip-trigger">?</div>
            <div class="tooltip-message">
              Time spent on tasks that are out of the estimation scope
            </div>
          </div>
        </div>

        <div class="input-block">
          <label>Task complexity</label>
          <ffcrm-select type="difficulty" classList="select form-select" labelKey="title" [options]="taskDifficulties" formControlName="difficulty" ngDefaultControl></ffcrm-select>
        </div>
        <div class="error-msg" [class.visible]="form.get('difficulty').invalid && (form.get('difficulty')?.touched || form.get('difficulty')?.dirty)">
          <div *ngFor="let error of getErrors(form.get('difficulty'))">{{ error }}</div>
        </div>

        <div class="input-block">
          <label for="description">Comment</label>
          <textarea id="description" class="input-field textarea" formControlName="description" rows="5" style="resize: none"></textarea>
          <div class="error-msg" [class.visible]="form.get('description')?.invalid && (form.get('link')?.touched || form.get('link')?.dirty)">
            <div *ngFor="let error of getErrors(form.get('description'))">{{ error }}</div>
          </div>
        </div>

        <div class="input-block">
          <label for="link">External link</label>
          <input type="text" id="link" class="input-field" placeholder="Add external link (Optional)" formControlName="link" />

          <div class="error-msg" [class.visible]="form.get('link')?.invalid && (form.get('link')?.touched || form.get('link')?.dirty)">
            <div *ngFor="let error of getErrors(form.get('link'))">{{ error }}</div>
          </div>
        </div>

        <div class="actions">
          <button type="button" class="btn btn-delete btn-block" (click)="openDeleteModal()">Delete</button>
          <button type="submit" class="btn btn-block" [disabled]="form.invalid">Save changes</button>
        </div>
      </div>
    </form>
  </div>
</ffcrm-dialog>

<ffcrm-accept
  [isVisible]="isDelete"
  type="delete"
  description="Are you sure you want to delete the task?"
  acceptBtnText="Delete task"
  (cancel)="closeDeleteModal()"
  (accept)="deleteTask()"
></ffcrm-accept>
