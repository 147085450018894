<div class="back-btn">
  <button type="button" class="btn-icon" routerLink="../"><i class="fas fa-arrow-left fa-fw"></i></button>
</div>

<div class="container">
  <div class="row">
    <div class="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">
      <div class="card">

        <h1>Forgot password</h1>

        <div class="token error-msg" *ngIf="expiredError">
          <p>Token is expired. Try again.</p>
        </div>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <div class="input-block">
            <label for="email">Email address</label>
            <input type="email" placeholder="Enter your email address" [class.hidden]="true" id="email" class="input-field" id="email" formControlName="email"
                   [ngClass]="{'error': form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)}">

            <div class="error-msg" [class.visible]="form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)">
              <div *ngFor="let error of getErrors(form.get('email'))">{{ error }}</div>
            </div>

          </div>

          <input type="submit" class="btn btn-block" value="Send" [disabled]="form.invalid">
        </form>
      </div>
    </div>
  </div>
</div>
