import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

export class ApiClient {
  private readonly basePath: string;

  constructor(path: string, private http: HttpClient) {
    this.basePath = environment.apiRoot + path;
  }

  public get<T>(url: string, httpParams?: HttpParams): Observable<T> {
    return this.http.get<T>(this.basePath + url, {params: httpParams});
  }

  public post<T>(url: string, body: object): Observable<T> {
    return this.http.post<T>(this.basePath + url, body).pipe(shareReplay<T>());
  }

  public put<T>(url: string, body: object): Observable<T> {
    return this.http.put<T>(this.basePath + url, body).pipe(shareReplay<T>());
  }

  public delete<T>(url: string): Observable<HttpResponse<T>> {
    return this.http.delete<T>(
      this.basePath + url,
      {observe: 'response'}
    );
  }

}
