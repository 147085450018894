import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// Components
import { FormComponent } from '../../../shared/super/form-component';

// Models
import { ProjectAutocomplete } from '../../../shared/models/project-autocomplete.model';
import { TimeEntryActivity } from 'src/app/shared/models/time-entry-activity.model';
import { TaskDifficulty } from 'src/app/shared/models/task-difficulty.model';
import { ProjectTasks } from '../../../shared/models/project-tasks.model';

// Services
import { ProjectService } from '../../services/project/project.service';
import { AlertService } from '../../../shared/services/alert.service';
import { TaskService } from '../../services/task/task.service';
import { SummaryService } from '../../services/summary/summary.service';

// Validators
import { taskDateValidator, taskLinkValidator, taskTimeValidator } from '../../../shared/validators/task-validators';

// Utils
import { getDate } from '../../../shared/utils/getDate';


@Component({
  selector: 'ffcrm-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss']
})
export class AddTaskComponent extends FormComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private taskService: TaskService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private summaryService: SummaryService,
  ) {
    super();

    this.subscriptions.push(
      this.activatedRoute?.parent?.params.subscribe((params: Params): void => {
        this.projectSlug = params?.projectSlug;
      })
    );

    this.subscriptions.push(
      this.activatedRoute.data.subscribe((data: Data): void => {
        this.taskDifficulties = data.difficulties;
        this.timeEntryActivities = data.activities;
      })
    );

    this.$tasks = this.taskService.$tasks;
    this.form = this.getClearForm();
  }

  public formTitle: string;
  private autocompleteSubject: Subject<string> = new Subject<string>();
  private projectSlug: string;
  public $tasks: Observable<ProjectTasks>;
  public tasks: Array<ProjectAutocomplete>;
  public timeEntryActivities: TimeEntryActivity[] = [];
  public taskDifficulties: TaskDifficulty[] = [];
  public form = this.getClearForm();
  public formStep = 1;
  private firstStepFields = ['title', 'isExtraTime', 'difficulty', 'description', 'link'];
  public get isFirstStepValid(): boolean {
    return this.form?.get('title')?.invalid ||
    this.form?.get('isExtraTime')?.invalid ||
    this.form?.get('link')?.invalid ||
    this.form?.get('difficulty')?.invalid ||
    this.form?.get('description')?.invalid;
  }
  public ngOnInit(): void {
    this.subscriptions.push(
      this.autocompleteSubject.pipe(debounceTime(400)).subscribe(async (reqString: string) => {
        if (reqString && reqString?.length > 0) {
          this.tasks = await this.taskService.autocomplete(reqString, this.projectSlug);
        } else {
          this.tasks = [];
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public getClearForm(): FormGroup {
    const date = new Date(Date.now());
    return this.fb.group({
      title: ['', [Validators.required]],
      isExtraTime: [false, [Validators.required]],
      description: [''],
      minutes: [0, [taskTimeValidator]],
      link: ['', [taskLinkValidator]],
      date: [`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, [taskDateValidator]],
      isOvertime: [false],
      activity: [null, [Validators.required]],
      difficulty: [null, [Validators.required]],
    });
  }

  public async onStateChange(newDate?: string): Promise<void> {
    const date = getDate(newDate) || null;

    if (newDate && date) {
      await this.router.navigate(['../../'], {
        queryParamsHandling: 'merge',
        relativeTo: this.activatedRoute,
        queryParams: {
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        },
      });
    } else {
      await this.router.navigate(['../../'], {
        queryParamsHandling: 'merge',
        relativeTo: this.activatedRoute,
      });
    }
  }

  private preProcessForm(): void {
    const link = this.form.get('link').value;

    if (link && this.form.get('link').valid) {
      if (!RegExp(/^(http|https):\/\//).test(link)) {
        this.form.get('link').setValue(`https://${link}`);
      }
    }
  }

  public async manageTask(close?: boolean): Promise<void> {
    try {
      const taskId = this.tasks?.find((task: ProjectAutocomplete) => task.title === this.form.get('title').value)?.id;
      this.form.markAllAsTouched();
      this.preProcessForm();

      if (this.form.valid) {
        if (taskId) {
          this.form.addControl('task', this.fb.control([]));
          this.form.get('task').setValue(taskId);
          await this.taskService.addTimeEntry(this.projectSlug, this.form.value, true);
          await this.projectService.updateMonthes(this.form.get('date').value);
        } else {
          await this.taskService.addTask(this.projectSlug, this.form.value);
          await this.projectService.updateMonthes(this.form.get('date').value);
        }

        // const date = new Date(this.form.get('date').value);
        // if (this.projectService.monthes.find((monthYear: MonthYear): boolean => {
        //   return +monthYear?.month === date.getMonth() + 1 && +monthYear?.year === date.getFullYear();
        // })) {
        //   await this.router.navigate([], {
        //     queryParamsHandling: 'merge',
        //     queryParams: {
        //       month: date.getMonth() + 1,
        //       year: date.getFullYear(),
        //     }
        //   });
        // }

        await this.alertService.showAlert({
          success: true,
          message: 'Task successfully added.'
        });

        this.form.markAsUntouched();
        if (close) {
          await this.onStateChange(this.form.get('date').value);
        }

        this.form.patchValue(this.getClearForm()?.value);
        this.form.reset(this.getClearForm()?.value);
        this.formStep = 1;
      }
    } catch (e) {
      this.form.removeControl('task');
      this.getErrorsFromResponse(e.error).then(() => {
        this.firstStepFields.forEach((field: string) => {
          if (this.form.get(field)?.invalid) {
            this.formStep = 1;
            this.form.updateValueAndValidity();
            return;
          }
        });
      });
    }
    this.summaryService.refreshSummaryAsync();
  }

  public async goToNexStep(): Promise<void> {
    let valid = true;
    this.form.markAllAsTouched();
    this.firstStepFields.forEach((field: string) => {
      if (this.form.get(field)?.invalid) {
        valid = false;
      }
    });
    if (valid) {
      this.form.markAsUntouched();
      this.formStep = 2;
    }
  }

  public async createAndNew(): Promise<void> {
    await this.manageTask(false);
  }

  public async createAndClose(): Promise<void> {
    await this.manageTask(true);
  }

  public async datepickerDate(date): Promise<void> {
    this.form.get('date').patchValue(date || '');
    this.form.get('date').markAsTouched();
  }

  public async titleAutocomplete(value: string): Promise<void> {
    this.autocompleteSubject.next(value);
  }

  public selectTask(taskId?: number): void {
    let taskResult;

    if (taskId) {
      taskResult = this.tasks?.find((task: ProjectAutocomplete) => task?.id === taskId);
    } else {
      taskResult = this.tasks?.find((task: ProjectAutocomplete) => task?.title === this.form.get('title').value);
    }

    if (this.form.get('title').value.length && this.form.get('title').value !== this.formTitle && taskResult) {
      this.formStep = 2;
    }

    this.formTitle = this.form.get('title').value;

    if (taskResult) {
      this.form.get('isExtraTime').patchValue(taskResult?.isExtraTime);
      this.form.get('difficulty').patchValue(taskResult?.difficulty?.id);
      this.form.get('description').patchValue(taskResult?.description);
      if (taskResult?.link) {
        this.form.get('link').patchValue(taskResult?.link);
      }
    }
  }

}
