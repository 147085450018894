import { Pipe, PipeTransform } from '@angular/core';

import { GroupProject } from '../models/group-project.model';

@Pipe({ name: 'other' })
export class OtherProjectGroupPipe implements PipeTransform {
  private isGroup(obj?: any): obj is GroupProject {
    return 'isActive' in obj;
  }

  transform(value: any, ...args): Array<GroupProject> {
    return value?.filter(item => this.isGroup(item) ? !item?.isStarred && item?.isActive && !item?.isReadonly : !item?.isStarred);
  }
}
