import { Component, OnDestroy, OnInit, QueryList, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { slugify, transliterate as tr } from 'transliteration';
import { Observable, zip } from 'rxjs';

// Components
import { FormComponent } from '../../../shared/super/form-component';

// Models
import { UserProfile } from '../../../shared/models/user-profile.model';
import { UserCheckbox } from '../../models/user-checkbox.model';
import { GroupErrors } from '../../models/group-errors.model';
import { Group } from '../../../shared/models/group.model';

// Pipes
import { validateAllFields } from '../../../shared/pipes/validate-all-fields.pipe';

// Services
import { AlertService } from '../../../shared/services/alert.service';
import { GroupService } from '../../services/group/group.service';
import { UserService } from '../../../shared/services/user/user.service';

// Utils
import imageResolving from '../../..//shared/utils/resolve-upload-file';


@Component({
  selector: 'ffcrm-group-modal',
  templateUrl: './group-modal.component.html',
  styleUrls: ['./group-modal.component.scss']
})
export class GroupModalComponent extends FormComponent implements OnInit, OnDestroy {

  constructor(
    private alertService: AlertService,
    private userService: UserService,
    private groupService: GroupService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) {
    super();

    this.subscriptions.push(
      this.activatedRoute?.parent?.params.subscribe((params: Params) => {
        this.groupSlug = params?.groupSlug;
      })
    );

    this.$users = this.userService.$users;
  }

  @ViewChild('photoInput') photoInput;
  @ViewChild('users') usersCheckboxes: QueryList<any>;
  @ViewChild('search') search;

  private groupSlug: string;
  private users: Array<UserProfile>;
  private uploadImage: boolean;

  public group: Group;
  public filteredUsers = [];

  public title: string;
  public image;
  public imageCropper: boolean;
  public croppedImage;
  public backendErrors: GroupErrors;
  public $users: Observable<Array<UserProfile>>;

  public selectedUsers = [];

  readonly resolvedFileTypes = [
    'image/gif',
    'image/jpeg',
    'image/png',
  ];

  public form = this.fb.group({
    name: ['', [Validators.required]],
    slug: ['', [Validators.required]],
    isActive: [false],
    isReadOnly: [false],
    members: this.fb.array([])
  });

  public async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      this.$users.subscribe((users: Array<UserProfile>) => {
        this.users = users;
      })
    );

    this.subscriptions.push(
      this.groupService.$currentGroup.subscribe((group: Group) => {
        if (!window.location.href.includes(`groups/${this.groupSlug}/add`)
          && this.groupSlug && group && (this.groupSlug === group?.slug)) {
          this.group = group;
        }
      })
    );

    this.subscriptions.push(
      zip(
        this.userService.$users,
        this.groupService.$currentGroup,
      )?.subscribe((result) => {
        if (result) {
          this.buildForm();
          this.image = this.group ? this.group.image : '';
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public membersCheckboxChange(event): void {
    const checked = event.target.checked;
    const members = this.form.value.members.map(member => {
      if (member.id.toString() === event.target.value) {
        member.checked = checked;
      }
      return member;
    });
    this.selectedUsers = this.getSelectedUsers();
    this.form.patchValue({ members });
  }

  public async dialogVisibleChange(): Promise<void> {
    await this.router.navigate(['../'], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
  }

  public async updateGroup(): Promise<void> {
    const group = { ...this.form.value, image: this.image };
    const newSlug = this.form.value?.slug;
    if (!this.uploadImage) {
      // @ts-ignore
      delete group.image;
    }
    group.members = this.selectedUsers;
    if (this.form.valid) {
      try {
        await this.groupService.updateGroup(this.group.slug, group);
        this.form.reset();
        await this.dialogVisibleChange();
        await this.alertService.showAlert({
          success: true,
          message: 'Group has been successfully updated'
        });
        this.group = group;

        if (this.groupSlug !== newSlug) {
          await this.router.navigate([`home/groups/${newSlug}`], { replaceUrl: true });
        }
      } catch (e) {
        this.backendErrors = new GroupErrors(e.error);
      }
    }
  }

  public async imageSelect(event): Promise<void> {
    const file: File = event.target.files[0];
    const reader = new FileReader();

    imageResolving(file, () => {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.croppedImage = reader.result;
        this.imageCropper = true;
      };
    }, async (message) => {
      await this.alertService.showAlert({
        success: false,
        message: message as string,
      });
    });

    this.photoInput.nativeElement.value = '';
  }

  public createSlug(): void {
    this.clearBackErrors();
    this.form.get('slug').setValue(slugify(tr(this.form.get('name').value)));
  }

  public get name(): AbstractControl {
    return this.form.get('name');
  }

  public get slug(): AbstractControl {
    return this.form.get('slug');
  }

  public buildForm(): void {
    this.form = this.fb.group({
      name: new FormControl(this.group?.name || '', [Validators.required]),
      slug: new FormControl(this.group?.slug || '', [Validators.required]),
      isActive: new FormControl(this.group?.isActive ?? true),
      isReadOnly: new FormControl(!!this.group?.isReadonly),
      members: this.buildUsersControl(),
    });
    this.selectedUsers = this.getSelectedUsers();
    this.filteredUsers = this.form.value.members;
  }

  public buildUsersControl(): FormArray {
    const arr = this.users.map(user => {
      const userCheckbox = new UserCheckbox(user);
      if (this.group && this.group.members.some(member => member?.id === userCheckbox?.id)) {
        userCheckbox.checked = true;
      }
      return this.fb.control(userCheckbox);
    });

    return this.fb.array(arr);
  }

  public getSelectedUsers(): Array<UserCheckbox> {
    return this.form.value.members.filter(member => {
      return member.checked;
    });
  }

  public async createAndNew(): Promise<void> {
    if (this.form.valid) {
      await this.saveGroup(false);
      this.form.reset();
    } else {
      validateAllFields(this.form);
    }
  }

  public async createAndClose(): Promise<void> {
    if (this.form.valid) {
      await this.saveGroup(true);
    } else {
      validateAllFields(this.form);
    }
  }

  public async saveGroup(closeDialog: boolean): Promise<void> {
    const group = { ...this.form.value, image: this.image };
    if (!this.uploadImage) {
      // @ts-ignore
      delete group.image;
    }
    group.members = this.selectedUsers;
    try {
      await this.groupService.createGroup(group);
      this.image = '';
      await this.alertService.showAlert({
        success: true,
        message: 'Group has been successfully created'
      });
      if (closeDialog) {
        this.form.reset();
        await this.dialogVisibleChange();
      }
    } catch (e) {
      await this.getErrorsFromResponse(e.error);
    }
  }

  public clearBackErrors(): void {
    this.backendErrors = null;
  }

  public filterUsers(): void {
    let keyword = '';
    if (this.search) {
      keyword = this.search.nativeElement.value.toLowerCase();
    }
    this.filteredUsers = this.form.value.members.filter(user =>
      (user.firstName.toLowerCase() + ' ' + user.lastName.toLowerCase()).includes(keyword)
    );
  }

  public getCroppedImage(image: string): void {
    this.image = image;
    this.uploadImage = true;
  }
}
