import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject,
  OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';

// Components
import { MonthFilterComponent } from '../../../shared/super/month-filter-component';

// Models
import { GroupProject } from '../../../shared/models/group-project.model';
import { UserTasksList } from '../../interfaces/user-tasks-list.model';
import { UserProfile } from '../../../shared/models/user-profile.model';
import { TableRowSettings } from '../../models/table-row-settings';
import { Profile } from '../../../shared/models/profile.model';
import { UserTask } from '../../interfaces/user-task.model';
import { MonthYear } from '../../models/month-year.model';

// Services
import { IMonthYearFilterService, YEAR_MONTH_FILTER_SERVICE } from '../../services/injection-tokens/month-year-filter-token';
import { UsersProjectService } from '../../services/all-users/users-projects/users-project.service';
import { TooltipService } from '../../../shared/services/tooltip.service';
import { UserTaskService } from '../../services/user-task/user-task.service';
import { GoogleExportService } from '../../services/google-export/google-export.service';
import { UserService } from '../../../shared/services/user/user.service';
import { SummaryService } from '../../services/summary/summary.service';


@Component({
  selector: 'ffcrm-user-component',
  templateUrl: './user-component.component.html',
  styleUrls: ['./user-component.component.scss'],
  providers: [
    {
      provide: YEAR_MONTH_FILTER_SERVICE,
      useExisting: UserTaskService,
    },
  ],
})
export class UserComponentComponent extends MonthFilterComponent implements OnInit, AfterViewInit {
  public user: UserProfile;
  public tasks: UserTasksList;

  public tableSettings: Array<TableRowSettings>;
  public tableData: Array<object> = [];

  @ViewChild('externalLinkTemplate') public externalLinkTableTemplate: TemplateRef<any>;
  @ViewChild('overtimeTemplate') public overtimeTableTemplate: TemplateRef<any>;
  @ViewChild('projectTemplate') public projectTableTemplate: TemplateRef<any>;
  @ViewChild('commentTemplate') public commentTableTemplate: TemplateRef<any>;
  @ViewChild('hoursTemplate') public hoursTableTemplate: TemplateRef<any>;
  @ViewChild('datesOvertime') public datesTableTemplate: TemplateRef<any>;
  @ViewChild('taskTemplate') public taskTableTemplate: TemplateRef<any>;

  private tasksRequestSubject: Subject<void> = new Subject<void>();

  public blockUserDialog: boolean;
  public unblockUserDialog: boolean;
  public projects: Array<GroupProject>;
  public isConfirmGoogleSheetOpening = false;
  public spreadSheetLink: string;
  public $profile: Observable<Profile>;
  public $tasks: Observable<Array<UserTask>>;


  @ViewChild('search') public searchInput: ElementRef;

  constructor(@Inject(YEAR_MONTH_FILTER_SERVICE) filterService: IMonthYearFilterService,
              private cd: ChangeDetectorRef,
              private userService: UserService,
              private taskService: UserTaskService,
              private summaryService: SummaryService,
              private userProjectService: UsersProjectService,
              private googleExportService: GoogleExportService,
              private tooltipService: TooltipService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private titleService: Title) {
    super(filterService);

    this.$profile = this.userService.$profile;
    this.$tasks = this.taskService.$tasks;
  }

  async ngOnInit(): Promise<void> {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const userId = +this.activatedRoute.snapshot.params?.userId;
    const confirmUrl = queryParams.confirm_url;
    const redirectUrl = queryParams.redirect_url;

    if (confirmUrl && redirectUrl) {
      const year: number = +queryParams?.year;
      const month: number = +queryParams?.month;

      const popUpSubscription = this.googleExportService.$popUpSubject.subscribe(link => {
        this.spreadSheetLink = link;
        this.isConfirmGoogleSheetOpening = true;
        popUpSubscription.unsubscribe();
      });

      this.googleExportService.exportUtil(confirmUrl, redirectUrl, 'startPersonalTasksWS', [year, month, userId]);
    }

    this.userProjectService.userId = userId;
    this.subscriptions.push(

      this.activatedRoute.data.subscribe((routeData: Data) => {
        this.tasks = routeData?.tasks;
        this.tableData = this.tasks?.tasks;
      }),

      this.activatedRoute.queryParams.subscribe(async (queries: Params) => {
        if (queries?.month && queries?.year) {
          this.currentMonth = {
            year: queries?.year,
            month: queries?.month,
          };

          this.setMonthes(this.currentMonth);
        }

        await this.filterTasks();
      }),

      this.userService.$userProfile.subscribe((user: UserProfile) => {
        this.user = user;


        if (this.user) {
          this.titleService.setTitle(`Timerilo - ${this.user?.firstName} ${this.user?.lastName}`);
        } else {
          this.titleService.setTitle(`Timerilo`);
        }
      }),

      this.taskService.$tasksList.subscribe((taskList: UserTasksList) => {
        this.tasks = taskList;
        this.tableData = taskList?.tasks;
      }),

      this.filterService.$monthes.subscribe(() => {
        this.setMonthes(this.currentMonth);
      }),

      this.tasksRequestSubject.asObservable().subscribe(async () => {
        await this.taskService.getUserTasks(this.activatedRoute.snapshot.params?.userId);
      }),
    );
  }

  private filterTasks(): void {
    this.tasksRequestSubject.next();
  }

  public ngAfterViewInit(): void {
    this.tableSettings = [
      { key: 'project', title: 'Project', template: this.projectTableTemplate, sort: true,
        sortCallbacks: { asc: this.sortProjectAsc, desc: this.sortProjectDesc }
      },
      { key: 'title', title: 'Task', template: this.taskTableTemplate, sort: true,
        sortCallbacks: { asc: this.sortTitleAsc, desc: this.sortTitleDesc }
      },
      { key: 'minutes', title: 'Hours', classes: 'table-users-hours', template: this.hoursTableTemplate, sort: true,
        sortCallbacks: { asc: this.sortHoursAsc, desc: this.sortHoursDesc }
      },
      { key: 'overtimes', title: 'Overtime', classes: 'table-users-overtime', template: this.overtimeTableTemplate, sort: true,
        sortCallbacks: { asc: this.sortOvertimesAsc, desc: this.sortOvertimesDesc }
      },
      { key: 'dates', title: 'Dates', classes: 'table-users-dates', template: this.datesTableTemplate, sort: true,
        sortCallbacks: { asc: this.sortDatesAsc, desc: this.sortDatesDesc }
      },
      { key: 'description', title: 'Comment', template: this.commentTableTemplate, sort: true,
        sortCallbacks: { asc: this.sortCommentAsc, desc: this.sortCommentDesc }
      },
      { key: 'link', title: 'External link', template: this.externalLinkTableTemplate, sort: true,
        sortCallbacks: { asc: this.sortLinkAsc, desc: this.sortLinkDesc }
      },
    ];

    /**
     * called to avoid "ExpressionChangedAfterItHasBeenCheckedError"
     * get Templates available only after ViewInit, otherwise it return undefined
     */
    this.cd.detectChanges();
  }

  public async selectMonthFilter(monthYear: MonthYear): Promise<void> {
    await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams: {
        year: monthYear?.year,
        month: monthYear?.month,
      },
    });
  }

  public openDialog(action): void {
    this[action] = true;
  }

  public closeDialog(action): void {
    this[action] = false;
  }

  public async blockUser(): Promise<void> {
    try {
      this.user.isActive = false;
      await this.userService.blockUser(this.user?.id, { isActive: this.user?.isActive }).toPromise();
      await this.userService.getAllUsers();
      await this.summaryService.refreshSummaryAsync();
    } catch (e) {
      console.error(e);
      throw e;
    }

    this.closeDialog('blockUserDialog');
  }

  public async unblockUser(): Promise<void> {
    try {
      this.user.isActive = true;
      await this.userService.blockUser(this.user?.id, { isActive: this.user?.isActive }).toPromise();
      await this.userService.getAllUsers();
      await this.summaryService.refreshSummaryAsync();
    } catch (e) {
      console.error(e);
      throw e;
    }

    this.closeDialog('unblockUserDialog');
  }

  public filter(): void {
    let keyword = '';

    if (this.searchInput) {
      keyword = this.searchInput.nativeElement.value.toLowerCase();
    }

    if (this.tasks) {
      this.tableData = this.tasks.tasks.filter((task: UserTask) => (
        task?.title.toLowerCase().includes(keyword)
        || task?.project?.name.toLowerCase().includes(keyword)
        || task?.description.toLowerCase().includes(keyword)
        || task?.link?.toLowerCase().includes(keyword)
      ));
    }
  }

  public showTooltip(event): void {
    this.tooltipService.showTooltip();
    this.tooltipService.setCords({x: event.clientX - 220, y: event.clientY + 5});
  }

  private sortProjectAsc(a, b): number {
    return a?.project?.name?.localeCompare(b?.project?.name);
  }

  private sortProjectDesc(a, b): number {
    return b?.project?.name?.localeCompare(a?.project?.name);
  }

  private sortTitleAsc(a, b): number {
    return a?.title?.localeCompare(b?.title);
  }

  private sortTitleDesc(a, b): number {
    return b?.title?.localeCompare(a?.title);
  }

  private sortHoursAsc(a, b): number {
    return a?.minutes - b?.minutes;
  }

  private sortHoursDesc(a, b): number {
    return b?.minutes - a?.minutes;
  }

  private sortOvertimesAsc(a, b): number {
    return b?.overtimes - a?.overtimes;
  }

  private sortOvertimesDesc(a, b): number {
    return a?.overtimes - b?.overtimes;
  }

  private sortDatesAsc(a, b): number {
    const aDate = a.dates.sort((d1, d2) => Date.parse(d1) - Date.parse(d2))[0];
    const bDate = b.dates.sort((d1, d2) => Date.parse(d1) - Date.parse(d2))[0];
    if (aDate > bDate) {
      return 1;
    } else if (aDate < bDate) {
      return -1;
    }
    return 0;
  }

  private sortDatesDesc(a, b): number {
    const aDate = a.dates.sort((d1, d2) => Date.parse(d1) - Date.parse(d2))[0];
    const bDate = b.dates.sort((d1, d2) => Date.parse(d1) - Date.parse(d2))[0];

    if (aDate < bDate) {
      return 1;
    } else if (aDate > bDate) {
      return -1;
    }
    return 0;
  }

  private sortCommentAsc(a, b): number {
    return a?.description?.localeCompare(b?.description);
  }

  private sortCommentDesc(a, b): number {
    return b?.description?.localeCompare(a?.description);
  }

  private sortLinkAsc(a, b): number {
    return a?.link?.localeCompare(b?.link);
  }

  private sortLinkDesc(a, b): number {
    return b?.link?.localeCompare(a?.link);
  }

  public async google(): Promise<void> {
    const response = await this.googleExportService.authGoogle(window.location.origin + '/home/google-auth/');
    localStorage.setItem('redirectUrl', window.location.pathname);
    localStorage.setItem('redirectQueryParams', window.location.search);
    if (response?.authUrl) {
      window.location.href = response?.authUrl;
    } else {
      console.log('Error');
    }
  }
}
