<div [ngClass]="classList" #dropDown>
  <div class="box" [class.difficulty-box]="type === 'difficulty'" (click)="toggle()">
    <span *ngIf="type !== 'difficulty'; else difficulty_selected" [class.placeholder]="label === placeholder && !model">{{ label }}</span>

    <ng-template #difficulty_selected>
      <img *ngIf="difficultySelected?.icon" class="superhero-img" [src]="difficultySelected.icon" />
      <p class="text">
        {{ difficultySelected?.superhero || difficultySelected?.title || difficultySelected }}
        <span *ngIf="difficultySelected?.title" class="difficulty-level">({{ difficultySelected?.title }})</span>
      </p>
    </ng-template>

    <i [@rotateArrow]="dropdown" class="fas fa-caret-down"></i>
  </div>
  <div class="dropdown"
       [@openClose]="dropdown"
       (@openClose.start)="$event.element.style.display = 'block'"
       (@openClose.done)="$event.element.style.display = ($event.toState ? 'block' : 'none')">
    <!-- <span class="triangle">▲</span> -->
    <div class="items-list">
      <div class="items">
        <div class="item"
            (click)="select(item)"
            *ngFor="let item of this.options; let i = index"
            [class.border-bottom]="type == 'difficulty'"
            [class.difficulty-item]="type == 'difficulty'">
          <p *ngIf="type !== 'difficulty'; else difficulty_item" class="text border-bottom">{{ item[labelKey] }}</p>

          <ng-template #difficulty_item>
            <img *ngIf="item?.icon" class="superhero-img" [src]="item.icon" />
            <p class="text">{{ item?.superhero || item?.title}} <span class="difficulty-level">({{ item[labelKey] }})</span></p>
          </ng-template>

        </div>
      </div>
    </div>
  </div>
</div>
