export const camelToSnake = (val: string): string => {
  return val.replace(/[A-Z]/g, (letter: string): string => `_${letter.toLowerCase()}`);
};
export const snakeToCamel = (val: string): string => {
  return val.replace(/_[a-z]/g, (letter: string): string => letter.split('_')[1].toUpperCase());
};

export enum cases {
  camel = 1,
  snake = 2,
}

export const keysToCase = (inputObject, targetCase: number): object => {
  if (Array.isArray(inputObject)) {
    return inputObject.map((i) => keysToCase(i, targetCase));
  }

  if (inputObject instanceof Object) {
    const resultObject = {};

    Object.keys(inputObject)
      .forEach((key: string): void => {
        if (inputObject[key] instanceof Object) {
          resultObject[targetCase === cases.camel ? snakeToCamel(key) : camelToSnake(key)] = keysToCase(inputObject[key], targetCase);
        } else {
          resultObject[targetCase === cases.camel ? snakeToCamel(key) : camelToSnake(key)] = inputObject[key];
        }
      });

    return resultObject;
  }

  return inputObject;
};

export default camelToSnake;
