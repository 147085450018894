<div class="content" [class.squoshed]="($userSidebar | async) === false">
  <div class="left-col-wrapper">
    <ffcrm-overtime-left></ffcrm-overtime-left>
  </div>
  <div class="dashboard">
    <div class="head">
      <h1>
        {{ profile?.isStaff ? title : 'Overtime' }}
      </h1>
      <div class="panel-wrapper">
        <div class="statistic">
          <div class="stat-item">
            <p class="text">Selected month</p>
            <p class="number">{{ ($statistics | async)?.selectedMonth || 0 | hhmm }}</p>
          </div>
          <div class="stat-item" *ngIf="profile?.isStaff">
            <p class="text">Previous month</p>
            <p class="number">{{ ($statistics | async)?.previousMonth || 0 | hhmm }}</p>
          </div>
        </div>
        <div class="input-block">
          <i class="icon icon-search search-icon"></i>
          <input #search class="input-field input-search" (input)="searchTasks()" type="text" id="search"
                 placeholder="Search">
        </div>
      </div>
    </div>
    <div class="wrapper full-height">
      <div class="right-col" [class.full-height]="!($profile | async)?.isStaff">
        <div class="table-panel">
          <h2 class="table-title">Information</h2>
          <div class="buttons-wrapper">
            <input type="button" class="filter-button google" (click)="google()" *ngIf="profile?.isStaff"/>
            <input type="button" class="filter-button print" (click)="print()" *ngIf="profile?.isStaff"/>
          </div>
          <ffcrm-select
            class="select"
            [options]='selectOptions'
            (selected)="selectMonthFilter($event)"
            [value]="initOption"
          ></ffcrm-select>
        </div>
          <ffcrm-table [data]="tableData | highlightNewRow:differenceTableData" [cellTemplates]="tableSettings" [skipCols]="skipCols"></ffcrm-table>
          <a class="btn btn-overtime btn-add with-margin-pillow" *ngIf="!profile?.isStaff" routerLink="./add" queryParamsHandling="preserve">
            <i class="icon icon-add"></i>
            Add overtime
          </a>
      </div>
    </div>

  </div>
</div>

<router-outlet></router-outlet>

<ng-template #userTemplate let-item>
  {{ item?.fullName }}
</ng-template>

<ng-template #editTableTemplate let-item>
  <a [routerLink]="'./edit/' + item" class="overtime-edit-button" queryParamsHandling="preserve" [tabIndex]="($profile | async)?.isStaff && 1"></a>
</ng-template>

<ng-template #projectTemplate let-item>
    {{item?.name}}
</ng-template>

<ng-template #taskTemplate let-item>
  {{ item?.title }}
  <p class="table-text-secondary">{{item.user}}</p>
</ng-template>

<ng-template #dateTemplate let-item>
  {{item | date}}
</ng-template>

<ng-template #hoursTemplate let-item>
  {{item | hhmm}}
</ng-template>

<ffcrm-accept
  [isVisible]="isConfirmGoogleSheetOpening"
  description="Do you want to open the document in google sheet?"
  type="sheet"
  [link]="spreadSheetLink"
  (accept)="isConfirmGoogleSheetOpening = false"
  (cancel)="isConfirmGoogleSheetOpening = false"
></ffcrm-accept>
