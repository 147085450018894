import { Component, OnDestroy } from '@angular/core';
import { Unsubscribable } from 'rxjs';

@Component({
  template: ''
})
export abstract class SubscriptionsComponent implements OnDestroy {
  protected constructor() {
    this.subscriptions = new Array<Unsubscribable>();
  }

  public subscriptions: Array<Unsubscribable> = new Array<Unsubscribable>();

  protected clearSubscriptions(): void {
    if (this.subscriptions && this.subscriptions.length) {
      for (const subscription of this.subscriptions) {
        subscription?.unsubscribe();
      }
    }
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
