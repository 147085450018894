<div class="wrapper">
  <div class="grid">
    <div class="logo-wrapper">
      <ffcrm-logo></ffcrm-logo>
    </div>
    <div class="summary" *ngIf="$summary | async">
      <h1>Dashboard</h1>
      <div class="statistic">
        <div class="projects-stat">
          <p class="text">Active Projects</p>
          <p class="number">{{ ($summary |async)?.activeProjects }}</p>
        </div>
        <div class="groups-stat">
          <p class="text">Active groups</p>
          <p class="number">{{ ($summary | async)?.activeGroups }}</p>
        </div>
        <div class="users-stat">
          <p class="text">Active users</p>
          <p class="number">{{ ($summary | async)?.activeUsers }}</p>
        </div>
      </div>
    </div>

    <div *ngIf="($summary | async) && ($userSidebar | async)">
      <div class="groups">
        <div class="heading">
          <h2>Projects</h2>
        </div>
        <div class="search">
          <div class="input-block">
            <i class="icon icon-search search-icon"></i>
            <input #search class="input-field input-search" type="text" (input)="filterItems()" placeholder="Search">
          </div>
        </div>
      </div>
    </div>

    <div class="items-list">
      <div class="projects-list">
        <div class="static-items">
          <ul>
            <li (click)="selectProject('all')"
                [class.selected-item]="isSelected('all')">
              <div class="image-container">
                <div class="image-wrapper all-projects"></div>
              </div>
              <div class="name"> All projects </div>
            </li>
          </ul>
        </div>
        <div class="starred" *ngIf="($userSidebar | async) === false">
          <ul>
            <li *ngFor="let project of filteredProjects | starred"
                (click)="selectProject(project?.slug)"
                [class.selected-item]="isSelected(project.slug)"
            >
              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
              <div class="star" (click)="$event.preventDefault(); $event.stopPropagation(); toggleProjectStar(project)">
                <i class="icon icon-star icon-18px"></i>
              </div>
            </li>
            <li *ngFor="let project of filteredProjects | other" (click)="selectProject(project?.slug)"
                [class.selected-item]="isSelected(project.slug)">
              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
              <div class="star" (click)="$event.preventDefault(); $event.stopPropagation(); toggleProjectStar(project)">
                <i class="icon icon-star-gray icon-18px"></i>
              </div>
            </li>
            <li *ngFor="let project of filteredProjects | readonly" (click)="selectProject(project?.slug)"
                [class.selected-item]="isSelected(project.slug)" class="item-leftsided">
              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
            </li>
          </ul>
        </div>

        <div class="starred" *ngIf="(filteredProjects | starred)?.length && ($userSidebar | async) === true">
          <p class="group-title">STARRED PROJECTS</p>

          <ul>
            <li *ngFor="let project of filteredProjects | starred"
                (click)="selectProject(project?.slug)"
                [class.selected-item]="isSelected(project.slug)">

              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
              <div class="star" (click)="$event.preventDefault(); $event.stopPropagation(); toggleProjectStar(project)">
                <i class="icon icon-star icon-18px"></i>
              </div>
            </li>
          </ul>
        </div>

        <div class="other" *ngIf="(filteredProjects | other)?.length && ($userSidebar | async) === true">
          <p class="group-title">OTHER PROJECTS</p>
          <ul>
            <li *ngFor="let project of filteredProjects | other"
                (click)="selectProject(project?.slug)"
                [class.selected-item]="isSelected(project.slug)"
            >
              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
              <div class="star" (click)="$event.preventDefault(); $event.stopPropagation(); toggleProjectStar(project)">
                <i class="icon icon-star-gray icon-18px"></i>
              </div>
            </li>
          </ul>
        </div>

        <div class="readonly" *ngIf="(filteredProjects | readonly )?.length && ($userSidebar | async) === true">
          <p class="group-title">READONLY PROJECTS</p>
          <ul>
            <li *ngFor="let project of filteredProjects | readonly" (click)="selectProject(project?.slug)"
                [class.selected-item]="isSelected(project.slug)" class="item-leftsided">
              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
            </li>
          </ul>
        </div>

        <div class="readonly" *ngIf="(filteredProjects | inactive )?.length && ($userSidebar | async) === true">
          <p class="group-title">INACTIVE PROJECTS</p>
          <ul>
            <li *ngFor="let project of filteredProjects | inactive" (click)="selectProject(project?.slug)"
                [class.selected-item]="isSelected(project.slug)" class="item-leftsided">
              <div class="image-container" (mouseenter)="showHelp(project?.name)" (mouseleave)="closeHelp()">
                <ffcrm-image [object]="project" srcKey="image" [labelKeys]="['name']" [border]="'5px'"></ffcrm-image>
              </div>

              <div class="name">
                {{ project?.name }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
