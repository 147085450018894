import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ffcrm-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @Input() public border: string;
  @Input() public object: any;
  @Input() public srcKey = 'image';
  @Input() public labelKeys = [];
  @Input() public classList = '';

  private colors = [
    '#f1f7ff',
    '#e7e9ff',
    '#ffe7e7',
    '#fef6e1',
    '#e1fee5',
    '#ffe7f2'
  ];

  public color: string;

  get label(): string {
    let label = '';
    if (this.object) {
      if (this.labelKeys?.length) {
        if (this.labelKeys?.length > 1) {
          if (this.labelKeys[0] === this.labelKeys[1]) {
            label = `${this.object[this.labelKeys[0]]?.split(' ')[0]?.toUpperCase()?.slice(0, 1) + this.object[this.labelKeys[1]]?.split(' ')[1]?.toUpperCase().slice(0, 1)}`;
          } else {
            label = `${this.object[this.labelKeys[0]]?.toUpperCase().slice(0, 1)}${this.object[this.labelKeys[1]]?.toUpperCase().slice(0, 1)}`;
          }
        } else {
          label = `${this.object[this.labelKeys[0]]?.toUpperCase().slice(0, 2)}`;
        }
      }
    }
    return label;
  }

  static hashCode(s: string): number {
    const hashed = s.split('').reduce((a, b) => {
      // tslint:disable-next-line:no-bitwise
      a = ((a << 5) - a) + b.charCodeAt(0);
      // tslint:disable-next-line:no-bitwise
      return a & a;
    }, 0);

    let resultIndex = +hashed.toString()[hashed.toString().length - 1];
    while (resultIndex > 5) {
      resultIndex -= 3;
    }

    return resultIndex;
  }

  public ngOnInit(): void {
    if (this.object && this.labelKeys?.length) {
      let keys = '';
      for (const key of this.labelKeys) {
        keys += ' ' + this.object[key];
      }

      this.color = this.colors[ImageComponent.hashCode(keys)];
    }
  }

}
