import { Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

// Models
import { DragNDropModalState } from '../../models/drag-n-drop-modal-state.model';
import { ManageProject } from '../../../shared/models/manage-project.model';
import { GroupProject } from '../../../shared/models/group-project.model';
import { UserProfile } from '../../../shared/models/user-profile.model';
import { Profile } from '../../../shared/models/profile.model';
import { Project } from '../../../shared/models/project.model';
import { Group } from '../../../shared/models/group.model';

// Services
import { DRAG_N_DROP_SERVICE } from '../../services/injection-tokens/drag-n-drop-token';
import { ProjectService } from '../../services/project/project.service';
import { GroupService } from '../../services/group/group.service';
import { UserService } from '../../../shared/services/user/user.service';


@Component({
  selector: 'ffcrm-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    {
      provide: DRAG_N_DROP_SERVICE,
      useExisting: GroupService,
    },
  ]
})
export class HomeComponent {
  @ViewChild('search') search;
  @ViewChild('filter') filter;

  public $profile: Observable<Profile>;
  public $currentGroup: Observable<Group>;
  public $userSidebar: Observable<boolean>;
  public projects: Array<GroupProject>;
  public currentSlug = false;

  constructor(
    private groupService: GroupService,
    private userService: UserService,
    private projectService: ProjectService,
  ) {
    this.$profile = this.userService.$profile;
    this.$currentGroup = this.groupService.$currentGroup;
    this.$userSidebar = this.userService.$userSidebar;
    this.groupService.$currentGroup.subscribe((group: Group) => {
      this.currentSlug = !!group?.slug;
    });
  }

  get profile(): UserProfile {
    return this.userService.profileSubj.value as UserProfile;
  }

  get project(): Project | ManageProject {
    return this.profile?.isStaff ? this.projectService.currentManageProjectSubject?.value
      : this.projectService.currentProjectSubject?.value;
  }

  get group(): Group {
    return this.groupService.currentGroupSubject?.value;
  }

  get usersModalDialog(): DragNDropModalState {
    return this.userService.dragNDropModalState;
  }

  set usersModalDialog(value: DragNDropModalState) {
    this.userService.dragNDropModalState = value;

    if (value?.refresh) {
      this.groupService.refreshGroup();
    }
  }

}
