import { HttpParams } from '@angular/common/http';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

// Components
import { FormComponent } from '../../../shared/super/form-component';

// Models
import { MonthYear } from '../../models/month-year.model';
import { OvertimePost } from '../../interfaces/overtime-post.model';
import { OvertimeRecord } from '../../interfaces/overtime.model';
import { TimeEntryActivity } from '../../../shared/models/time-entry-activity.model';
import { Project } from '../../../shared/models/project.model';
import { OvertimeTask } from '../../interfaces/overtime.model';

// Services
import { AlertService } from '../../../shared/services/alert.service';
import { UserService } from '../../../shared/services/user/user.service';
import { OvertimeService } from '../../../shared/services/overtime.service';
import { ProjectService } from '../../services/project/project.service';
import { TaskService } from '../../services/task/task.service';

// Validators
import { taskDateValidator } from '../../../shared/validators/task-validators';
import { taskTimeValidator } from '../../../shared/validators/task-validators';

@Component({
  selector: 'ffcrm-overtime-modal',
  templateUrl: './overtime-modal.component.html',
  styleUrls: ['./overtime-modal.component.scss']
})
export class OvertimeModalComponent extends FormComponent implements OnInit, OnDestroy {

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private taskService: TaskService,
    private projectService: ProjectService,
    private userService: UserService,
    private overtimeService: OvertimeService,
    private alertService: AlertService,
  ) {
    super();

    this.subscriptions.push(
      this.projectService.$manageProjects.subscribe((response: Array<Project>) => {
        this.projects = response;
        this.coldProjects = response;
      }),
    );

    if (this.overtimeId) {
      this.subscriptions.push(
        this.overtimeService.$singleOvertime.subscribe((response: OvertimeRecord) => {
          this.currentOvertime = response;
        }),
      );
    }

    this.form = this.getClearForm();
  }

  public isProjectSelected = false;

  public projects: Array<Project> = [];
  public coldProjects: Array<Project> = [];
  public tasks: Array<OvertimeTask> = [];
  public coldTasks: Array<OvertimeTask> = [];
  public activities: Array<TimeEntryActivity> = [];

  public isDelete = false;
  private currentOvertime: OvertimeRecord;

  public get overtimeId(): string | undefined {
    return this.activatedRoute.snapshot.params?.overtimeId;
  }

  private get currentProject(): string {
    return this.activatedRoute.snapshot.queryParams?.project;
  }

  private get body(): OvertimePost {
    return {
      activity: this.form.get('activity').value,
      minutes: this.form.get('minutes').value,
      date: this.form.get('date').value,
      task: {
        title: this.form.get('task').value,
        id: this.coldTasks.find((task: OvertimeTask) => this.form.get('task').value === task?.title)?.id,
      },
    };
  }

  private get isTaskExist(): boolean {
    return !!this.coldTasks.find(task => task?.title?.toLowerCase() === this.form.get('task').value);
  }

  private get projectSlug(): string {
    return this.coldProjects.find((project: Project) => project?.name === this.form.get('project').value)?.slug;
  }

  private isOvertimeInMonthExists(month: MonthYear): boolean {
    return !!this.overtimeService.monthes.filter((monthYear: MonthYear) => {
      return monthYear.month === month.month && monthYear.year === month.year;
    }).length;
  }

  private createMYObject(month: number, year: number): MonthYear {
    return { month, year };
  }

  public ngOnInit(): void {
    if (this.form.get('project')) {
      this.isProjectSelected = true;
    }
    this.subscriptions.push(
      this.taskService.$activities.subscribe((response: Array<TimeEntryActivity>) => {
        this.activities = response;
      }),
    );

    if ((this.currentProject && this.currentProject !== 'all') || this.overtimeId) {
      const project = this.overtimeId ? this.currentOvertime.project.slug : this.currentProject;

      this.subscriptions.push(
        this.taskService.getTasksAsync(project, {
          user: this.userService.profile.id.toString(),
        }).subscribe(({ tasks }) => {
          this.tasks = tasks as Array<OvertimeTask>;
          this.coldTasks = tasks as Array<OvertimeTask>;
          this.isProjectSelected = true;
        })
      );
    }
  }

  public async projectsAutocomplete(value: string): Promise<void> {
    this.projects = this.coldProjects.filter(({ name }: Project) => name?.toLowerCase().indexOf(value.toLowerCase()) !== -1);

    if (this.projects.find(project => project?.name.toLowerCase() === value.toLowerCase())) { // If project has selected
      this.isProjectSelected = true;

      const response = await this.taskService.getTasksAsync(this.projects[0]?.slug, {
        user: this.userService.profile.id.toString(),
      }).toPromise();
      this.tasks = response?.tasks as Array<OvertimeTask>;
      this.coldTasks = response?.tasks as Array<OvertimeTask>;
    } else {
      this.isProjectSelected = false;
      this.tasks = [];
      if (this.form.get('task').value) {
        this.form.get('task').reset();
      }
    }

    if (this.projects.length === 1 && this.projects[0]?.name?.toLowerCase() === value?.toLowerCase()) {
      this.projects = [];
    }
  }

  public tasksAutocomplete(value: string): void {
    this.tasks = this.coldTasks.filter(({ title }: OvertimeTask) => title?.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    if (this.tasks.length === 1 && this.tasks[0]?.title?.toLowerCase() === value?.toLowerCase()) {
      this.tasks = [];
    }
  }

  public getClearForm(): FormGroup {
    const date = new Date(Date.now());
    return this.fb.group({
      project: [this.currentOvertime?.project?.name
        || (this.currentProject !== 'all' &&
        this.coldProjects.find((project: Project) => project.slug === this.currentProject)?.name) || '', [Validators.required]],
      task: [this.currentOvertime?.task?.title ?? '', [Validators.required]],
      activity: [this.currentOvertime?.activity?.id ?? '', [Validators.required]],
      minutes: [this.currentOvertime?.minutes ?? 0, [taskTimeValidator]],
      date: [this.currentOvertime?.date ?? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, [taskDateValidator]],
    });
  }

  public async onStateChange(): Promise<void> {
    await this.router.navigate([this.overtimeId ? '../../' : '../'], {
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
    });
  }

  public async deleteOvertime(): Promise<void> {
    this.isDelete = true;
    try {
      await this.overtimeService.deleteOvertime(this.overtimeId).toPromise();

      await this.alertService.showAlert({
        success: true,
        message: 'Overtime successfully deleted.'
      });

      const currentParams: Params = this.activatedRoute.snapshot.queryParams;

      await this.overtimeService.getOvertimes(currentParams as HttpParams).toPromise();

      const month = +this.form.get('date').value.split('-')[1];
      const year = +this.form.get('date').value.split('-')[0];

      if (!this.isOvertimeInMonthExists(this.createMYObject(month, year))) {
        await this.overtimeService.getMonthes('months/');
      }

      this.onStateChange();
    } catch (err) {
      this.showErrorAlert();
    }
  }

  public async editOvertime(): Promise<void> {
    try {
      if (!this.isTaskExist && this.form.get('project').value) {
        await this.createTask();
      }

      await this.overtimeService.editOvertime(this.overtimeId, this.body).toPromise();

      await this.alertService.showAlert({
        success: true,
        message: 'Overtime successfully edited.'
      });

      const currentParams: Params = this.activatedRoute.snapshot.queryParams;

      await this.overtimeService.getOvertimes(currentParams as HttpParams).toPromise();

      const month = +this.form.get('date').value.split('-')[1];
      const year = +this.form.get('date').value.split('-')[0];

      if (!this.isOvertimeInMonthExists(this.createMYObject(month, year))) {
        await this.overtimeService.getMonthes('months/');
      }

      this.onStateChange();
    } catch (err) {
      this.showErrorAlert();
    }
  }

  public async postOvertime(close: boolean): Promise<void> {
    try {
      if (!this.isTaskExist && this.form.get('project').value) {
        await this.createTask();
      }

      await this.overtimeService.addOvertime(this.body).toPromise();
      await this.alertService.showAlert({
        success: true,
        message: 'Overtime successfully added.'
      });

      const currentParams: Params = this.activatedRoute.snapshot.queryParams;

      await this.overtimeService.getOvertimes(currentParams as HttpParams).toPromise();

      const month = +this.form.get('date').value.split('-')[1];
      const year = +this.form.get('date').value.split('-')[0];

      if (!this.isOvertimeInMonthExists(this.createMYObject(month, year))) {
        await this.overtimeService.getMonthes('months/');
      }

      close ? await this.onStateChange() : this.newOvertime();
    } catch (e) {
      this.showErrorAlert();
      this.newOvertime();
    }
  }

  private async createTask(): Promise<void> {
    await this.taskService.addTask(this.projectSlug, {
      activity: this.form.get('activity').value,
      date: this.form.get('date').value,
      description: 'Description',
      difficulty: 1,
      is_extra_time: false,
      is_overtime: false,
      link: '',
      minutes: this.form.get('minutes').value,
      title: this.form.get('task').value,
    });

    const { tasks } = await this.taskService.getTasksAsync(this.projectSlug, {
      user: this.userService.profile.id.toString(),
    }).toPromise();

    this.coldTasks = tasks as Array<OvertimeTask>;
  }

  private showErrorAlert(): void {
    this.alertService.showAlert({
      success: false,
      message: 'Bad request'
    });
  }

  public newOvertime(): void {
    this.form.patchValue(this.getClearForm()?.value);
    this.form.reset(this.getClearForm()?.value);
    this.projects = this.coldProjects;
    this.tasks = [];
    this.coldTasks = [];
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
