import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

// Models
import { TimeEntryActivity } from '../../shared/models/time-entry-activity.model';

// Services
import { TaskService } from '../services/task/task.service';

@Injectable({
  providedIn: 'root'
})
export class TimeEntriesActivitiesResolver implements Resolve<TimeEntryActivity> {

  constructor(private taskService: TaskService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TimeEntryActivity | any> {
    return this.taskService.getTimeEntryActivitiesAsync();
  }
}

