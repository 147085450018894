import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

// Models
import { ChangePasswordError } from '../../models/change-password-error.model';

// Pipes
import { validateAllFields } from '../../../shared/pipes/validate-all-fields.pipe';

// Services
import { AuthService } from '../../../auth/services/auth.service';
import { UserService } from '../../../shared/services/user/user.service';

// Validators
import { MustMatch } from '../../../shared/utils/must-match.validator';


@Component({
  selector: 'ffcrm-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {

  successDialog: boolean;
  @Input() changePasswordDialog: boolean;
  @Output() changePasswordDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  changePasswordForm = new FormGroup({
    oldPassword: new FormControl('', [
      Validators.required
    ]),
    newPassword: new FormControl('', [
      Validators.required
    ]),
    confirmPassword: new FormControl('', [
      Validators.required
    ])
  }, [
    MustMatch('newPassword', 'confirmPassword')
  ]);
  changePasswordErrors: ChangePasswordError;

  constructor(
    private userService: UserService,
    private authService: AuthService
  ) { }

  dialogVisibleChange(): void {
    this.changePasswordForm.reset();
    this.clearBackendErrors();
    this.changePasswordDialogChange.emit(false);
  }

  formData(data): object {
    return {
      old_password: data.oldPassword,
      new_password: data.newPassword
    };
  }

  savePassword(): void {
    if (this.changePasswordForm.valid) {
      this.authService.changePassword(this.formData(this.changePasswordForm.value)).subscribe(
        (data: any) => {
          this.dialogVisibleChange();
          this.successDialog = true;
          this.changePasswordForm.reset();
        },
        (err: any) => {
          this.changePasswordErrors = new ChangePasswordError(err.error);
        }
      );
    } else {
      validateAllFields(this.changePasswordForm);
    }
  }

  clearBackendErrors(): void {
    this.changePasswordErrors = null;
  }

  get oldPassword(): AbstractControl {
    return this.changePasswordForm.get('oldPassword');
  }

  get newPassword(): AbstractControl {
    return this.changePasswordForm.get('newPassword');
  }

  get confirmPassword(): AbstractControl {
    return this.changePasswordForm.get('confirmPassword');
  }
}
