import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {

  constructor() { }

  private isVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private clickCords: BehaviorSubject<{x: number, y: number}> = new BehaviorSubject<{x: number, y: number}>(null);

  get $isTooltipVisible(): Observable<boolean> {
    return this.isVisibleSubject.asObservable();
  }

  get $clickCords(): Observable<{x: number, y: number}> {
    return this.clickCords.asObservable();
  }

  public setCords(cords): void {
    if (!cords) {
      this.clickCords.next(null);
    } else {
      this.clickCords.next({x: cords.x, y: cords.y});
    }
  }

  public showTooltip(): void {
    this.isVisibleSubject.next(true);
  }

  public hideTooltip(): void {
    this.isVisibleSubject.next(false);
  }
}
